// DescuentosEstudiantes.js este se usa
import React, { useState, useEffect, useRef } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import EstudianteFilter from '../components/Filters/EstudianteFilter';
import JornadaAnioNivelCurso from '../components/Filters/JornadaAnioNivelCurso';
import moment from 'moment'
import Loading from '../components/Loading';
import utils from '../utils/generalFunctions';
import collectionsService from '../services/collectionsService';
import billingService from '../services/billingService';
import Swal from 'sweetalert2';
import css from '../css/estudiantes.css';


const EstadoDeCuentas = () => {
    
    const [showLoading, setShowLoading] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [filters, setFilters] = useState({ // Estado para los filtros aplicados
        studentId: '0',
        journeyId: '0',
        schoolYearId: '0',
        educationLevelId: '0',
        courseId: '0',
        cuentaPagada:true,
        cuentaPorPagar:false,
        verAnio:false
    });
    const filteReference = useRef(null);
    const [interesMesVencido, setInteresMesVencido] = useState({});
    const [estadoCuenta, setEstadoCuenta] = useState({});
    const [nivelesEducativos, setNivelesEducativos] = useState([]);
    const [billingAnios, setBillingAnios] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [meses, setMeses] = useState([{
        numero:1,
        nombre:"Obligaciones"
    },
    {
        numero:2,
        nombre:"Febrero"
    },
    {
        numero:3,
        nombre:"Marzo"
    },
    {
        numero:4,
        nombre:"Abril"
    },{
        numero:5,
        nombre:"Mayo"
    },{
        numero:6,
        nombre:"Junio"
    },{
        numero:7,
        nombre:"Julio"
    },{
        numero:8,
        nombre:"Agosto"
    },{
        numero:9,
        nombre:"Septiembre"
    },{
        numero:10,
        nombre:"Octubre"
    },{
        numero:11,
        nombre:"Noviembre"
    }]);
    const [obligaciones, setObligaciones] = useState([]);
    let consecutive = 0;
    const [currentMonth, setCurrentMonth] = useState(moment().month()+1);
    const [realCurrentMonth, setRealCurrentMonth] = useState(moment().month()+1);

    const fechtCurrentAnio = async () => {
        const parameters = await collectionsService.getParameters();
        const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
        const interesMesVencido = parameters.find((op) => String(op.keyName) === "interesMesVencido");
        if(interesMesVencido){
            setInteresMesVencido(Number(interesMesVencido.value));
        }
        if(currentAnio){
            setFilters((prevState) => ({ ...prevState, schoolYearId:currentAnio.value }));
          filteReference?.current?.setFormValues({schoolYearId:currentAnio.value});
        }
    }

    useEffect(() => {
        fechtCurrentAnio();
      }, []);

    // Obtener los descuentos cuando los filtros cambian
    const fetchEstadoDeCuenta = async () => {
        try {
            // Asumiendo que tu servicio acepta un objeto de filtros
            const response = await billingService.getEstadoDeCuentas(filters.studentId,  filters.journeyId, filters.schoolYearId, filters.educationLevelId, filters.courseId);
            let cursos =[];
            if(filters.courseId==="0"){
                cursos = filteReference?.current?.getCursos();
                setCursos(filteReference?.current?.getCursos());
            }else{
                cursos = [filteReference?.current?.getCursos().find(c=> String(c.courseId) === String(filters.courseId))];
                setCursos([filteReference?.current?.getCursos().find(c=> String(c.courseId) === String(filters.courseId))]);
            }
            setAniosAndEducationLevels(response.concepts);
            buildObligation(response,cursos);
            setEstadoCuenta(response); // Actualiza el estado con los descuentos obtenidos
        } catch (error) {
            console.error("Error al obtener los descuentos:", error);
        }
    };

    const setAniosAndEducationLevels = (concepts) =>{
        const anios = utils.removeDuplicates(concepts.map((concept) => {return {year: concept.year, schoolYearId: concept.schoolYearId}}));
        setBillingAnios(anios);
        const educationLevels =  utils.removeDuplicates((concepts.map((concept) =>  { return {name:concept.educationLevelName, id:concept.educationLevelId}})));
        setNivelesEducativos(educationLevels.filter(e=>e.id !== null));
    }

    const buildObligation = (estadoCuenta, cursos) =>{
        const conceptos = estadoCuenta.concepts;
        const anios = utils.removeDuplicates(conceptos.map((concept) => {return {year: concept.year, schoolYearId: concept.schoolYearId}}));
        const calculoObligacion = [];
        anios.map((anio) =>{
            cursos.map((curso) =>{
                const obligacion = {
                    year:anio,
                    curso : curso,
                    serie : []
                };
                const students = estadoCuenta.students.filter(s=> s.courseId === curso.courseId);
                meses.filter(m=> (String(filters.journeyId)!=="2" && m!==null) || ((String(filters.journeyId)==="2") && m.numero===1)).map((mes) =>{
                    const mesPago = {
                        numero:mes.numero,
                        nombre:mes.nombre,
                        conceptos:[],
                        totalDeuda:0,
                        totalPagado:0,
                        descuentosEnRecibo:0,
                    };
                    const billingConcepts = estadoCuenta.statusAccount.filter(c=>c.numero===mes.numero && c.schoolYearId === anio.schoolYearId && students.find(s=> s.studentId === c.studentId)!=null );
                    if(mes.numero === 1){
                        mesPago.conceptos = [...conceptos.map(c=> {
                            if((c.paymentFrequencyId === 44 || c.paymentFrequencyId === 46) && 
                                c.schoolYearId===anio.schoolYearId && 
                                (c.educationLevelId===curso.educationLevelId || c.educationLevelId===null) &&
                                (billingConcepts.find(b=> b.billingConceptId === c.billingConceptId)!= null ))
                            return c;
                        })].filter(c=> c);
                        //console.log(mesPago.conceptos);
                    }else{
                        mesPago.conceptos = [...conceptos.map(c=>{ 
                            if(c.paymentFrequencyId === 45 &&  c.schoolYearId===anio.schoolYearId && (c.educationLevelId===curso.educationLevelId || c.educationLevelId===null))
                            return c;
                        })].filter(c=> c);
                        //console.log(mesPago.conceptos);
                    }
                    obligacion.serie.push({...mesPago});

                    return mes;
                });
                calculoObligacion.push({...obligacion});

                return curso;
            });

            return anio;
        })
        setObligaciones(calculoObligacion);
        console.log(calculoObligacion);
    }

    const handleFilterChange = (newFilters) => {
        // Actualiza el estado de filters con los nuevos valores
        setFilters(prev => ({
            ...prev,
            ...newFilters
        }));
    };

    const studentInfoChange = (student) => {
        if(student){
        filteReference?.current?.setFormValues({ 
            journeyId: student.journeyId,
            schoolYearId: student.schoolYearId,
            educationLevelId: student.educationLevelId,
            courseId: student.courseId});
        }else{
            filteReference?.current?.setFormValues({ 
                journeyId: '0',
                schoolYearId: '0',
                educationLevelId: '0',
                courseId: '0'});
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const buscarEstadoDeCuentas = async () => {
      setShowLoading(true);
      await fetchEstadoDeCuenta();
      setShowLoading(false);
      setShowSearch(true);
    };

    const normalizeString = (str) => {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    };

    const getDescuentos = (student, concept, mes) => {
        return estadoCuenta.discounts.find(d=> 
            d.studentId === student.studentId && 
            d.billingConceptId === concept.billingConceptId &&
            d.schoolYearId === concept.schoolYearId &&
                ( (d.startDate===null && d.endDate === null) 
                || (d.startDate!==null && d.endDate === null && (moment(d.startDate).month()+1)>= mes.numero )
                || (d.startDate!==null && d.endDate !== null && 
                    (moment(d.startDate).month()+1 <= mes.numero) && 
                    (moment(d.endDate).month()+1 >= mes.numero))
                )
            );
    }
    
    const getValorDeObligacion = (discount, concept)=>{
        const valorDescuento= (discount)?(discount.discountTypeId === 34)?
                                                ((discount.discountValue * 100)/concept.value):
                                                discount.discountValue :0;
        return (concept.value-valorDescuento)< 0 ? 0 : concept.value-valorDescuento;
    }

    const getPagosDeRecibos = (concept, student, anio)=>{
        return estadoCuenta.payments.filter(p=> 
            p.studentId === student.studentId && 
            p.billingConceptId === concept.billingConceptId &&
            anio.schoolYearId === concept.schoolYearId  ); 
    }

    const getDescuentosDeRecibos = (payments) => {
        return payments.filter(p=> p.billingConceptName.toLowerCase()===("descuento") || p.total < 0);
    }

    const calcularPagos = (payments, student, resumen, descuentoEnFactura) => {
        for (let i = 0; i < payments.length; i++) {
            const pago = payments[i];
            const itemRecibo = student.payments.find(d=>d.billingDetailsId===pago.billingDetailId);
            if(!itemRecibo){
                const pagoProcesado = {billingDetailsId:pago.billingDetailId, value:pago.total};
                student.payments.push(pagoProcesado);
                procesarDetalleDeReciboPago(student, descuentoEnFactura, pago, resumen, pagoProcesado);
                if(pagoProcesado.value===0 && resumen.obligacionAPagar===0) break;
            }else{
                if(itemRecibo.value>0){
                    const cuantoDebo =  (resumen.obligacionAPagar-itemRecibo.value);
                    student.pagosRealizados += (cuantoDebo<0)?resumen.obligacionAPagar:itemRecibo.value;
                    resumen.pagoRealizado += (cuantoDebo<0)?resumen.obligacionAPagar:itemRecibo.value;
                    itemRecibo.value= (cuantoDebo)>=0?0:(cuantoDebo*-1);
                    if(cuantoDebo<=0) break; 
                    else resumen.obligacionAPagar = cuantoDebo;
                }
            }
        }
    }

    const getSumaTotalPorConcepto = (concept,mes, curso, anio) => {
        let totalPagado = 0;
        let totalApagar = 0;
        const students = estadoCuenta.students.filter(st=>st.schoolYearId === anio.schoolYearId && st.grade === curso.name);
        students.map((st)=>{
            
            const serie = estadoCuenta.statusAccount.filter(c=>c.numero===mes.numero && c.studentId === st.studentId && c.schoolYearId === anio.schoolYearId && c.billingConceptId===concept.billingConceptId);
            serie.map((c)=>{
                    totalPagado = totalPagado + (c.valuePaid??0);
                    totalApagar = totalApagar + (c.deudaTotal??0);
            })
        });
        return ((filters.cuentaPagada)? totalPagado:totalApagar);
    }

    const procesarDetalleDeReciboPago = (student, descuentoRecibos, pago, resumen, pagoProcesado) => {
        const descuentoEnFactura = descuentoRecibos.filter(d=>d.billingId===pago.billingId);
        if(descuentoEnFactura.length===0){
            procesarDetalleDeReciboSinDescuentosManuales(pago, student, resumen, pagoProcesado);
        }else{
            procesarDetalleDeReciboConDescuentosManuales(pago, student, resumen, pagoProcesado, descuentoEnFactura);
        }
    }

    const procesarDetalleDeReciboConDescuentosManuales = (pago, student, resumen, pagoProcesado, descuentoEnFactura) => {

        const totalDescuento = (descuentoEnFactura.reduce((sum, desc) => sum + (desc.total??0), 0)) * -1;
        const descuentoUsado = student.descuento.reduce((sum, desc) => { 
            if(desc.billingId === pago.billingId)
            return sum + (desc.total??0);
            else return sum;
        }, 0);
        const totalDescuentoDisponible = (totalDescuento - descuentoUsado);
        const valorPagado = pago.total - totalDescuentoDisponible;
        if(valorPagado<=0){
            resumen.obligacionAPagar = 0;
            resumen.pagoRealizado += 0;//pago.total;
            student.pagosRealizados += 0;//pago.total;
            student.deuda = student.deuda - pago.total;//Tener en cuenta que el descuento manual es negativo
            pagoProcesado.value=0;
            //student.pagosRealizados += valorPagado;
        }else{
            if(valorPagado>resumen.obligacionAPagar){
                resumen.pagoRealizado += ((totalDescuentoDisponible>resumen.obligacionAPagar)?0:resumen.obligacionAPagar);
                student.pagosRealizados += ((totalDescuentoDisponible>resumen.obligacionAPagar)?0:resumen.obligacionAPagar);
                const pagoDesdeElDescuento = resumen.obligacionAPagar-totalDescuentoDisponible;
                const calculoPagoRestante = pagoProcesado.value- ((pagoDesdeElDescuento<0)?0:pagoDesdeElDescuento);
                pagoProcesado.value=(calculoPagoRestante<0)?0:calculoPagoRestante;
                resumen.obligacionAPagar = 0;
            }else{
                resumen.pagoRealizado += valorPagado;
                student.pagosRealizados += valorPagado;
                pagoProcesado.value=pagoProcesado.value-(valorPagado+ totalDescuentoDisponible);
                resumen.obligacionAPagar = resumen.obligacionAPagar - valorPagado - totalDescuentoDisponible;
            }
        }
        //Restamos el descuento usado
        for(let i=0; i <  descuentoEnFactura.length; i++){
            const desc =  descuentoEnFactura[i];
            const value = desc.total*-1;
            if((value - pago.total)<0){
                student.descuento.push({billingId: desc.billingId, total: resumen.pagoRealizado});
            }else{
                student.descuento.push({billingId: desc.billingId, total: pago.total});
            }
        }
    }

    const procesarDetalleDeReciboSinDescuentosManuales = (pago, student, resumen, pagoProcesado) => {
        if(pago.total === resumen.obligacionAPagar){
            student.pagosRealizados += pago.total;
            resumen.pagoRealizado += pago.total;
            pagoProcesado.value=0;
            resumen.obligacionAPagar = 0;
        }else if(pago.total > resumen.obligacionAPagar){
            student.pagosRealizados += resumen.obligacionAPagar;
            resumen.pagoRealizado += resumen.obligacionAPagar;
            pagoProcesado.value=(pagoProcesado.value - resumen.obligacionAPagar);
            resumen.obligacionAPagar = 0;
        }else{
            student.pagosRealizados += pagoProcesado.value;
            resumen.pagoRealizado += pagoProcesado.value;
            pagoProcesado.value=0;
            resumen.obligacionAPagar -=  resumen.pagoRealizado;
        }
    }

    const getPagosEstudiantes = (concept, student, mes, anio, mostrarPagoRealizado) =>{

        const discount  = getDescuentos(student, concept, mes);
        const payments = getPagosDeRecibos(concept, student, anio);
        const manualDiscounts = getDescuentosDeRecibos(payments);
        const resumen ={
            pagoRealizado: 0,
            obligacionAPagar:getValorDeObligacion(discount, concept)
        }    
        student.deuda = (student.deuda+resumen.obligacionAPagar);

        calcularPagos(payments, student, resumen, manualDiscounts);
        
        if(mostrarPagoRealizado){
            student["conceptoPagado"+concept.billingConceptId+""+mes.numero] = resumen.pagoRealizado;
        return (<>
                <td className="d-none d-xl-table-cell">{utils.formatNumber.new(resumen.pagoRealizado, "$")}</td>
        </>)
        }else{
            student["conceptoPagado"+concept.billingConceptId+""+mes.numero] = resumen.obligacionAPagar;
            return (<>
                <td className="d-none d-xl-table-cell">{utils.formatNumber.new(resumen.obligacionAPagar, "$")}</td>
        </>)
        }
    }

    const exportarExcel = (curso, year) =>{
        const id="st-obligacion-"+curso.courseId+"-"+year.year;
        const name ="Estado de cuentas "+((filters.cuentaPagada)?"pagadas":"por pagar")+" "+curso.name+"-"+year.year;
        utils.exportTableToExcel(id, name);
    }

    const exportarTodoExcel = (year) =>{
        const id="st-obligacion-"+year.year;
        const name ="Estado de cuentas "+((filters.cuentaPagada)?"pagadas":"por pagar")+" "+year.year;
        const html = document.getElementById(id).innerHTML;
        utils.exportHtmlToExcel(html, name);
    }

    
    const calcularIntereses = (e) =>{
        e.preventDefault();
        Swal.fire({
            title:  '¿Está seguro de que desea generar los intereses de mora?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, generar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
              try{
                const response = await billingService.generarIntereses();
                if(response){
                    Swal.fire(
                        'Felicidades!',
                        '('+ response +') Interes(es) generado(s) exitosamente!',
                        'success'
                    );
                }else{
                    Swal.fire(
                        "Ha ocurrido un error",
                        "Por favor intentelo más tarde",
                        "error"
                    );
                }
              }catch(error){
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intentelo más tarde",
                    "error"
                );
              }
            }
          })    
    }

    return (
    <>
        <div className="student-table-container p-3 p-4">
        <h1>Estado de cuentas de estudiantes</h1>
                <div className="row">
                  <EstudianteFilter 
                  callback={handleFilterChange} 
                  callbackStudentInfo={studentInfoChange}
                  properties={
                      {
                          className:'col-md-3 mb-3',
                      }
                  }
                  />
                  <JornadaAnioNivelCurso
                          callback={handleFilterChange}
                          ref={filteReference}
                          properties={
                            {
                                className:'col-md-2 mb-2',
                                id:'formTestCuenta',
                                filtro:true
                            }
                        }
                      />
                    <div className="col-md-1 mb-1 margin-top-45">
                      <Button onClick={buscarEstadoDeCuentas} className="ml-2">Buscar</Button>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-2">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="isPagado" name="isPagado"
                                checked={filters.cuentaPagada} onChange={e => setFilters({...filters, cuentaPagada: e.target.checked, cuentaPorPagar: !e.target.checked})} />
                            <label className="form-check-label" htmlFor="isPagado">Cuentas pagadas</label>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="isPorPagar" name="isPorPagar" 
                                checked={filters.cuentaPorPagar} onChange={e => setFilters({...filters, cuentaPorPagar: e.target.checked, cuentaPagada:!e.target.checked})} />
                            <label className="form-check-label" htmlFor="isPorPagar">Cuentas por pagar</label>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="verAnio" name="verAnio" 
                                checked={filters.verAnio} onChange={e =>{
                                        setFilters({...filters, verAnio: e.target.checked});
                                        setCurrentMonth((e.target.checked)?12:moment().month()+1);
                                     }} />
                            <label className="form-check-label" htmlFor="verAnio">Ver año completo</label>
                        </div>
                    </div>
                    <div className="col-md-3 mb-1 margin-top-45 d-flex align-items-end justify-content-end">
                      <button className='btn btn-success' onClick={(e)=>{calcularIntereses(e)}}>Calcular intereses</button>
                    </div>
                  
                </div>
        </div>
        

        {showLoading && <Loading />}

        {showSearch && (
        <>
            {!showLoading && (
            <>
            {billingAnios.map((year) => (
                                   
                <div className="student-table-container p-3 margin-top-12">
                    <div className="row" id={"st-obligacion-"+year.year}>
                        <h1 class="h3 mb-3" >Año {year.year}</h1>
                        <div>
                            <div class="card-actions float-end margin-top--45">
                                <button className='btn btn-success' onClick={(e)=>{exportarTodoExcel(year)}}>Exportar a Excel</button>
                            </div>
                        </div>
                        {/* <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
                            <div className="btn-group">
                                <button className="btn-sm btn-Estudiante btn-NuevoEstudiante"  onClick={()=>{}} >Crear Descuento <i className="fa-solid fa-circle-plus"></i></button>
                            </div>
                        </div> */}

                        {obligaciones.map((obligacion) =>{
                            consecutive = 1;
                            const curso =obligacion.curso;
                            if(obligacion.year.schoolYearId === year.schoolYearId)
                            return (
                                <div class="card">
                                    <div class="card-header">
                                        
                                        <h5 class="card-title mb-0">{curso.name}</h5>
                                        <div class="badge bg-info my-2">{curso.educationLevelName}</div>
                                        <h5>Estado de cuentas {(filters.cuentaPagada)?"pagadas":"por pagar"}</h5>
                                        <button className='btn btn-success' onClick={(e)=>{exportarExcel(curso, year)}}>Exportar a Excel</button>
                                    </div>
                                    <div class="col-12 col-xl-12">
                                    <div class=" pt-0 table-responsive">

                                        <table id={"st-obligacion-"+curso.courseId+"-"+year.year} className="table table-corporate table-bordered table-sm  my-0 dataTables_wrapper dt-bootstrap5 no-footer">
                                            <thead>
                                            <tr>
                                                <th rowspan="2" className="d-none d-xl-table-cell">No.</th>
                                                <th rowspan="2" className="d-none d-xl-table-cell nombre-estudiante">Estudiante</th>
                                                {obligacion.serie.map((mes) => {                                                    
                                                    if(mes.numero<=currentMonth){
                                                        return(
                                                        <th colspan={
                                                            mes.conceptos.length
                                                        }
                                                        className="d-none d-xl-table-cell mes"
                                                        >{mes.nombre}</th>)
                                                    }
                                                })}
                                                <th rowspan="2" className="d-none d-xl-table-cell">Total pagado</th>
                                                <th rowspan="2" className="d-none d-xl-table-cell">Total pendiente</th>
                                                <th rowspan="2" className="d-none d-xl-table-cell">Total cuenta</th>
                                            </tr>
                                            <tr>
                                                {obligacion.serie.map((mes) => {
                                                    if(mes.numero<=currentMonth){
                                                        return(
                                                            mes.conceptos.map((concept)=>{
                                                                return(
                                                                    <th className="d-none d-xl-table-cell concepto">{concept.description.split(" ")[0]}</th>
                                                                )
                                                        }))
                                                    }
                                                })}
                                            </tr>
                                            </thead>
                                            
                                            <tbody>
                                            {estadoCuenta.students.map((student, index) => {
                                                if(student.schoolYearId===year.schoolYearId && 
                                                student.grade === curso.name)
                                                return (
                                            <tr>
                                                <td> {consecutive++} </td>
                                                <td className="nombre-estudiante"> {student.fullName} </td>
                                                {obligacion.serie.map((mes) => {
                                                    if(mes.numero<=currentMonth){
                                                        return(
                                                            mes.conceptos.map((concept)=>{
                                                                ////serie.conceptos
                                                                const pago = estadoCuenta.statusAccount.find(a=> 
                                                                                        a.studentId === student.studentId &&
                                                                                        a.billingConceptId === concept.billingConceptId &&
                                                                                        a.schoolYearId === year.schoolYearId &&
                                                                                        a.numero === mes.numero);
                                                                if(filters.cuentaPagada)
                                                                return(
                                                                    <td className={`d-none d-xl-table-cell concept-${concept.billingConceptId}`}>{utils.formatNumber.new((pago)?pago.valuePaid:"0", "$")}</td>
                                                                )
                                                                else
                                                                return(
                                                                    <td className={`d-none d-xl-table-cell concept-${concept.billingConceptId}`}>{utils.formatNumber.new((pago)?pago.deudaTotal:"0", "$")}</td>
                                                                )
                                                        }))
                                                    }
                                                })}
                                                <td>{utils.formatNumber.new((
                                                    estadoCuenta.statusAccount.filter(a=>
                                                        a.studentId === student.studentId &&
                                                        a.schoolYearId === year.schoolYearId ).reduce((sum, mes) => { 
                                                    if(mes.numero<=currentMonth)
                                                    return sum + (mes.valuePaid??0)
                                                    else return sum;
                                                    }, 0)), "$")}
                                                </td>
                                                <td>{utils.formatNumber.new((
                                                     estadoCuenta.statusAccount.filter(a=>
                                                        a.studentId === student.studentId &&
                                                        a.schoolYearId === year.schoolYearId).reduce((sum, mes) => { 
                                                    if(mes.numero<=currentMonth)
                                                    return sum + (mes.deudaTotal??0)
                                                    else return sum;
                                                    }, 0)), "$")}
                                                </td>
                                                <td>{utils.formatNumber.new((
                                                     estadoCuenta.statusAccount.filter(a=>
                                                        a.studentId === student.studentId &&
                                                        a.schoolYearId === year.schoolYearId ).reduce((sum, mes) => { 
                                                    if(mes.numero<=currentMonth)
                                                    return sum + ((mes.valuePaid??0) + (mes.deudaTotal??0))
                                                    else return sum;
                                                    }, 0)), "$")}</td>
                                            </tr>)
                                            })}
                                            <tr className="fila-total">
                                            <td className="fila-total nombre-estudiante" colspan="2" >Total</td>
                                                
                                            {obligacion.serie.map((mes) =>{
                                                if(mes.numero<=currentMonth){
                                                    return(
                                                        mes.conceptos.map((concept)=>{

                                                            return(
                                                                <td>{utils.formatNumber.new(getSumaTotalPorConcepto(concept, mes,curso,year), "$")}</td>                                                                
                                                            )                                                            
                                                            
                                                        })
                                                    )}
                                                })
                                            } 

                                                <td className="fila-total">{utils.formatNumber.new((estadoCuenta.students.reduce((sum, student) => { 
                                                    if(student.schoolYearId===year.schoolYearId && 
                                                    student.grade === curso.name)
                                                    return sum + ( estadoCuenta.statusAccount.filter(a=>
                                                        a.studentId === student.studentId &&
                                                        a.schoolYearId === year.schoolYearId).reduce((sum2, mes) => { 
                                                        if(mes.numero<=currentMonth)
                                                        return sum2 + ((mes.valuePaid??0))
                                                        else return sum2;
                                                        }, 0))
                                                    else return sum;
                                                    }, 0)), "$")}</td>
                                                <td className="fila-total">{utils.formatNumber.new((estadoCuenta.students.reduce((sum, student) => {
                                                    if(student.schoolYearId===year.schoolYearId && 
                                                        student.grade === curso.name)
                                                    return sum + ((estadoCuenta.statusAccount.filter(a=>
                                                        a.studentId === student.studentId &&
                                                        a.schoolYearId === year.schoolYearId).reduce((sum2, mes) => { 
                                                        if(mes.numero<=currentMonth)
                                                        return sum2 + ((mes.deudaTotal??0))
                                                        else return sum2;
                                                        }, 0)))
                                                    else return sum;
                                                    }, 0)), "$")}</td>
                                                <td className="fila-total">{utils.formatNumber.new((estadoCuenta.students.reduce((sum, student) => {
                                                    if(student.schoolYearId===year.schoolYearId && 
                                                        student.grade === curso.name)
                                                    return sum + ((estadoCuenta.statusAccount.filter(a=>
                                                        a.studentId === student.studentId &&
                                                        a.schoolYearId === year.schoolYearId).reduce((sum2, mes) => { 
                                                        if(mes.numero<=currentMonth)
                                                        return sum2 + ((mes.valuePaid??0) + (mes.deudaTotal??0))
                                                        else return sum2;
                                                        }, 0)))
                                                    else return sum;
                                                    }, 0)), "$")}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            )
                        } 
                        )}
                    </div>
                </div>
                
                ))}
            </>
            )}
        </>
        )}              
    </>
    );
};

export default EstadoDeCuentas;
