import React, { useEffect, useRef, useState } from 'react';
import Cardacademy from '../../components/CategoryCard.js';
import DashboardCard from '../../components/TituloDashboard.js'; // Asegúrate de que la ruta sea correcta
import studentPhoto from '../../img/user-profile.png';
import utils from '../../utils/generalFunctions.js';
import types from '../../services/types.js';
import collectionsService from '../../services/collectionsService.js';
import academicService from '../../services/academicService.js';
import estudiantesService from '../../services/estudiantesService.js';
import userProfile from "../../img/user-profile.png";

const DashboardDocente = () => {

    const [user,setUser] = useState([]);
    const [years,setYears] = useState([]);
    const [year,setYear] = useState([]);
    const [birthdayStudents, setBirthdayStudents] = useState([]);

    const cargarInformacionUsuario = () => {
        const data= utils.getUserInformation();
        if(data){
            setUser(data);
        }
    };

    const fetchSchoolYears = async () => {
        try {
            const yearsResp = await academicService.getYears();
            setYears(yearsResp);
            cargarAnio(yearsResp);
        } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
        }
    }
    
    const cargarAnio = async (yearsResp)=>{
        const parameters = await collectionsService.getParameters();
        const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
        if(currentAnio){
            const data = yearsResp.find(y=> String(y.schoolYearId) === currentAnio.value);
            if(data)setYear(data.year);
        }
    } 
    
    const fetchBirthDayStudents = async () => {
        try {
          const result = await estudiantesService.getEstudiantesCumpleanios();
          if(result && result.length > 0){ 
            setBirthdayStudents(result);
          }else{
            setBirthdayStudents([]);
          }
        } catch (error) {
          console.error("Error fetching students data:", error);
        }
      };
    
    useEffect(() => {
        cargarInformacionUsuario();
        fetchSchoolYears();
        fetchBirthDayStudents();
      }, []);

    const chartContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%',
        padding: '10px'
    };

    return (
        <div style={chartContainerStyle} className='row mb-2 mb-xl-12'>
            <DashboardCard
                studentPhoto={user.urlImage??studentPhoto} // Usar la variable importada aquí
                title={"Te damos la Bienvenida, "+user.userName+"!"}
                subtitle=""
                nombre={user.userName}
                curso=""
                nivelEducativo=""
                anio={year}
            />
            
            <div className="cardcate">
                <Cardacademy title="Asistencia" icon="fa-user-check" link="/asistencia" className="profile-card" />
                <Cardacademy title="Incidencias" icon="fa-person-circle-exclamation" link="/diciplinario/incidencias" className="grades-card" />
                <Cardacademy title="Planilla de Notas" icon="fa-star" link="/NotasEstudiantes" className="finance-card" />
                <Cardacademy title="Logros" icon="fa-medal" link="/Logros-estudiantes" className="observations-card" />
                <Cardacademy title="Reporte Asistencia" icon="fa-calendar-check" link="/informe-inasistencias" className="schedule-card" />

                {/* Puedes añadir más categorías según sea necesario */}
            </div>

            <div className="col-12 col-lg-4 d-flex">
                <div className="card flex-fill w-100">
                <div className="card-header">
                    <h5 className="card-title mb-0">Cumpleaños de estudiantes</h5>
                </div>
                <div className="card-body">
                    {birthdayStudents.map((student, index) => (
                    <div key={index} className="d-flex align-items-start">
                        <img  src={(student && student.urlImage)?`${student.urlImage}`:userProfile}
                        width="36" height="36" className="rounded-circle me-2" alt={student.fullName}/>
                        <div className="flex-grow-1 margin-bottom-10">
                        <strong>{student.fullName}</strong> - <small>{student.grade}</small><br/>
                        <small className="text-muted">{student.currentBirthDate}</small>
                        </div>
                        <hr/>
                    </div>
                    ))}
                    {/* <div className="d-grid">
                    <a href="#" className="btn btn-primary">Ver todos</a>
                    </div> */}
                </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardDocente;
