import api from './api';

const getLogros = async () => {
    try {
        const response = await api.get(`academic/achievement`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createLogro = async (periodoData) => {
    try {
        const response = await api.post(`academic/achievement`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const saveLogros = async (periodoData) => {
    try {
        const response = await api.post(`academic/achievement-list`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};



const updateLogro = async (periodoData) => {
    try {
        const response = await api.put(`academic/achievement`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getLogro = async (id) => {
    try {
        const response = await api.get(`academic/achievement/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getLogroByProcessId = async (id) => {
    try {
        const response = await api.get(`academic/achievement/process/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteLogroById = async (id) => {
    try {
        const response = await api.delete(`academic/achievement/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getLogros,
    createLogro,
    saveLogros,
    updateLogro,
    getLogro,
    deleteLogroById,
    getLogroByProcessId
};