import React, { useEffect, useState } from "react";
import "../../css/DashboardEstudiante.css";
import DashboardCard from '../../components/TituloDashboard.js'; // Asegúrate de que la ruta sea correcta
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import studentPhoto from '../../img/user-profile.png';
import util from '../../utils/generalFunctions';
import estudiantesService from '../../services/estudiantesService';
import moment from "moment";

const HorarioEstudiantePc = () => {
  const studentInfo = util.getStudentInformation();
  const [horario, setHorario] = useState([]);


  useEffect(() => {
    cargarHorario();
  }, []);

  const cargarHorario = async () => {
    const courseId = studentInfo.courseId;
    const schoolYearId = studentInfo.schoolYearId;
    try {
      const response = await estudiantesService.getEstudianteHorario(courseId, schoolYearId);
      if (response && response.length > 0) {
        setHorario(response);
      } else {
        setHorario([]);
      }
    } catch (ex) {
      console.error("Error al obtener horario de estudiante:", ex);
    }
  };

  return (
    
    <div className="horario-estudiante-container">
       <Link to="/dashboard" className="back-to-dashboard-pc">
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link> 
       <DashboardCard
        studentPhoto={studentPhoto}
          title="Horario de Clases"
          subtitle="Semanal"
         
        />
     <div className="scroll-semana">
        <div className="grid-semana">
          {horario.map((dia) => (
            <div key={dia.dayName} className="columna-dia">
              <h3 className="titulo-dia">{dia.dayName}</h3>
              <div className="contenedor-clases">
                {dia.schedule.map((clase, index) => (
                  <div key={index} className="tarjeta-clase">
                    <span className="nombre-asignatura">{clase.schoolSubjectName}</span>
                    <br/>
                    <span className="horario-clase">
                      {moment(clase.startHour, "HH:mm:ss").format("hh:mm A")} - {moment(clase.endHour, "HH:mm:ss").format("hh:mm A")}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HorarioEstudiantePc;