import Logo from '../img/Logo-Colegio.png';

const HeaderActaAdultos = () => {
    return (
      <header className="acta-header">
        <img
          src={Logo}
          alt="Escudo Colegio Jerusalem"
          className="escudo"
        />
        <div className="header-content">
          <h2>CENTRO EDUCATIVO PARA ADULTOS JERUSALEM</h2>
          <p>Educando para la eternidad.</p>
          <p>PBX 3684319 | Nit. 800 197 762-1</p>
          <p>LICENCIA DE FUNCIONAMIENTO No. 001205 DE NOVIEMBRE DE 2005</p>

        </div>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Coat_of_arms_of_Colombia.svg/1200px-Coat_of_arms_of_Colombia.svg.png"
          alt="Escudo de Colombia"
          className="escudo"
        />
      </header>
    );
  };
  
  export default HeaderActaAdultos;
  