import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import TeacherForm from '../components/Forms/TeachersForm';
import Loading from '../components/Loading';
import docentesService from "../services/docentesService";
import Swal from "sweetalert2";
import useCustomDataTableStyles from "../components/estilosDeDataTable";



const Docentes = () => {

  const [teachers, setTeachers] = useState([]); // Inicializa teachers como un array vacío
    
  
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [BtnVer, setBtnVer] = useState('');


  const toggleShow = () => {
      setFullscreenXlModal(!fullscreenXlModal);
      setSelectedTeacher(null); 
  };

  const openMaximizeModal = (teacher) => {
      setSelectedTeacher(teacher);
      setFullscreenXlModal(true);
      setBtnVer(true);
  };

  const openEditingModal = (teacher) => {
      setSelectedTeacher(teacher);
      setFullscreenXlModal(true);
      setBtnVer(false);
  };
  
  const openAddingModal = () => {
      setSelectedTeacher(null);
      setFullscreenXlModal(true);
      setBtnVer(false);
  };

  const fetchTeachers = async () => {
    try {
        const response = await docentesService.getDocentes();
        if (response && response.length > 0) { // Asegurarse de que la respuesta contiene datos
            setTeachers(response);
        }
    } catch (error) {
        console.error("Error al obtener los docentes:", error);
    }
};

const deleteDocente = async(docente)=>{
  Swal.fire({
      title: 'Esta seguro que desea eliminar este docente?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
        await docentesService.deleteDocenteById(docente.teacherId);
        Swal.fire(
          'Felicidades!',
          'Docente eliminado exitosamente!',
          'success'
        );
        fetchTeachers();
        }catch(error){
          console.log(error);
          Swal.fire(
              "Ha ocurrido un error",
              "Por favor intentelo mas tarde",
              "error"
          );
        }
      }
    })
}


useEffect(() => {
    fetchTeachers();
  }, []);

  const columns = [
    { name: "ID", selector: "identification", sortable: true, grow:"2" },
    { name: "Nombre completo", selector: "fullName", sortable: true, grow:"8"  },
    { name: "Celular", selector: "cellPhone", sortable: true, grow:"3"  },
    { name: "Correo", selector: "email", sortable: true, grow:"4"  },
    { name: "Teléfono", selector: "phone", sortable: true, grow:"3"  },
    { name: "Dirección", selector: "address", sortable: true, grow:"4"  },
    { name: "Contrato", selector: "hireDate", sortable: true, grow:"3" },
    { name: "Acciones",
      grow:"2.5",
      selector: "actions",grow:"3",
      cell: (row) => (
        <div className="actions-container justify-content-between align-items-center">
          <button
            onClick={() => openMaximizeModal(row)}
            className="btn-maximize btn-sm me-2"
            title="Maximizar información"
          >
            <i className="fas fa-eye"></i>
          </button>
          <button
            onClick={() => openEditingModal(row)}
            className="btn-edit btn-sm me-2"
            title="Editar"
          >
            <i className="icon-edit fas fa-pencil-alt"></i>
          </button>
          <button
            onClick={() => deleteDocente(row)}
            className="btn-deactivate btn-sm"
            title="Eliminar"
          >
            <i className="icon-deactivate fas fa-times"></i>
          </button>
        </div>
      ),
    },
  ];

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredItems = teachers?.filter((item) => {
    if (item) {
      const allData = `${item.identification} ${item.fullName} ${item.cellPhone} ${item.email} ${item.phone} ${item.address} ${item.hiringDate}`;
      const normalizedFilterText = normalizeString(filterText);
      const normalizedItemName = normalizeString(allData);
      return normalizedItemName.includes(normalizedFilterText);
    }
    return item;
  });

  const customStyles = useCustomDataTableStyles();

  const paginationPerPage = 50; // Ajusta este número según tus necesidades

  return (
    <div className="student-table-container p-3">
        <h1>Administración de docentes</h1>
        <p>Esta es la vista de los docentes de todo el plantel educativo.</p>
        <>
        <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
                <input
                    type="text"
                    placeholder="Buscar docente"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    className="form-control form-control-sm w-50"
                />
                <div className="btn-group">
                    <button className="btn-sm btn-Estudiante btn-NuevoEstudiante"  onClick={() => openAddingModal(null)} ><i className="fa-solid fa-circle-plus"></i>Nuevo docente</button>
                </div>
        </div>
        <DataTable
                className="students-datatable shadow-sm rounded"
                noDataComponent="No hay información que mostrar, por favor añadela"   
                columns={columns}
                data={filteredItems}
                pagination
                fixedHeader
                customStyles={customStyles}
                paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                paginationRowsPerPageOptions={[50, 100]}

            />
        <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                    <MDBModalDialog className="modal-xl-custom">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>DETALLES DEL DOCENTE</MDBModalTitle>
                                <MDBBtn
                                    type='button'
                                    className='btn-close'
                                    color='none'
                                    onClick={toggleShow}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className='card'>
                                    {selectedTeacher && (
                                        <TeacherForm
                                            key={Date.now()}
                                            teacher={selectedTeacher.teacherId}
                                            onClose={() => {
                                                 setFullscreenXlModal(false); 
                                                 fetchTeachers();
                                            }}
                                            ReadOnly={BtnVer}
                                        />
                                    )}

                                    {!selectedTeacher && (
                                        <TeacherForm
                                            key={Date.now()}
                                            teacher={0}
                                            onClose={() => {
                                                 setFullscreenXlModal(false); 
                                                 fetchTeachers();
                                            }}
                                            ReadOnly={false}
                                        />
                                    )}
                                </div>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                    Cerrar
                                </button>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                </>

    </div>
    );
};

export default Docentes;
