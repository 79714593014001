import { LazyLoadImage } from 'react-lazy-load-image-component';
import Logo from '../img/Logo-Colegio.png';

const HeaderActa = () => {
    return (
      <header className="acta-header">
        <LazyLoadImage
          src={Logo}
          alt="Escudo Colegio Jerusalem"
          className="escudo"
          effect="blur"  // Opcional: efecto visual mientras la imagen carga
        />
        <div className="header-content">
          <h2>COLEGIO JERUSALEM</h2>
          <p>Educando para la eternidad.</p>
          <p>PBX 3684319 | Nit. 800 197 762-1</p>
          <p>LICENCIA DE FUNCIONAMIENTO No. 001205 DE NOVIEMBRE DE 2005</p>

        </div>
        <LazyLoadImage
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Coat_of_arms_of_Colombia.svg/1200px-Coat_of_arms_of_Colombia.svg.png"
          alt="Escudo de Colombia"
          className="escudo"
          effect="blur"  // Opcional: efecto visual mientras la imagen carga
        />
      </header>
    );
  };
  
  export default HeaderActa;
  