import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/DashboardEstudiante.css'; // Asegúrate de crear este archivo CSS para estilizar tu componente
import studentPhoto from '../../img/foto-estudiante.png';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fotoProfesor1 from '../../img/profe (1).png';
import fotoProfesor2 from '../../img/profe (2).png';
import fotoProfesor3 from '../../img/profe (3).png';
import fotoProfesor4 from '../../img/profe (4).png';
import fotoProfesor5 from '../../img/profe (5).png';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';





const ObservacionesAsistencia = () => {
    
    const datosEstudiante = {
        nombre: "Ana María López",
        foto: "foto-estudiante.png", // Reemplaza con el path de la imagen real
        curso: "10 B",
        nivelEducativo: "Secundaria",
        anio: "2024",
        observaciones: "Excelente participación en clase.",
        calificaciones: "Promedio: 4.5",
        estadoCuenta: "Al día"
    };

    const asistencia = {
      totalAsistencias: 50,
      inasistencias: 3,
      excusas: 2,
      llegadasTarde: 1
    };
  
    const observaciones = [
      {
        id: 1,
        texto: 'Excelente trabajo en el proyecto de ciencias.',
        fecha: '2023-09-01',
        fotoProfesor: fotoProfesor1,
        nombreProfesor: 'Profesor(a) García'
      },
      // Más observaciones aquí...
      {
        id: 2,
        texto: 'Ana ha mostrado una notable mejora en matemáticas este trimestre.',
        fecha: '2023-09-10',
        fotoProfesor: fotoProfesor5,
        nombreProfesor: 'Maestra Martínez'
      },
      {
        id: 3,
        texto: 'Durante las clases de ciencias, Ana ha demostrado curiosidad y entusiasmo.',
        fecha: '2023-09-15',
        fotoProfesor: fotoProfesor3,
        nombreProfesor: 'Maestra López'
      },
      {
        id: 4,
        texto: 'Ana es una alumna muy creativa en las clases de arte.',
        fecha: '2023-09-20',
        fotoProfesor: fotoProfesor4,
        nombreProfesor: 'Maestra Rodríguez'
      },
      {
        id: 5,
        texto: 'He notado que Ana se esfuerza por mejorar en inglés.',
        fecha: '2023-09-25',
        fotoProfesor: fotoProfesor2,
        nombreProfesor: 'Maestro Gómez'
      }
    ];
    
  
    return (
      <div className="observaciones-asistencia-container">
            <Link to="/dashboard-estudiante" className="back-to-dashboard">
            <FontAwesomeIcon icon={faArrowLeft} />
            </Link>        
            <div className="estudiante-info-panel">
            <img src={studentPhoto} alt="Foto del estudiante" className="foto-estudiante" />
                <h2 className="nombre-estudiante">{datosEstudiante.nombre}</h2>
                <p className="info-estudiante">{datosEstudiante.curso} - {datosEstudiante.nivelEducativo} - Año {datosEstudiante.anio}</p>
            </div>
            <div className="asistencia-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className="asistencia-card" style={{ backgroundImage: 'linear-gradient(310deg, #56ab2f, #a8e063)', width: '50%' }}>
                    <h3 style={{ color: 'white' }}>Asistencias</h3>
                    <p>{asistencia.totalAsistencias}</p>
                </div>
                <div className="asistencia-card" style={{ backgroundImage: 'linear-gradient(310deg, #cb2d3e, #ef473a)', width: '50%' }}>
                    <h3 style={{ color: 'white' }}>Inasistencias</h3>
                    <p>{asistencia.inasistencias}</p>
                </div>
                <div className="asistencia-card" style={{ backgroundImage: 'linear-gradient(310deg,#2152ff,#21d4fd)', width: '50%' }}>
                    <h3 style={{ color: 'white' }}>Excusas</h3>
                    <p>{asistencia.excusas}</p>
                </div>
                <div className="asistencia-card" style={{ backgroundImage: 'linear-gradient(310deg, #FF8008, #FFC837)', width: '50%' }}>
                    <h3 style={{ color: 'white' }}>Llegadas Tarde</h3>
                    <p>{asistencia.llegadasTarde}</p>
                </div>
            </div>

  
        <div className="lista-observaciones">
        {observaciones.map(obs => (
            <div key={obs.id} className="observacion-item">
                <img src={obs.fotoProfesor} alt={obs.nombreProfesor} className="foto-profesor" />
                <div className="observacion-info">
                <h4>{obs.nombreProfesor}</h4>
                <p>{obs.texto}</p>
                <small>{obs.fecha}</small>
                </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ObservacionesAsistencia;
