import React, { useState } from 'react';
import { Tab, Tabs, Container } from 'react-bootstrap';
import css from '../css/estudiantes.css';
import ConfigureAcademicAreas from './ConfigureAcademicAreas';
import DimensionAcademica from './DimensionAcademica';
import AcademicPeriod from './periodoAcademico';
import ConfigureGrades from './grados';
import ConfigureYears from './ConfigureYears';
import Courses from './cursos';
import Asignatura from './asignatura';
import AcademicOrganization from './organizacionAcademica';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faCalendarAlt, faBookOpen, faAtom, faShapes, faGraduationCap, faClock, faBook, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

 
  
const ConfiguracionAcademica = () => {
    
    const [activeTab, setActiveTab] = useState('organizacionAcademica');
    const handleSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };
    const [isHovered, setIsHovered] = useState(false);
  

    return (
        <div className="col-12 col-lg-12">
            <div className="tab">
                <ul className="nav nav-tabs" role="tablist">
                    {/* Año Escolar Tab */}
                    <li className="nav-item" role="presentation">
                        <a className= {`nav-link ${activeTab === 'añosEscolares' ? 'active' : ''}`}
                            id="añosEscolares-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="añosEscolares"
                            aria-selected={activeTab === 'añosEscolares'}
                            onClick={() => handleSelect('añosEscolares')}
                        >
<FontAwesomeIcon icon={faCalendarAlt} className="icon-hover" /> Año Escolar                        </a>
                    </li>
                    {/* Períodos Tab */}
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'periodos' ? 'active' : ''}`}
                            id="periodos-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="periodos"
                            aria-selected={activeTab === 'periodos'}
                            onClick={() => handleSelect('periodos')}
                        >
<FontAwesomeIcon icon={faClock} className="icon-hover" /> Períodos
                        </a>
                    </li>
                    {/* Áreas Académicas Tab */}
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'areasAcademicas' ? 'active' : ''}`}
                            id="areasAcademicas-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="areasAcademicas"
                            aria-selected={activeTab === 'areasAcademicas'}
                            onClick={() => handleSelect('areasAcademicas')}
                        >
<FontAwesomeIcon icon={faAtom} className="icon-hover" />
 Áreas
                        </a>
                    </li>
                    {/* Dimensión Académica Tab */}
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'dimensionAcademica' ? 'active' : ''}`}
                            id="dimensionAcademica-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="dimensionAcademica"
                            aria-selected={activeTab === 'dimensionAcademica'}
                            onClick={() => handleSelect('dimensionAcademica')}
                        >
<FontAwesomeIcon icon={faShapes} className="icon-hover" />
Dimensiones
                        </a>
                    </li>
                    {/* Asignaturas Tab */}
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'asignaturas' ? 'active' : ''}`}
                            id="asignaturas-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="asignaturas"
                            aria-selected={activeTab === 'asignaturas'}
                            onClick={() => handleSelect('asignaturas')}
                        >
<FontAwesomeIcon icon={faBook} className="icon-hover" />
 Asignaturas
                        </a>
                    </li>
                    {/* Grados Tab */}
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'grados' ? 'active' : ''}`}
                            id="grados-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="grados"
                            aria-selected={activeTab === 'grados'}
                            onClick={() => handleSelect('grados')}
                        >
<FontAwesomeIcon icon={faGraduationCap} className="icon-hover" />
 Grados
                        </a>
                    </li>
                    {/* Cursos Tab */}
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'cursos' ? 'active' : ''}`}
                            id="cursos-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="cursos"
                            aria-selected={activeTab === 'cursos'}
                            onClick={() => handleSelect('cursos')}
                        >
<FontAwesomeIcon icon={faBookOpen} className="icon-hover" />
 Cursos
                        </a>
                    </li>
                    {/* Organización Académica Tab */}
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${activeTab === 'organizacionAcademica' ? 'active' : ''}`}
                            id="organizacionAcademica-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="organizacionAcademica"
                            aria-selected={activeTab === 'organizacionAcademica'}
                            onClick={() => handleSelect('organizacionAcademica')}
                        >
<FontAwesomeIcon icon={faChalkboardTeacher} className="icon-hover" />
 Organización Académica
                        </a>
                    </li>
                </ul>
                <div className="tab-content">
    {/* Panel de Año Escolar */}
    <div className={`tab-pane ${activeTab === 'añosEscolares' ? 'active show' : ''}`}
        id="añosEscolares"
        role="tabpanel"
        aria-labelledby="añosEscolares-tab"
    >
        <ConfigureYears />
    </div>

    {/* Panel de Períodos */}
    <div className={`tab-pane ${activeTab === 'periodos' ? 'active show' : ''}`}
        id="periodos"
        role="tabpanel"
        aria-labelledby="periodos-tab"
    >
        <AcademicPeriod />
    </div>

    {/* Panel de Áreas Académicas */}
    <div className={`tab-pane ${activeTab === 'areasAcademicas' ? 'active show' : ''}`}
        id="areasAcademicas"
        role="tabpanel"
        aria-labelledby="areasAcademicas-tab"
    >
        <ConfigureAcademicAreas />
    </div>

    {/* Panel de Dimensión Académica */}
    <div className={`tab-pane ${activeTab === 'dimensionAcademica' ? 'active show' : ''}`}
        id="dimensionAcademica"
        role="tabpanel"
        aria-labelledby="dimensionAcademica-tab"
    >
        <DimensionAcademica />
    </div>

    {/* Panel de Asignaturas */}
    <div className={`tab-pane ${activeTab === 'asignaturas' ? 'active show' : ''}`}
        id="asignaturas"
        role="tabpanel"
        aria-labelledby="asignaturas-tab"
    >
        <Asignatura />
    </div>

    {/* Panel de Grados */}
    <div className={`tab-pane ${activeTab === 'grados' ? 'active show' : ''}`}
        id="grados"
        role="tabpanel"
        aria-labelledby="grados-tab"
    >
        <ConfigureGrades />
    </div>

    {/* Panel de Cursos */}
    <div className={`tab-pane ${activeTab === 'cursos' ? 'active show' : ''}`}
        id="cursos"
        role="tabpanel"
        aria-labelledby="cursos-tab"
    >
        <Courses />
    </div>

    {/* Panel de Organización Académica */}
    <div className={`tab-pane ${activeTab === 'organizacionAcademica' ? 'active show' : ''}`}
        id="organizacionAcademica"
        role="tabpanel"
        aria-labelledby="organizacionAcademica-tab"
    >
        <AcademicOrganization />
    </div>
</div>

            </div>
        </div>
    );
};

export default ConfiguracionAcademica;