
import { useEffect, useRef, useState } from "react";
import HeaderActa from "../../../components/headerActas";
import FooterActa from "../../../components/FooterActa";
import moment from "moment";
import utils from "../../../utils/generalFunctions";
import types from "../../../services/types";


const RepoPreventivoEstudiante = ({ reporte }) => {
    const filteReference = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const [process, setProcess] = useState([]);

    const cargarProcesos =  () => {
        const processArray = [];
        reporte.schoolSubjects.forEach(subject =>{
            subject.evaluationProcess.forEach(pro =>{
                if(!processArray.find(p=>p === pro.evaluationProcess))
                {
                    processArray.push(pro.evaluationProcess);
                }
            });
        });
        setProcess(processArray);
    }

    useEffect(()=>{
        cargarProcesos();
    },[]);
   
    return (
        <>
          {!showLoading && reporte && (

            <>

                <div class="card acta-container">
                
                    {/* <!-- Cabezera --> */}
                    <div className="card-body">
                    <HeaderActa />
                    {/* <!-- Ficha de Estudiante --> */}
                    <section className="incidencia-info-boletin">
    <div className="info-header-boletin">
        <h5 className="section-title-boletin">CORTE INFORMATIVO TRIMESTRE</h5>
    </div>
    <table className="datos-incidencia-table table table-sm my-2">
        <tbody>
            <tr>
                <th className="info-title-boletin">Estudiante</th>
                <td className="info-content-boletin">{reporte.student.studentFullName}</td>
                <th className="info-title-boletin">Grado</th>
                <td className="info-content-boletin">{reporte.student.grade}</td>
            </tr>
            <tr>
                <th className="info-title-boletin">Fecha</th>
                <td className="info-content-boletin">{moment().format("DD-MM-yyyy")}</td>
                <th className="info-title-boletin">Dir. de Grupo</th>
                <td className="info-content-boletin">{reporte.student.groupDirectorName}</td>
            </tr>
        </tbody>
    </table>
</section>

                        <section class="incidencia-info">

                            <table class="table-corte-preventivo">
                                <thead>
                                    <tr>
                                        <th>Asignaturas</th>
                                        {process?.map((pro, index) => (
                                        <th>{index+1}. {pro.substring(0,4)}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {reporte.schoolSubjects?.filter(s=>s.evaluationProcess.filter(e=>e.evaluativeScale).length > 0).map((subject, index) => (
                                        <tr>
                                            <td>{subject.schoolSubjectName}</td>
                                            {subject.evaluationProcess?.map((pro, index) => (
                                                <td>{pro.evaluativeScale??""}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                        <section class="incidencia-info">
                            <table class=" table-striped ">
                                <thead>
                                    <tr>
                                    <th>Procesos</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>CORTE PREVENTIVO</td>
                                    </tr>
                                    {process?.map((pro, index) => (
                                        <tr>
                                            <td>{index+1}. {pro}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                        <FooterActa 
                            creadorId={reporte.student.groupDirectorId??0}
                            reportTypeId={types.reportTypes.ACTA}></FooterActa>
                    </div>
                </div>

                {/* <FooterActa></FooterActa> */}

                <br />
                    <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
                <br />

            </>
          )}
        </>
    );
  };
  
  export default RepoPreventivoEstudiante;