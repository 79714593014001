import api from './api';

const getReportPermission = async () => {
    try {
        const response = await api.get(`report/permission`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createReportPermission = async (incidenciaTipo) => {
    try {
        const response = await api.post(`report/permission`, incidenciaTipo);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateReportPermission = async (incidenciaTipo) => {
    try {
        const response = await api.put(`report/permission`, incidenciaTipo);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getReportPermissionById= async (id) => {
    try {
        const response = await api.get(`report/permission/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteReportPermissionById = async (id) => {
    try {
        const response = await api.delete(`report/permission/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getPermisosEstudiantesPorCurso = async (periodId, schoolYearId, courseId, reportTypeId) => {
    try {
        const response = await api.get(`report/permission-cursos?periodId=${periodId}&reportTypeId=${reportTypeId}&schoolYearId=${schoolYearId}&courseId=${courseId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getPermisosEstudiantes = async (studentId, schoolYearId, periodId) => {
    try {
        const response = await api.get(`report/permission-student?studentId=${studentId}&periodId=${periodId}&schoolYearId=${schoolYearId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getReportPermission,
    createReportPermission,
    updateReportPermission,
    getReportPermissionById,
    deleteReportPermissionById,
    getPermisosEstudiantes,
    getPermisosEstudiantesPorCurso
};