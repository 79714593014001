import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import useCustomDataTableStyles from "../components/estilosDeDataTable";
import CategoriaForm from "../components/Forms/categoriaIncidenciasForm.js";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import tipoIncidenciaService from "../services/tipoIncidenciaService.js";
import Swal from "sweetalert2";
import Loading from "../components/Loading.js";

const Categorias = () => {
    const [categorias, setCategorias] = useState([]); // Inicializar como un array vacío
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCategoria, setSelectedCategoria] = useState(null);
    const [filterTextCategorias, setFilterTextCategorias] = useState('');
    const [showLoading, setShowLoading] = useState(false);

    const toggleShow = () => {
        setIsModalOpen(!isModalOpen);
    };

    const openModal = () => {
        setSelectedCategoria(null); // Resetear la categoría seleccionada
        setIsModalOpen(true);
    };

    const fetchCategorias = async () => {
        setShowLoading(true); // Cambia el estado a false una vez que los datos se carguen
        try {

            const response = await tipoIncidenciaService.getParentsIncidenciasTipos();
            if(response){
                setCategorias(response);
            }else{
                setCategorias([]);
            }
        } catch (error) {
          console.error("Error al obtener categoria de incidencias:", error);
        }
        setShowLoading(false);// Cambia el estado a false una vez que los datos se carguen
    };

    const removeIncidenciaType = (inicidenceTypeId) => {
        
        if(inicidenceTypeId){
            Swal.fire({
                title:  '¿Está seguro de que desea eliminar esta categoria de incidencias?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!'
              }).then(async(result) => {
                if (result.isConfirmed) {
                  try{
                  
                  await tipoIncidenciaService.deleteIncidenciasTipoById(inicidenceTypeId);
                  Swal.fire(
                    'Felicidades!',
                    'Categoria eliminada exitosamente!',
                    'success'
                  );
                  await fetchCategorias();
                  }catch(error){
                    Swal.fire(
                        "Ha ocurrido un error",
                        "Por favor intentelo más tarde",
                        "error"
                    );
                  }
                }
              })    
        }
    };

    useEffect(() => {
        fetchCategorias();
    }, []);

    const columns = [
        { 
            name: "N°", 
            selector: row => row.number, 
            sortable: true, 
            cell: (row, index) => <span>{index + 1}</span>, 
        },
        { name: "Nombre", selector: row => row.description, sortable: true },
        {
            name: "Acciones",
            selector: "actions",
            cell: (row) => (
                <div className="actions-container justify-content-between align-items-center">
                    <button className="btn-edit btn-sm me-2" title="Editar" onClick={() => {
                        setSelectedCategoria(row);
                        setIsModalOpen(true);
                    }}>
                        <i className="icon-edit fas fa-pencil-alt"></i>
                    </button>
                    <button className="btn-deactivate btn-sm" title="Eliminar" onClick={() => {
                        removeIncidenciaType(row.inicidenceTypeId);
                        // Aquí la lógica para eliminar una categoría
                    }}>
                        <i className="icon-deactivate fas fa-times"></i>
                    </button>
                </div>
            ),
        },
    ];

    const customStyles = useCustomDataTableStyles();


    const normalizeString = (str) => {
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      };
    
    const filteredItems = categorias?.filter((item) => {
        if (item) {
          const allData = `${item.parentTypeName}`;
          const normalizedFilterText = normalizeString(filterTextCategorias);
          const normalizedItemName = normalizeString(allData);
          return normalizedItemName.includes(normalizedFilterText);
        }
        return item;
    });              

    return (
        <div className="student-table-container p-3">
            <h1>Gestión de Categorías</h1>
            <>
               {showLoading && <Loading />}

                {!showLoading && (
                <>
                        <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
                                <input
                                    type="text"
                                    placeholder="Buscar categorías..."
                                    value={filterTextCategorias}
                                    onChange={e => setFilterTextCategorias(e.target.value)}
                                    className="form-control form-control-sm w-50"
                                />
                                {/* Otros controles como botones para agregar nuevas categorías, etc. */}
                            <div className="btn-group">
                                <button className="btn-sm btn-Estudiante btn-NuevoEstudiante" onClick={openModal}>
                                    <i className="fa-solid fa-circle-plus"></i> Registrar Categoría
                                </button>
                            </div>
                            
                        </div>
                    
                        <DataTable
                            noHeader
                            noDataComponent="No hay categorias que mostrar, por favor agregalas"  
                            columns={columns}
                            data={filteredItems}
                            customStyles={customStyles}
                            pagination
                            persistTableHead
                        />
                </>
                )}

                <MDBModal tabIndex='-1' show={isModalOpen} setShow={setIsModalOpen}>
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>{selectedCategoria ? "Editar Categoría" : "Registrar Categoría"}</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>

                                {selectedCategoria && (
                                    <CategoriaForm
                                        categoria={selectedCategoria}
                                        onClose={()=>{
                                            toggleShow();
                                            fetchCategorias();
                                        }}
                                    />
                                )}

                                {!selectedCategoria && (
                                    <CategoriaForm
                                        categoria={selectedCategoria}
                                        onClose={()=>{
                                            toggleShow();
                                            fetchCategorias();
                                        }}
                                    />
                                )}
                            </MDBModalBody>
                            <MDBModalFooter>
                                <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                    Cerrar
                                </button>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
                
            </>
            
        </div>
          
            
        
    );
    
};

export default Categorias;
