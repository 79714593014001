import api from './api'; // Asumiendo que tienes un módulo de configuración de API

const getAllAcademicObservations = async () => {
    try {
        const response = await api.get('/academic/observation');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAcademicObservationById = async (academicObservationId) => {
    try {
        const response = await api.get(`/academic/observation/${academicObservationId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAcademicObservationByCourse = async (courseId, periodId, schoolYearId) => {
    try {
        const response = await api.get(`/academic/observation-course?courseId=${courseId}&periodId=${periodId}&schoolYearId=${schoolYearId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


const addAcademicObservation = async (academicObservation) => {
    try {
        const response = await api.post('/academic/observation', academicObservation);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateAcademicObservation = async (academicObservation) => {
    try {
        const response = await api.put(`/academic/observation`, academicObservation);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteAcademicObservation = async (academicObservationId) => {
    try {
        const response = await api.delete(`/academic/observation/${academicObservationId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getAllAcademicObservations,
    getAcademicObservationById,
    getAcademicObservationByCourse,
    addAcademicObservation,
    updateAcademicObservation,
    deleteAcademicObservation
};
