import api from './api'; // Asumiendo que tienes un módulo de configuración de API

const getAllBillingItems = async () => {
    try {
        const response = await api.get('/billing/discounts');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAllBillingDescuentos = async (studentId,journeyId, schoolYearId, educationLevelId, courseId) => {
    try {
        const response = await api.get(`/billing/discounts?studentId=${studentId}&journeyId=${journeyId}&schoolYearId=${schoolYearId}&educationLevelId=${educationLevelId}&courseId=${courseId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getBillingItemById = async (billingConceptId) => {
    try {
        const response = await api.get(`billing/discounts/${billingConceptId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const addBillingItem = async (billingItem) => {
    try {
        const response = await api.post('/billing/discounts', billingItem);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateBillingItem = async (billingItem) => {
    try {
        const response = await api.put(`/billing/discounts`, billingItem);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteBillingItem = async (billingConceptId) => {
    try {
        const response = await api.delete(`/billing/discounts/${billingConceptId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getAllBillingItems,
    getAllBillingDescuentos,
    getBillingItemById,
    addBillingItem,
    updateBillingItem,
    deleteBillingItem
};
