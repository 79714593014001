import { event } from "jquery";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoColegio from "../img/Logo-Colegio.png"; // Asegúrate de tener la ruta correcta al logo
import types from '../services/types';
import SidebarAdmin from "./sidebars/sidebarAdmin";
import SidebarCoodinator from "./sidebars/sidebarCoordinator";
import SidebarTeacher from "./sidebars/sidebarDocente";
import SidebarStudents from "./sidebars/sidebarEstudi-Padre";
import SidebarWorker from "./sidebars/sidebarWorker";

const Sidebar = () => {


  const activeMenu = (event) => {
    const sidebar = document.getElementById("sidebar");
    var menus = sidebar.getElementsByClassName("active");
    for (let index = 0; index < menus.length; index++) {
      const element = menus[index];
      element.className = element.className.replace("active", "");
    }
    const item =
      event.target.tagName === "SPAN" || event.target.tagName === "I"
        ? event.target.parentNode
        : event.target;
    item.parentNode.className = "sidebar-item active";
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const sidebarToggle = () => {
    const sidebar = document.getElementById("sidebar");
    sidebar.className = "sidebar collapsed";
  }

  const showMenus = (event) => {
    const item =
      event.target.tagName === "SPAN" || event.target.tagName === "I"
        ? event.target.parentNode
        : event.target;
    console.log(item.tagName);
    const closed = item.className.includes("collapsed");
    item.className = closed ? "sidebar-link" : "sidebar-link collapsed";
    const subMenuList =
      item.parentNode.getElementsByClassName("sidebar-dropdown");
    if (subMenuList && subMenuList.length > 0) {
      for (let i = 0; i < subMenuList.length; i++) {
        subMenuList[i].getElementsByClassName("sidebar-dropdown");
        if (closed) {
          subMenuList[i].className =
            "sidebar-dropdown list-unstyled collapse show";
        } else {
          subMenuList[i].className = "sidebar-dropdown list-unstyled collapse";
        }
      }
    }
  };

  const getMenus = () =>{
    const type = localStorage.getItem('userTypeId');
    if(type){
      const value = Number(type);
      switch(value){
        case types.userTypes.ADMIN:
          return (<SidebarAdmin
              showMenus={showMenus}
              activeMenu={activeMenu}
             />);
        case types.userTypes.WORKER:
          return (<SidebarWorker
            showMenus={showMenus}
            activeMenu={activeMenu}
           />);
        case types.userTypes.COODINATOR:
          return (<SidebarCoodinator
            showMenus={showMenus}
            activeMenu={activeMenu}
           />);
        case types.userTypes.TEACHER:
          return (<SidebarTeacher
            showMenus={showMenus}
            activeMenu={activeMenu}
           />);
        case types.userTypes.STUDENT:
        case types.userTypes.PARENTS:
          return (<SidebarStudents
            showMenus={showMenus}
            activeMenu={activeMenu}
           />);
        default:
          return(<ul className="sidebar-nav">
          <li className="sidebar-header"></li>
          </ul>);
    }
  }else{
       return (
          <ul className="sidebar-nav">
            <li className="sidebar-header"></li>
          </ul>
       );
    }
  }

  return (
    <>
    <nav id="sidebar" className="sidebar">
      <div className="sidebar-content js-simplebar">
        <Link to="/dashboard" className="sidebar-brand">
          <span className="align-middle me-3">
            <img
              src={LogoColegio}
              alt="Logo del Colegio"
              width="120"
              height="120"
            />{" "}
            {/* Ajusta el tamaño como necesites */}
            <br></br>
            <span className="light-text small">GESTION ACADEMICA</span>
          </span>
        </Link>
        {getMenus()}
        {/* <div className="sidebar-cta">
          <div className="sidebar-cta-content">
            <strong className="d-inline-block mb-2">
              Alerta de Notificaciones
            </strong>
            <div className="mb-3 text-sm">
              ¡Tienes nuevas notificaciones pendientes!
            </div>

            <div className="d-grid">
              <a
                
              >
                Ver Notificaciones
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </nav>

    </>
  );
}

export default Sidebar;
