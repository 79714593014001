import api from './api';

const getObservationNotasAcademicos = async () => {
    try {
        const response = await api.get(`disciplinary/observation-note`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getObservationNotasCourse = async (courseId, schoolSubjectId, schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`disciplinary/observation-note-course?courseId=${courseId}&schoolSubjectId=${schoolSubjectId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getObservationNotasCourseReport = async (studentId, courseId, schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`disciplinary/observation-report?studentId=${studentId}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createObservationNotaAcademico = async (periodoData) => {
    try {
        const response = await api.post(`disciplinary/observation-note`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createObservationNotaListAcademico = async (periodoData) => {
    try {
        const response = await api.post(`disciplinary/observation-note-list`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateObservationNotaListAcademico = async (periodoData) => {
    try {
        const response = await api.put(`disciplinary/observation-note-list`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateObservationNotaAcademico = async (periodoData) => {
    try {
        const response = await api.put(`disciplinary/observation-note`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getObservationNotaAcademico = async (id) => {
    try {
        const response = await api.get(`disciplinary/observation-note/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteObservationNotasAcademicoById = async (id) => {
    try {
        const response = await api.delete(`disciplinary/observation-note/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getObservationNotasAcademicos,
    createObservationNotaAcademico,
    createObservationNotaListAcademico,
    updateObservationNotaListAcademico,
    updateObservationNotaAcademico,
    getObservationNotaAcademico,
    deleteObservationNotasAcademicoById,
    getObservationNotasCourse,
    getObservationNotasCourseReport
};