import React, { useEffect, useRef, useState } from "react";
import Cardacademy from "../components/CategoryCard.js";
import estudiantesService from "../services/estudiantesService.js";
import gradesService from "../services/gradesService.js";
import Chart from "chart.js/auto";
import userProfile from "../img/user-profile.png";
import moment from "moment";

const Dashboard = () => {
  const chartRef = useRef(null);
  let myChart = null;
  const [gradesData, setGradesData] = useState([]);
  const [birthdayStudents, setBirthdayStudents] = useState([]);
  
  const fetchGradesData = async () => {
    try {
      const grades = await gradesService.getGradesWithStudentAmount();
      const data = await Promise.all(
        grades.map(async (grade) => {
          return {
            grade: grade.name,
            count: grade.studentAmount,
          };
        })
      );
      setGradesData(data);
    } catch (error) {
      console.error("Error fetching grades data:", error);
    }
  };

  useEffect(() => {
    fetchGradesData();
    fetchBirthDayStudents();
  }, []);

  const fetchBirthDayStudents = async () => {
    try {
      const result = await estudiantesService.getEstudiantesCumpleanios();
      if(result && result.length > 0){ 
        setBirthdayStudents(result);
      }else{
        setBirthdayStudents([]);
      }
    } catch (error) {
      console.error("Error fetching students data:", error);
    }
  };

  useEffect(() => {
    if (myChart) {
      myChart.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");
    const gradient = myChartRef.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "#DCF2F1"); // Empieza con este color en la parte superior
    gradient.addColorStop(0.33, "#7FC7D9"); // A un tercio del camino, cambia a este color
    gradient.addColorStop(0.66, "#365486"); // A dos tercios del camino, cambia a este color
    gradient.addColorStop(1, "#0F1035"); // Termina con este color en la parte inferior

    myChart = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: gradesData.map((data) => data.grade),
        datasets: [
          {
            label: "Estudiantes por Grado",
            backgroundColor: gradient,
            data: gradesData.map((data) => data.count),
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [gradesData]);

  const chartContainerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    padding: "10px",
  };

  return (
    <div className="container">
                <h1>Dashboard</h1>

      <div className="row">
      <div className="text-end mb-3">
            <div className="text-start float-inline-start">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSc6jLYQU9WZLXkHfdjb8rV6FAgwfVJR5FSWpK0Me9RPmajmsg/viewform" target="blank"
                    className="btn btn-success me-2">Solicitud de cupo</a>

                    <a href="https://docs.google.com/forms/d/1W6D0JlLXFgFNuLHQi26k29VZORnIsXDOCTkYwsfuiPg/edit?chromeless=1#responses" target="blank"
                    className="btn btn-info me-2">Reporte de inasistencia</a>

                    <a href="https://docs.google.com/forms/u/6/d/e/1FAIpQLScNEKWvyteX9iHKfibGhos7dshx_LC91gC5RPHLSz1w3Un_cA/viewform" target="blank"
                    className="btn btn-primary me-2 m-margin-top-10">Recibido entrega de informes</a>
            </div>
        </div>

          <Cardacademy
            title="Descuentos"
            icon="fas fa-percent"
            link="/cartera/descuentos"
            className="teachers-card"
          />
          <Cardacademy
            title="Recibos de pagos"
            icon="fas fa-receipt"
            link="/cartera/facturacion"
            className="calendar-card"
          />
          <Cardacademy
            title="Estado de Cuentas"
            icon="fa-solid fa-circle-dollar-to-slot"
            link="/cartera/estadodecuentas"
            className="students-card"
          />
          <Cardacademy
            title="Matrículas"
            icon="fas fa-address-book"
            link="/matriculas"
            className="grades-card"
          />
          <Cardacademy
            title="Conceptos de pago"
            icon="fas fa-cash-register"
            link="/cartera/conceptos"
            className="subjects-card"
          />

          {/* Puedes añadir más categorías según sea necesario */}
        </div>
        <div className="row">

        <div className="col-12 col-lg-8 d-flex">
  <div className="card flex-fill"> {/* Asegúrate de que la tarjeta se expanda completamente con flex-fill */}
    <div className="card-header">
      <h5 className="card-title">Estudiantes por Grado</h5>
      <h6 className="card-subtitle text-muted">
        Muestra la cantidad de estudiantes matriculados por cada grado.
      </h6>
    </div>
    <div className="card-body">
      <div className="chart" style={{ width: '100%', height: '300px' }}> {/* Ajusta el contenedor del gráfico para que ocupe todo el ancho disponible */}
        <canvas
          id="chartjs-bar"
          ref={chartRef}
          style={{ display: 'block', width: '100%', height: '120px' }}
        />
      </div>
    </div>
  </div>
</div>

     
      {/* Añadiendo la lista de cumpleaños de estudiantes */}
      <div className="col-12 col-lg-4 d-flex">
        <div className="card flex-fill w-100">
          <div className="card-header">
            <h5 className="card-title mb-0">Cumpleaños de estudiantes</h5>
          </div>
           <div className="card-body">
            {birthdayStudents.map((student, index) => (
              <div key={index} className="d-flex align-items-start">
                <img  src={(student && student.urlImage)?`${student.urlImage}`:userProfile}
                width="36" height="36" className="rounded-circle me-2" alt={student.fullName}/>
                <div className="flex-grow-1 margin-bottom-10">
                  <strong>{student.fullName}</strong> - <small>{student.grade}</small><br/>
                  <small className="text-muted">{student.currentBirthDate}</small>
                </div>
                <hr/>
              </div>
            ))}
            {/* <div className="d-grid">
              <a href="#" className="btn btn-primary">Ver todos</a>
            </div> */}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
