import React from 'react';
import '../../css/Informes-actas.css'; // Asegúrate de crear este archivo CSS y poner allí los estilos
import HeaderActa from '../headerActas';
import moment from 'moment';

const ActaIncidencia = ({ incidencia }) => {

  const getImnplicados = () =>{
      if(incidencia && incidencia.studentsFullName && incidencia.studentsFullName.includes(",")){
          return incidencia.studentsFullName.split(",");
      }else if(incidencia && incidencia.studentsFullName){
        return[incidencia.studentsFullName];
      }
      return []
  }
  const getCursos = () =>{
    if(incidencia){
        return incidencia.coursesName?.split(",");
    }
    return []
  }
  return (
    <div className="">
    <div className="card acta-container">
        <div className="card-body">

      {/* Cabezera */}
      <HeaderActa />

      
      {/* Ficha de Incidencia */}
      <section className="incidencia-info-boletin">
    <div className="info-header-boletin">
        <h5 className="section-title-boletin">FICHA DE INCIDENCIA ESCOLAR</h5>
    </div>
    <table className="datos-incidencia-table table table-sm my-2">
        <tbody>
            <tr>
                <th className="info-title-boletin">Incidencia</th>
                <td className="info-content-boletin">{incidencia.title}</td>
                <th className="info-title-boletin">Categoría</th>
                <td className="info-content-boletin">{incidencia.parentTypeName}</td>
            </tr>
            <tr>
                <th className="info-title-boletin">Tipo</th>
                <td className="info-content-boletin">{incidencia.incidenceTypeName}</td>
                <th className="info-title-boletin">Fecha</th>
                <td className="info-content-boletin">{moment(incidencia.incidenceDate).format('DD-MM-YYYY')}</td>
            </tr>
            <tr>
                <th className="info-title-boletin">Periodo</th>
                <td className="info-content-boletin">{incidencia.periodName}</td>
                <th className="info-title-boletin">Notifica</th>
                <td className="info-content-boletin">{incidencia.createdUserName}</td>
            </tr>
        </tbody>
    </table>
</section>

      
      {/* Descripción */}
      <section className="incidencia-desc">
      <h5 className="section-title-boletin">DESCRIPCIÓN DE LA INCIDENCIA</h5>
    <p className="info-content-boletin">
        {incidencia.description}
    </p>

        {/* Descripción de la incidencia */}
      </section>

      {/* Implicados */}
      <section className="incidencia-implicados">
        <h5 className="section-title-boletin">IMPLICADOS</h5>
        <table className="tabla-implicados">
          {/* Tabla de implicados */}
          <tbody>
            {getImnplicados()?.map((estudiante, index) => (
              <tr key={index}>
                <td>{estudiante}</td>
                <td>{getCursos()?.length > 0 ? getCursos()[index]: ""}</td>
                <td><div class="firma">
                    {/* Si se espera una firma electrónica, puedes usar un input */}
                    <input type="text" class="firma-input" />

                    </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
    </div>
      <br />
        <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
      <br />
    </div>
  );
};

export default ActaIncidencia;
