import React, { useState } from "react";
import { Link } from "react-router-dom";

const  SidebarTeacher = (props) => {
    const { showMenus, activeMenu}=props;

  return (

        <ul className="sidebar-nav">
          <li className="sidebar-header"></li>
  
          <li className="sidebar-item active">
            <Link onClick={activeMenu} className="sidebar-link" to="/dashboard">
            <i class="fas fa-bars"></i>              <span className="align-middle">Dashboard</span>
            </Link>
          </li>
 
          <li className="sidebar-item">
            <a
              onClick={showMenus}
              data-bs-toggle="collapse"
              className="sidebar-link collapsed"
            >
              <i className="fa-solid fa-graduation-cap"></i>
              <span className="align-middle">Académico</span>
            </a>
            <ul
              id="dashboards"
              data-bs-parent="#sidebar"
              className="sidebar-dropdown list-unstyled collapse"
            >
            
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/estudiantes"
                  className="sidebar-link"
                >
                  <i className="fas fa-user-graduate"></i>
                  Estudiantes
                </Link>
              </li>
            
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/procesos"
                  className="sidebar-link"
                >
                  <i class="fa-solid fa-spell-check"></i>
                  Procesos de notas
                </Link>
              </li>
           
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/Logros-estudiantes"
                  className="sidebar-link"
                >
                  <i className="fas fa-award"></i>
                  Logros académicos
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/NotasEstudiantes"
                  className="sidebar-link"
                >
                  <i className="fas fa-star"></i>
                  Planilla de Notas
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/evaluacion-preventiva"
                  className="sidebar-link"
                >
                  <i class="fas fa-hourglass-half"></i>
                  Evaluación preventiva
                </Link>
              </li>
              {/* Agrega aquí más elementos del submenú si es necesario */}
            </ul>
          </li>
          <li className="sidebar-item">
            <a
              onClick={showMenus}
              data-bs-toggle="collapse"
              className="sidebar-link collapsed"
            >
              <i className="fas fa-gavel"></i>
              <span className="align-middle">Disciplinario</span>
            </a>
            <ul
              id="disciplinario"
              data-bs-parent="#sidebar"
              className="sidebar-dropdown list-unstyled collapse"
            >
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/asistencia"
                  className="sidebar-link"
                >
                  <i className="fas fa-user-check"></i>
                  Asistencia
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/diciplinario/incidencias"
                  className="sidebar-link"
                >
                  <i class="fa-solid fa-person-burst"></i>
                  Incidencias
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/observador"
                  className="sidebar-link"
                >
                  <i class="fa-solid fa-stamp"></i>Observador
                </Link>
              </li>
            </ul>
          </li>

          <li className="sidebar-item">
            <a
              onClick={showMenus}
              data-bs-toggle="collapse"
              className="sidebar-link collapsed"
            >
              <i className="fas fa-chart-pie"></i>
              <span className="align-middle">Reportes</span>
            </a>
            <ul
              id="reportes"
              data-bs-parent="#sidebar"
              className="sidebar-dropdown list-unstyled collapse"
            >
               <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/informe-inasistencias"
                  className="sidebar-link"
                >
                  <i class="fa-solid fa-calendar-check"></i>
                  Asistencias
                </Link>
              </li>
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/informes"
                  className="sidebar-link"
                >
                  <i className="fas fa-scroll"></i>
                  Boletines/Informes
                </Link>
              </li>
              
            </ul>
          </li>
          <li className="sidebar-item">
            <a
              onClick={showMenus}
              data-bs-toggle="collapse"
              className="sidebar-link collapsed"
            >
              <i class="fa-solid fa-shield-halved"></i>
              <span className="align-middle">Ajustes de Perfil</span>
            </a>
            <ul
              id="configuraciones"
              data-bs-parent="#sidebar"
              className="sidebar-dropdown list-unstyled collapse"
            >
              <li className="sidebar-item">
                <Link
                  onClick={activeMenu}
                  to="/UserProfile"
                  className="sidebar-link"
                >
                  <i class="fa-solid fa-user-gear"></i>
                  Cuenta de usuario
                </Link>
              </li>
            </ul>
          </li>

        </ul>

  );
}

export default SidebarTeacher;
