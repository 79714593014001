import React, { useState, useEffect, useRef } from "react";
import api from "../services/estudiantesService";
import { Button } from "react-bootstrap";
import procesoService from "../services/procesoService";
import definicionNotaServices from "../services/definicionNotaServices";
import "../css/tabla-notas.css";
import SkeletonNotas from "../components/skeleton/SkeletonNotas"; // Importa el componente Skeleton
import JornadaAnioPeriodoNivelCursoAsig from "../components/Filters/JornadaAnioPeriodoNivelCursoAsig";
import utils from "../utils/generalFunctions";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import FormDefinicionNotas from "../components/Forms/FormDefinicionNotas";
import Swal from "sweetalert2";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import noteService from "../services/noteService";
import logroNoteService from "../services/logroNoteService";
import collectionsService from "../services/collectionsService";
import evaluativeScaleService from "../services/evaluativeScaleService";
import Loading from "../components/Loading";
import logrosServices from "../services/logrosServices";
import TablaNotasDimensiones from "./tablaNotasDimensones";
import types from "../services/types";
import observacionesService from "../services/observacionesService";
import { toast } from 'react-toastify';

const NotasEstudiantes = () => {
  const [estudiantes, setEstudiantes] = useState([]); // Almacenamos los estudiantes aquí
  const [procesos, setProcesos] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [notas, setNotas] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [notasTabla, setNotasTabla] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [isLoading, setIsLoading] = useState(true); // Estado para el loading
  const [filters, setFilters] = useState({
    // Estado para los filtros aplicados
    teacherId: "0",
    journeyId: "0",
    schoolYearId: "0",
    educationLevelId: "0",
    courseId: "0",
    isRecuperation:false
  });
  const [paramPlanilla, setParamPlanilla] = useState({});
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [selectedDefNota, setSelectedDefNota] = useState(null);
  const filteReference = useRef(null);
  const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [decimalesPlanilla, setDecimalesPlanilla] = useState(0);
  const [maximaNota, setMaximaNota] = useState(0);
  const [minimaNota, setMinimaNota] = useState(0);
  const [evaluationScale, setEvaluationScale] = useState([]);
  const [observaciones, setObservaciones] = useState([]);

  let notasTemp = [];

  const fetchLoadGeneralParams = async () => {
    try {
      const parameters = await collectionsService.getParameters();
      const decimales = parameters.find(
        (op) => String(op.keyName) === "decimalesPlanilla"
      );
      if (decimales) {
        setDecimalesPlanilla(Number(decimales.value));
      }
      const maximaNotaR = parameters.find(
        (op) => String(op.keyName) === "maximaNota"
      );
      if (maximaNotaR) {
        setMaximaNota(Number(maximaNotaR.value));
      }
      const minimaNotaR = parameters.find(
        (op) => String(op.keyName) === "minimaNota"
      );
      if (minimaNotaR) {
        setMinimaNota(Number(minimaNotaR.value));
      }
    } catch (error) {
      console.error("Error al obtener parametros de formulario:", error);
    }
  };

  useEffect(() => {
    fetchLoadGeneralParams();
  }, []);

  const openMaximizeModal = (proceso) => {
    setFullscreenXlModal(true);
  };

  const closeModal = (close) => {
    setFullscreenXlModal(false);
  };

  const cargarNotas = async () => {
    const notasDB = await noteService.getNotasCourse(
      paramPlanilla.courseId,
      paramPlanilla.schoolSubjectId,
      paramPlanilla.schoolYearId,
      paramPlanilla.periodId
    );
    setNotas(notasDB);
    notasTemp = notasDB;
  };

  const cargarObservaciones = async () => {

    const observacionesResponse = await observacionesService.getAcademicObservationByCourse(
      paramPlanilla.courseId,
      paramPlanilla.periodId,
      paramPlanilla.schoolYearId,
    ); // Obtenemos los estudiantes de tu sistema

    const estudiantesActualizados = estudiantes.map(est => {
      // Buscamos el estudiante específico que estamos modificando
      const obs = observacionesResponse.find(o=> o.studentId===est.studentId);
      if(obs){
        // Creamos una nueva copia del estudiante para no modificar directamente el objeto
        return {
          ...est,
          observaciones: {
            ...est.observaciones,
            observations: obs.observations,
            academicObservationId: obs.academicObservationId  // Actualizamos el valor de observaciones
          }
        };
      }
      return est; // Retornamos los demás estudiantes sin modificar
    });
    // Actualizamos el estado con la nueva lista de estudiantes
    setEstudiantes(estudiantesActualizados);
  };
  

  const cargarNotasLogros = async () => {
    const notasDB = await logroNoteService.getLogroNotasCourse(
      paramPlanilla.courseId,
      paramPlanilla.schoolSubjectId,
      paramPlanilla.schoolYearId,
      paramPlanilla.periodId
    );
    setNotas(notasDB);
    notasTemp = notasDB;
  };

  const cargarProcesos = async (
    courseId,
    schoolSubjectId,
    schoolYearId,
    academicPeriodId,
    evaluationTypeId,
    isRecuperation
  ) => {
    const process = await procesoService.getProcesosToNotas(
      courseId,
      schoolSubjectId,
      schoolYearId,
      academicPeriodId,
      evaluationTypeId
    );
    const procesosDB  = process.filter(p=> p.isRecuperation === (isRecuperation?1:0));
    if (evaluationTypeId === types.evaluationTypes.ESCALA_VALORATIVA) {
      const notasDB = await noteService.getNotasCourse(
        courseId,
        schoolSubjectId,
        schoolYearId,
        academicPeriodId
      );
      setNotas(notasDB);
      notasTemp = notasDB;
    } else {
      const notasDB = await logroNoteService.getLogroNotasCourse(
        courseId,
        schoolSubjectId,
        schoolYearId,
        academicPeriodId
      );
      setNotas(notasDB);
      notasTemp = notasDB;
    }
    if (procesosDB.length > 0) {
      for (let i = 0; i < procesosDB.length; i++) {
        const evaluationProcessId = procesosDB[i].evaluationProcessId;
        if (evaluationTypeId === 2) {
          const notas =
            await definicionNotaServices.getDefinicionDeNotaByProcessId(
              evaluationProcessId
            );
          procesosDB[i]["notas"] = notas ? notas : [];
        } else {
          const logros = await logrosServices.getLogroByProcessId(
            evaluationProcessId
          );
          procesosDB[i]["logros"] = logros ? logros : [];
        }
      }
    }
    setProcesos(procesosDB);
    return procesosDB;
  };

  // Función para añadir un nuevo proceso
  const agregarDefinicionDeNota = (proceso) => {
    setSelectedDefNota(null);
    setSelectedProcess(proceso);
    openMaximizeModal(proceso);
  };

  const editarDefinicionDeNota = (definicionNota) => {
    setSelectedDefNota(definicionNota);
    setSelectedProcess(null);
    openMaximizeModal(null);
  };

  // Aquí podrías implementar la lógica para manejar los cambios en las notas y el cálculo de promedios
  // ...
  // Función para calcular el promedio de un proceso
  const calcularPromedio = (notasProceso) => {
    const notasValidas = notasProceso.filter(
      (nota) => !isNaN(parseFloat(nota)) && parseFloat(nota) >= 0
    );
    const sumaNotas = notasValidas.reduce((acc, nota) => acc + Number(nota), 0);
    const promedio =
      notasValidas.length > 0 ? sumaNotas / notasValidas.length : 0;
    return promedio.toFixed(2); // Redondear a dos decimales para el promedio
  };

  // Función para calcular la nota definitiva de un estudiante
  const calcularNotaDefinitiva = (studentId) => {
    let promedios = procesos.map((proceso) => {
      const notasProceso = notas
        .filter(
          (nota) =>
            nota.studentId === studentId &&
            nota.evaluationProcessId === proceso.evaluationProcessId
        )
        .map((nota) => nota.note);
      return parseFloat(calcularPromedio(notasProceso)); // Convierte el resultado en un número flotante
    });
  };

  const handleNotaChange2 = async (
    proceso,
    definiNota,
    studentId,
    valor,
    e
  ) => {
    e.dataset.change = true;
    setNotasTabla((prevState) => ({
      ...prevState,
      [`${studentId}-${proceso}-${definiNota}`]: valor,
    }));
  };

  const handleSaveNota = async (proceso, definiNota, studentId, valor, e) => {
    // Creamos una copia de las notas actuales
    if (e.dataset.change === "true") {
      const nValor = Number(valor);
      if(nValor && !isNaN(nValor)){
        if(nValor<minimaNota || nValor>maximaNota){
          const mensaje = (nValor<minimaNota) ? `Error, la nota minima para califica es ${minimaNota}`:
          `Error, la nota maxima para califica es ${maximaNota}`
          toast.error(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          //const process = procesos.find((p) => p.evaluationProcessId === proceso);
          const nota = notas.find(
            (n) =>
              n.notesDefinitionId === Number(definiNota) &&
              n.evaluationProcessId === Number(proceso) &&
              n.studentId === Number(studentId)
          );
          if(nota){
            e.value = nota.note;
          }else{
            e.value = "";
          }
          return false;
        }
      }
      e.dataset.change = false;
      const process = procesos.find((p) => p.evaluationProcessId === proceso);
      const nota = notas.find(
        (n) =>
          n.notesDefinitionId === Number(definiNota) &&
          n.evaluationProcessId === Number(proceso) &&
          n.studentId === Number(studentId)
      );
      const nuevasNotasTabla = notasTabla;
      if (nota) {
        if (valor.trim() !== "") {
          nota.note = utils.roundNumber(Number(valor), decimalesPlanilla);
          const notaBD = await guardarNota(nota, e);
          if (notaBD) {
            await cargarNotas();
          }
        } else {
          const eliminado = await eliminarNota(nota, e);
          if (eliminado) {
            await cargarNotas();
          }
        }
      } else {
        if (valor.trim() !== "") {
          const newNote = {
            noteId: 0,
            notesDefinitionId: definiNota,
            evaluationProcessId: proceso,
            studentId: studentId,
            teacherId: paramPlanilla.teacherId,
            note: utils.roundNumber(Number(valor), decimalesPlanilla),
          };
          const notaBD = await guardarNota(newNote, e);
          if (notaBD && notaBD.noteId > 0) {
            await cargarNotas();
          }
        }
      }
      nuevasNotasTabla[`${studentId}-${proceso}-${definiNota}`] = valor
        ? utils.roundNumber(Number(valor), decimalesPlanilla)
        : "";
      nuevasNotasTabla[`${studentId}-${proceso}-promedio`] = calcularPromedios(
        studentId,
        process
      );
      nuevasNotasTabla[`${studentId}-total`] = calcularDefinitiva(
        studentId,
        procesos
      );
    }
  };

  const toNextNote = (e) => {
    if (e.key === "Enter") {
      // Perform an action when the Enter key is pressed
      const txt = e.target;
      const td = txt.parentNode;
      const tr = txt.parentNode.parentNode;
      const table = txt.parentNode.parentNode.parentNode;
      const numeroCelda = utils.getChildIndex(td);
      const numeroFila = utils.getChildIndex(tr);
      const nextElement =
        table.children[numeroFila + 1]?.children[numeroCelda]?.children[0];
      if (nextElement) {
        nextElement.focus();
        utils.moveCursorToEnd(nextElement);
      }
      return false;
    }
  };

  const guardarNota = async (nota, e) => {
    if (nota.noteId > 0) {
      const notasDB = await noteService.updateNotaAcademico(nota);
      if (notasDB && notasDB.noteId > 0 && notasDB.note === nota.note) {
        e.style.backgroundColor = "#fff299";
      }
      return notasDB;
    } else {
      const notasDB = await noteService.createNotaAcademico(nota);
      if (notasDB && notasDB.noteId > 0 && notasDB.note === nota.note) {
        e.style.backgroundColor = "#cdfdc1";
      }
      return notasDB;
    }
  };

  const eliminarNota = async (nota, e) => {
    if (nota.noteId > 0) {
      try {
        await noteService.deleteNotasAcademicoById(nota.noteId);
        e.style.backgroundColor = "#fff299";
        return true;
      } catch (ex) {
        e.style.backgroundColor = "#ffc7c5";
        console.log(ex);
        return false;
      }
    } else {
      e.style.backgroundColor = "#ffc7c5";
      return false;
    }
  };

  const handleSaveObservation = async (observation, e) => {
    // Creamos una copia de las notas actuales
    if (e.dataset.change === "true") {
      e.dataset.change = false;
      if (observation.observations.trim() !== "") {
        const observacionDB = await guardarObservacion(observation, e);
        if (observacionDB) {
          await cargarObservaciones();
        }
      } else {
        const eliminado = await eliminarObservacion(observation, e);
        if (eliminado) {
          await cargarObservaciones();
        }
      }
    }
  };

  const handleObservacionChange2 = (estudiantes, estudianteModificado, nuevoValor, e) => {
    e.dataset.change = true;
    // Creamos una nueva copia de la lista de estudiantes
    const estudiantesActualizados = estudiantes.map(est => {
      // Buscamos el estudiante específico que estamos modificando
      if (est === estudianteModificado) {
        // Creamos una nueva copia del estudiante para no modificar directamente el objeto
        return {
          ...est,
          observaciones: {
            ...est.observaciones,
            observations: nuevoValor  // Actualizamos el valor de observaciones
          }
        };
      }
      return est; // Retornamos los demás estudiantes sin modificar
    });
  
    // Actualizamos el estado con la nueva lista de estudiantes
    setEstudiantes(estudiantesActualizados);
  };


  const guardarObservacion = async (observacion, e) => {
    if (observacion.academicObservationId > 0) {
      const observacionDB = await observacionesService.updateAcademicObservation(observacion);
      if (observacionDB && observacionDB.academicObservationId > 0 && observacionDB.observations === observacion.observations) {
        e.style.backgroundColor = "#fff299";
      }
      return observacionDB;
    } else {
      const observacionDB = await observacionesService.addAcademicObservation(observacion);
      if (observacionDB && observacionDB.academicObservationId > 0 && observacionDB.observations === observacion.observations) {
        e.style.backgroundColor = "#cdfdc1";
      }
      return observacionDB;
    }
  };

  const eliminarObservacion = async (observation, e) => {
    if (observation.academicObservationId > 0) {
      try {
        await observacionesService.deleteAcademicObservation(observation.academicObservationId);
        e.style.backgroundColor = "#fff299";
        return true;
      } catch (ex) {
        e.style.backgroundColor = "#ffc7c5";
        console.log(ex);
        return false;
      }
    } else {
      e.style.backgroundColor = "#ffc7c5";
      return false;
    }
  };

  const fillNotes = (estudiantes, procesos, evaluationTypeId) => {
    const notasBD = notasTemp.length > 0 ? notasTemp : notas;

    let notes = [];
    if (evaluationTypeId === 2) {
      for (let i = 0; i < estudiantes.length; i++) {
        for (let j = 0; j < procesos.length; j++) {
          for (let k = 0; k < procesos[j].notas.length; k++) {
            let nota = notasBD.find(
              (n) =>
                n.notesDefinitionId ===
                  Number(procesos[j].notas[k].notesDefinitionId) &&
                n.evaluationProcessId ===
                  Number(procesos[j].evaluationProcessId) &&
                n.studentId === Number(estudiantes[i].studentId)
            );
            notes[
              `${estudiantes[i].studentId}-${procesos[j].evaluationProcessId}-${procesos[j].notas[k].notesDefinitionId}`
            ] = nota
              ? utils.roundNumber(Number(nota.note), decimalesPlanilla)
              : "";
          }
          notes[
            `${estudiantes[i].studentId}-${procesos[j].evaluationProcessId}-promedio`
          ] = calcularPromedios(estudiantes[i].studentId, procesos[j]);
        }
        notes[`${estudiantes[i].studentId}-total`] = calcularDefinitiva(
          estudiantes[i].studentId,
          procesos
        );
      }
      setNotasTabla(notes);
    } else {
      for (let i = 0; i < estudiantes.length; i++) {
        for (let j = 0; j < procesos.length; j++) {
          for (let k = 0; k < procesos[j].logros.length; k++) {
            let nota = notasBD.find(
              (n) =>
                n.achievementId ===
                  Number(procesos[j].logros[k].achievementId) &&
                n.evaluationProcessId ===
                  Number(procesos[j].evaluationProcessId) &&
                n.studentId === Number(estudiantes[i].studentId)
            );
            notes[
              `${estudiantes[i].studentId}-${procesos[j].evaluationProcessId}-${procesos[j].logros[k].achievementId}`
            ] = nota
              ? {
                  evaluativeScaleId: nota.evaluativeScaleId,
                  achievementEvaluateId: nota.achievementEvaluateId,
                }
              : "";
          }
        }
      }
      setNotasTabla(notes);
    }
  };

  const calcularPromedios = (studenstId, process) => {
    const notasBD = notasTemp.length > 0 ? notasTemp : notas;
    let promedio = 0;
    let amount = 0;
    if (process && process.notas.length > 0) {
      for (let defNota of process.notas) {
        const nota = notasBD.find(
          (n) =>
            n.notesDefinitionId === Number(defNota.notesDefinitionId) &&
            n.evaluationProcessId === Number(defNota.evaluationProcessId) &&
            n.studentId === Number(studenstId)
        );
        if (nota) {
          promedio += nota.note;
          amount += 1;
        }
      }
    }
    return utils.roundNumber(
      amount > 0 ? promedio / amount : 0,
      decimalesPlanilla
    );
  };

  const calcularDefinitiva = (studenstId, procesArray) => {
    const notasBD = notasTemp.length > 0 ? notasTemp : notas;
    let totalNota = 0;
    let totalPercentage = 0;
    for (let process of procesArray) {
      let procesoUsado = false;
      let notaPromedio = 0;
      let sumaNotas = 0;
      let amount = 0;
      if (process && process.notas.length > 0) {
        for (let defNota of process.notas) {
          const nota = notasBD.find(
            (n) =>
              n.notesDefinitionId === Number(defNota.notesDefinitionId) &&
              n.evaluationProcessId === Number(defNota.evaluationProcessId) &&
              n.studentId === Number(studenstId)
          );
          if (nota) {
            sumaNotas += nota.note;
            amount += 1;
            procesoUsado = true;
          }
        }
        notaPromedio = amount > 0 ? sumaNotas / amount : 0;
      }
      totalPercentage = procesoUsado
        ? totalPercentage + process.percentage
        : totalPercentage;
      totalNota = procesoUsado
        ? totalNota + (notaPromedio * process.percentage) / 100
        : totalNota;
    }
    totalNota = totalPercentage > 0 ? (totalNota * 100) / totalPercentage : 0;
    return utils.roundNumber(totalNota, decimalesPlanilla);
  };

  const getScaleFormat = (totalNota) => {
    const total = utils.roundNumber(totalNota, decimalesPlanilla);
    const scale = evaluationScale.find(
      (s) => total >= s.minScore && total <= s.maxScore
    );

    if (scale) {
      return (
        <span style={{ color: scale.color }}>
          {total + " - " + scale.abbreviation}
        </span>
      );
    } else {
      return <span>{total}</span>;
    }
  };

  const handleFilterChange = (newFilters) => {
    // Actualiza el estado de filters con los nuevos valores
    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  };

  const cargarPlanilla = async () => {
    setShowLoading(true);
    setIsLoading(true); // Cambia el estado a false una vez que los datos se carguen    
    const copyData = utils.copy(filters);
    setActiveTab((Number(copyData.schoolSubjectId)===-1)?"observaciones":"gestion-notas")
    const cursos = filteReference?.current?.getCursos();
    if (cursos.length > 0) {
      copyData.gradeId = cursos.find(
        (c) => String(c.courseId) === String(copyData.courseId)
      )?.gradeId;
    }
    const educationLevels = filteReference?.current?.getEducationLeves();
    if (educationLevels.length > 0){
      copyData.evaluationTypeId = educationLevels.find(
        (c) => String(c.educationLevelId) === String(copyData.educationLevelId)
      )?.evaluationTypeId;
    }
    setParamPlanilla(copyData);
    try {
      let data = await api.getEstudiantesPorCurso(
        "",
        copyData.journeyId,
        copyData.schoolYearId,
        copyData.educationLevelId,
        copyData.courseId
      ); // Obtenemos los estudiantes de tu sistema
      data = data.filter(s=>s.isActive);
      if(copyData.isRecuperation){
        const lostEstudiantes = await api.getEstudianteToNivelar(
          "",
          copyData.courseId,
          copyData.schoolYearId,
          copyData.periodId
        ); // Obtenemos los estudiantes de tu sistema
        data = data.filter(est => lostEstudiantes.students.find(e=>e.studentId === est.studentId && e.areasNotes.filter(a=>
          a.subjectNotes.find(s=> String(s.schoolSubjetId) === String(copyData.schoolSubjectId)) !=null  ).length > 0 )!=null);
      }
      const observacionesResponse = await observacionesService.getAcademicObservationByCourse(
        copyData.courseId,
        copyData.periodId,
        copyData.schoolYearId
      ); // Obtenemos los estudiantes de tu sistema
      data.map(est=>{
          const obs = observacionesResponse.find(o=> o.studentId===est.studentId);
          if(obs){
            est.observaciones = obs;
          }else{
            est.observaciones = {
              academicObservationId:0,
              schoolYearId: copyData.schoolYearId,
              academicPeriodId: copyData.periodId,
              courseId: copyData.courseId,
              gradeId: copyData.gradeId,
              studentId:est.studentId,
              observations: ""
            }
          }
          return est;
      })
      setEstudiantes(data); // Guardamos los estudiantes en el estado
      setObservaciones(observacionesResponse);
      const evaluationScale = await evaluativeScaleService.getEvaluativeScale(
        copyData.evaluationTypeId
      );
      setEvaluationScale(evaluationScale);
      const process = await cargarProcesos(
        copyData.courseId,
        copyData.schoolSubjectId,
        copyData.schoolYearId,
        copyData.periodId,
        copyData.evaluationTypeId,
        copyData.isRecuperation
      );
      fillNotes(data, process, copyData.evaluationTypeId);
    } catch (error) {
      console.error("Error al obtener estudiantes:", error);
    }
    setIsLoading(false); // Cambia el estado a false una vez que los datos se carguen
    setShowLoading(false);
    setShowSearch(true);
  };

  const deleteDefinicionDeNota = async (definiNota) => {
    Swal.fire({
      title: "Esta seguro que desea eliminar esta definición de nota?",
      text: "Al eliminar esta definición de nota se eliminaran todas las notas asociadas a ella.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await definicionNotaServices.deleteDefinicionDeNotaById(
            definiNota.notesDefinitionId
          ); // Asegúrate de tener un método deleteCursoById en cursoService
          Swal.fire(
            "Felicidades!",
            "Definición de nota eliminada exitosamente!",
            "success"
          );
          cargarPlanilla();
        } catch (error) {
          Swal.fire(
            "Ha ocurrido un error",
            "Por favor intentelo mas tarde",
            "error"
          );
        }
      }
    });
  };

  const [activeTab, setActiveTab] = useState("gestion-notas");

  const handleSelect = (tabId) => {
    setActiveTab(tabId);
  };

  const exportarExcel = () =>{
    const id="table-notes-completed";
    const name ="Planilla de notas";
    utils.exportTableToExcel(id, name);
  }

  // Aquí renderizamos el componente
  return (
    <>
      <div className="student-table-container p-3 p-4 mb-3">
        <h1>Planilla de notas</h1>
        <div className="row">
          <JornadaAnioPeriodoNivelCursoAsig
            callback={handleFilterChange}
            ref={filteReference}
            loadTutoria={true}
            loadCoordinators={true}
            properties={{
              className: "col-md-2 mb-2",
              id: "formTest",
              filtro: false,
            }}
          />
           <div className="col-md-2 mb-2 form-check margin-top-50">
              <input type="checkbox" className="form-check-input" id="isRecuperation" name="isRecuperation"
                  checked={filters.isRecuperation} onChange={e => setFilters({...filters, isRecuperation: e.target.checked})} />
              <label className="form-check-label" htmlFor="isRecuperation">Recuperación</label>
          </div>

          <div className="col-md-1 mb-1 margin-top-45">
            <Button
              onClick={() => {
                cargarPlanilla();
              }}
              className="ml-2 btn btn-primary btn-lg"
            >
              Buscar
            </Button>
          </div>
        </div>
      </div>

      {showLoading && <Loading />}

      {showSearch && (
        <>
          {!showLoading && (
            <div class="col-12 col-lg-12">
              
              <div class="tab">
                  <ul class="nav nav-tabs" role="tablist">
                  {Number(paramPlanilla.schoolSubjectId) !== -1 && (
                    <li class="nav-item" role="presentation">
                      <a className={`nav-link ${activeTab === 'gestion-notas' ? 'active' : ''}`}
                        href="#gestion-notas"
                        onClick={(e) => { e.preventDefault(); handleSelect('gestion-notas'); }}>
                        <i class="fa-regular fa-star"></i> Gestión de Notas
                      </a>
                    </li>
                    )}
                    {Number(paramPlanilla.schoolSubjectId) === -1 && (
                        <li class="nav-item" role="presentation">
                          <a className={`nav-link ${activeTab === 'observaciones' ? 'active' : ''}`}
                            href="#observaciones"
                            onClick={(e) => { e.preventDefault(); handleSelect('observaciones'); }}>
                            <i class="fa-regular fa-comment-dots"></i> Observaciones
                          </a>
                        </li>
                    )}
                  </ul>
                  <div class="tab-content">
                  {Number(paramPlanilla.schoolSubjectId) !== -1 && (
                    <div className={`tab-pane ${activeTab === 'gestion-notas' ? 'show active' : 'fade'}`} id="gestion-notas" role="tabpanel">
                    <div fluid className="p-3">
                                      {paramPlanilla.evaluationTypeId === 2 && (
                                      <div className="d-flex justify-content-between align-items-center my-4">
                                        <h1 className="text-center my-4">Gestión de Notas</h1>
                                        <Button
                                          variant="primary"
                                          onClick={() => {
                                            agregarDefinicionDeNota(null);
                                          }}
                                          className="sm"
                                        >
                                          <i class="fa-solid fa-circle-plus"></i> Añadir
                                          definición de notas
                                        </Button>
                                      </div>
                                      )}
                                      
                                      {paramPlanilla.evaluationTypeId === 2 && (
                                        <div className="row">
                                          <div
                                            class="alert alert-primary alert-outline alert-dismissible"
                                            role="alert"
                                          >
                                            <button
                                              type="button"
                                              class="btn-close display-none"
                                              data-bs-dismiss="alert"
                                              aria-label=""
                                            ></button>
                                            <div class="alert-icon">
                                              <i class="far fa-fw fa-bell"></i>
                                            </div>
                                            <div class="alert-message text-center">
                                              {evaluationScale.map((scale, index) => (
                                                <>
                                                  <span className="margin-left-10">
                                                    {scale.name}{" "}
                                                    <strong style={{ color: scale.color }}>
                                                      {" "}
                                                      {scale.abbreviation +
                                                        " (" +
                                                        scale.minScore.toFixed(
                                                          decimalesPlanilla
                                                        ) +
                                                        " - " +
                                                        scale.maxScore.toFixed(
                                                          decimalesPlanilla
                                                        ) +
                                                        ") "}{" "}
                                                    </strong>
                                                  </span>
                                                </>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {paramPlanilla.evaluationTypeId === 1 && (
                                        <div className="row">
                                          <div
                                            class="alert alert-primary alert-outline alert-dismissible"
                                            role="alert"
                                          >
                                            <button
                                              type="button"
                                              class="btn-close display-none"
                                              data-bs-dismiss="alert"
                                              aria-label=""
                                            ></button>
                                            <div class="alert-icon">
                                              <i class="far fa-fw fa-bell"></i>
                                            </div>
                                            <div class="alert-message text-center">
                                              {evaluationScale.map((scale, index) => (
                                                <>
                                                  <span className="margin-left-10">
                                                    {scale.name}{" "}
                                                    <strong style={{ color: scale.color }}>
                                                      {" ("} {scale.abbreviation} {") "}
                                                    </strong>
                                                  </span>
                                                </>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      <div className="custom-table div-table-notas scroll-table">
                                        {paramPlanilla.evaluationTypeId === 2 && (
                                          <div className="table-responsive">
                                            {/* <button className='btn btn-success' onClick={(e)=>{exportarExcel()}}>Exportar a Excel</button> */}
                                            <table id="table-notes-completed" className="striped bordered hover responsive table-notas">
                                              <thead className="table-notas-head">
                                                <tr>
                                                  <th
                                                    rowSpan={3}
                                                    className="student-name-column celda-estudiante"
                                                  >
                                                    ESTUDIANTE
                                                  </th>
                                                  {procesos.map((proceso, index) => (
                                                    <th
                                                      key={index}
                                                      colSpan={proceso.notas.length + 1}
                                                    >
                                                      {proceso.description}{" "}
                                                      {proceso.percentage
                                                        ? ` (${proceso.percentage}%)`
                                                        : ""}
                                                      <span> </span>
                                                      <span
                                                        size="sm"
                                                        onClick={() =>
                                                          agregarDefinicionDeNota(proceso)
                                                        }
                                                      >
                                                        <i class="fa-solid fa-circle-plus"></i>
                                                      </span>
                                                    </th>
                                                  ))}
                                                  <th>NOTA</th>
                                                </tr>
                                                <tr>
                                                  {procesos.map((proceso, index) => (
                                                    <>
                                                      {proceso.notas.map(
                                                        (definiNota, indexNota) => (
                                                          <>
                                                            <th
                                                              key={definiNota.notesDefinitionId}
                                                            >
                                                              <span
                                                                data-tooltip-id="my-tooltip"
                                                                data-tooltip-content={
                                                                  definiNota.description
                                                                }
                                                                href="#"
                                                              >
                                                                Nota {indexNota + 1}
                                                              </span>
                                                            </th>
                                                          </>
                                                        )
                                                      )}
                                                      <th>Nota Proc.</th>
                                                    </>
                                                  ))}
                                                  <th>DEF.</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {isLoading ? (
                                                  // Muestra el skeleton mientras los datos se están cargando
                                                  <SkeletonNotas
                                                    numProcesos={procesos.length}
                                                  /> // Ajusta el número de procesos
                                                ) : (
                                                  // Renderiza los datos una vez cargados
                                                  estudiantes.map((estudiante, indexEst) => (
                                                    <tr key={indexEst}>
                                                      <td className="celda-estudiante">
                                                        {estudiante.fullName}
                                                      </td>
                                                      {procesos.map((proceso, indexProc) => (
                                                        <React.Fragment key={indexProc}>
                                                          {proceso.notas.map(
                                                            (definiNota, indexNota) => (
                                                              <td key={indexNota}>
                                                                <input
                                                                  type="text"
                                                                  autocomplete="off"
                                                                  style={{
                                                                    height: "25px",
                                                                    width: "50px",
                                                                  }}
                                                                  className="form-control"
                                                                  id={"nota" + indexNota}
                                                                  name={"nota" + indexNota}
                                                                  data-change="false"
                                                                  value={
                                                                    notasTabla[
                                                                      `${estudiante.studentId}-${proceso.evaluationProcessId}-${definiNota.notesDefinitionId}`
                                                                    ]
                                                                  }
                                                                  onKeyPress={(e) => {
                                                                    toNextNote(e);
                                                                  }}
                                                                  onBlur={(e) =>
                                                                    handleSaveNota(
                                                                      proceso.evaluationProcessId,
                                                                      definiNota.notesDefinitionId,
                                                                      estudiante.studentId,
                                                                      e.target.value,
                                                                      e.target
                                                                    )
                                                                  }
                                                                  onChange={(e) =>
                                                                    handleNotaChange2(
                                                                      proceso.evaluationProcessId,
                                                                      definiNota.notesDefinitionId,
                                                                      estudiante.studentId,
                                                                      e.target.value,
                                                                      e.target
                                                                    )
                                                                  }
                                                                />
                                                                {/* Aquí iría un input para ingresar la nota */}
                                                              </td>
                                                            )
                                                          )}
                                                          <td>
                                                            {getScaleFormat(
                                                              notasTabla[
                                                                `${estudiante.studentId}-${proceso.evaluationProcessId}-promedio`
                                                              ]
                                                            )}
                                                            {/* Aquí iría el promedio del proceso */}
                                                          </td>
                                                        </React.Fragment>
                                                      ))}
                                                      <td style={{ width: "100px" }}>
                                                        {getScaleFormat(
                                                          notasTabla[
                                                            `${estudiante.studentId}-total`
                                                          ]
                                                        )}
                                                        {/* Aquí iría la nota definitiva del estudiante */}
                                                      </td>
                                                    </tr>
                                                  ))
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        )}

                                        {paramPlanilla.evaluationTypeId === 1 && (
                                          <TablaNotasDimensiones
                                            students={estudiantes}
                                            process={procesos}
                                            evaluationScale={evaluationScale}
                                            tablaNotes={notasTabla}
                                            paramPlanilla={paramPlanilla}
                                            refreshNotas={cargarNotasLogros}
                                          />
                                        )}
                                      </div>

                                      {paramPlanilla.evaluationTypeId === 2 && (
                                        <div className="row">
                                          <hr />
                                          <div
                                            class="alert alert-primary alert-dismissible"
                                            role="alert"
                                          >
                                            <div class="alert-message">
                                              <h4>Definiciones de nota</h4>
                                            </div>
                                          </div>
                                          {procesos.map((proceso, index) => (
                                            <>
                                              <div className="col-md-4 scroll-table">
                                                <div class="card definicio definicion-notas-container">
                                                  <div class="card-header definicion-notas-header">
                                                    <h5 class="card-title badge badge-soft-info  mb-0 px-1 definicion-notas-title">
                                                      Proceso:{" "}
                                                    </h5>
                                                    <br></br>
                                                    {proceso.description}{" "}
                                                    {proceso.percentage
                                                      ? " (" + proceso.percentage + "%)"
                                                      : ""}
                                                  </div>
                                                  <div class="card-body definicion-notas-body scroll-table">
                                                    {proceso.notas.map(
                                                      (definiNota, indexNota) => (
                                                        <>
                                                          <table class="table table-sm my-2 definicion-notas-tabla">
                                                            <tbody>
                                                              <tr>
                                                                <th>Nota {indexNota + 1}:</th>
                                                                <td>
                                                                  <small class="text-muted my-2">
                                                                    {definiNota.description}
                                                                  </small>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <th>Fecha:</th>
                                                                <td>
                                                                  {moment(
                                                                    definiNota.evaluationDate
                                                                  ).format("DD-MM-yyyy")}
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <th>Acciones:</th>
                                                                <td>
                                                                  <button
                                                                    onClick={() =>
                                                                      editarDefinicionDeNota(
                                                                        definiNota
                                                                      )
                                                                    }
                                                                    className="btn-edit btn me-2 btn-sm"
                                                                    title="Editar"
                                                                  >
                                                                    <i className="icon-edit fas fa-pencil-alt"></i>
                                                                  </button>
                                                                  <button
                                                                    onClick={() =>
                                                                      deleteDefinicionDeNota(
                                                                        definiNota
                                                                      )
                                                                    } // Cambié deletePeriodo a deleteCurso
                                                                    className="btn-deactivate btn  me-2 btn-sm"
                                                                    title="Eliminar"
                                                                  >
                                                                    <i className="icon-deactivate fas fa-times"></i>
                                                                  </button>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                          <br />
                                                        </>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      )}

                                      {paramPlanilla.evaluationTypeId === 1 && (
                                        <div className="row">
                                          <hr />
                                          <div
                                            class="alert alert-info alert-dismissible"
                                            role="alert"
                                          >
                                            <div class="alert-message">
                                              <h4>Logros </h4>
                                            </div>
                                          </div>
                                          {procesos.map((proceso, index) => (
                                            <>
                                              <div className="col-md-12 scroll-table">
                                                <div class="card definicio definicion-notas-container">
                                                  <div class="card-header definicion-notas-header">
                                                    <h5 class="card-title mb-0 definicion-notas-title">
                                                      Proceso: {proceso.description}
                                                    </h5>
                                                  </div>
                                                  <div class="card-body definicion-notas-body scroll-table">
                                                    <table class="table table-sm my-2 definicion-notas-tabla">
                                                      <tbody>
                                                        {proceso.logros.map(
                                                          (logro, indexNota) => (
                                                            <>
                                                              <tr>
                                                                <th>Logro {indexNota + 1}:</th>
                                                                <td>{logro.description}</td>
                                                              </tr>
                                                            </>
                                                          )
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      )}

                                      <MDBModal
                                        tabIndex="-1"
                                        show={fullscreenXlModal}
                                        setShow={setFullscreenXlModal}
                                      >
                                        <MDBModalDialog className="modal-xl-custom">
                                          <MDBModalContent>
                                            <MDBModalHeader>
                                              <MDBModalTitle>
                                                Definiciones de nota
                                              </MDBModalTitle>
                                              <MDBBtn
                                                type="button"
                                                className="btn-close"
                                                color="none"
                                                onClick={closeModal}
                                              ></MDBBtn>
                                            </MDBModalHeader>
                                            <MDBModalBody>
                                              <div className="card">
                                                {selectedProcess && fullscreenXlModal && (
                                                  <FormDefinicionNotas
                                                    key={Date.now()}
                                                    definitionNote={selectedDefNota}
                                                    evaluationProcessId={
                                                      selectedProcess.evaluationProcessId
                                                    }
                                                    paramToSearch={{
                                                      courseId: paramPlanilla.courseId,
                                                      schoolSubjectId:
                                                        paramPlanilla.schoolSubjectId,
                                                      schoolYearId: paramPlanilla.schoolYearId,
                                                      periodId: paramPlanilla.periodId,
                                                      evaluationTypeId:
                                                        paramPlanilla.evaluationTypeId,
                                                      isRecuperation:paramPlanilla.isRecuperation
                                                    }}
                                                    hideBtnInscription={true}
                                                    onClose={() => {
                                                      setFullscreenXlModal(false);
                                                      cargarPlanilla();
                                                    }}
                                                  />
                                                )}

                                                {!selectedProcess && fullscreenXlModal && (
                                                  <FormDefinicionNotas
                                                    key={Date.now()}
                                                    definitionNote={selectedDefNota}
                                                    evaluationProcessId={0}
                                                    paramToSearch={{
                                                      courseId: paramPlanilla.courseId,
                                                      schoolSubjectId:
                                                        paramPlanilla.schoolSubjectId,
                                                      schoolYearId: paramPlanilla.schoolYearId,
                                                      periodId: paramPlanilla.periodId,
                                                      evaluationTypeId:
                                                        paramPlanilla.evaluationTypeId,
                                                        isRecuperation:paramPlanilla.isRecuperation
                                                    }}
                                                    hideBtnInscription={true}
                                                    onClose={() => {
                                                      setFullscreenXlModal(false);
                                                      cargarPlanilla();
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            </MDBModalBody>
                                            <MDBModalFooter>
                                              <button
                                                type="button"
                                                className="btn-footer-close btn-primary"
                                                onClick={closeModal}
                                              >
                                                Cerrar
                                              </button>
                                            </MDBModalFooter>
                                          </MDBModalContent>
                                        </MDBModalDialog>
                                      </MDBModal>

                                      <Tooltip id="my-tooltip" />
                                    </div>
                    </div>
                  )}

                    {Number(paramPlanilla.schoolSubjectId) === -1 && (
                    <div className={`tab-pane ${activeTab === 'observaciones' ? 'show active' : 'fade'}`} id="observaciones" role="tabpanel">
                    
                    <div class="p-3 custom-table div-table-notas scroll-table">
                    <h1 className=" my-4">Observaciones</h1>
                    <p>Añade las observaciones necesarias para cada estudiante.</p>
                    <div class="p-3 table-responsive">
                                    <table className="bordered">
                                      <thead>
                                        <tr>
                                          <th class=""
                                          style={{
                                            maxWidth: "250px",
                                            minWidth: "250px",
                                          }}>Estudiante</th>
                                          <th style={{
                                            maxWidth: "500px",
                                            minWidth: "500px",
                                          }}>Observaciones</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {estudiantes?.map((estudiante, index) => (
                                          <tr key={index}>
                                            <td class=""  style={{
                                            
                                          }} >{estudiante.fullName}</td>
                                            <td>

                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Escriba una observación..."
                                                style={{
                                                  height: "25px",
                                                  width: "100%",
                                                  textAlign:"start"
                                                }}
                                                id={"obs" + index}
                                                name={"obs" + index}
                                                data-change="false"
                                                value={estudiante.observaciones.observations}
                                                onKeyPress={(e) => {
                                                  toNextNote(e);
                                                }}
                                                onBlur={(e) =>
                                                  handleSaveObservation(
                                                    estudiante.observaciones,
                                                    e.target
                                                  )
                                                }
                                                onChange={(e) => handleObservacionChange2(estudiantes, estudiante, e.target.value,e.target)}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                    </div>
                    </div>
                    )}
                  </div>
                </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NotasEstudiantes;
