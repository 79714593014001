import React, { useEffect, useState } from "react";
import "../css/StudentForm.css";
import StudentForm from "../components/Forms/StudentForm";
import utils from "../utils/generalFunctions";
import estudiantesService from '../services/estudiantesService';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";

function Inscriptions() {
  const [selectedStudent, setSelectedStudent] = useState({
    registrationId: 0,
    profilePicture: null,
    aspirantId: "",
    currentYear: "2023",
    journeyId: "",
    grade: "",
    firstName: "",
    secondName: "",
    firstSurname: "",
    secondSurname: "",
    identificationType: "",
    placeOfIssue: "",
    birthDate: "",
    gender: "",
    phone: "",
    cellPhone: "",
    address: "",
    disability: "",
    email: "",
    institution: "",
    city: "",
    department: "",
    fatherReferenceId: 0,
    fatherId: "",
    fatherPlaceOfIssue: "",
    fatherIdType: "",
    fatherAcademicLevel: "",
    fatherFirstName: "",
    fatherSecondName: "",
    fatherFirstSurname: "",
    fatherSecondSurname: "",
    fatherBirthDate: "",
    fatherCellphone: "",
    fatherPhone: "",
    fatherAddress: "",
    fatherEmail: "",
    fatherProfession: "",
    fatherWorkplace: "",
    fatherJobType: "",
    fatherPosition: "",
    fatherIsParentMain: true,
    motherReferenceId: 0,
    motherId: "",
    motherPlaceOfIssue: "",
    motherIdType: "",
    motherAcademicLevel: "",
    motherFirstName: "",
    motherSecondName: "",
    motherFirstSurname: "",
    motherSecondSurname: "",
    motherBirthDate: "",
    motherCellPhone: "",
    motherPhone: "",
    motherAddress: "",
    motherEmail: "",
    motherProfession: "",
    motherWorkplace: "",
    motherJobType: "",
    motherPosition: "",
    motherIsParentMain: true,
    studentLivingWith: "",
    enrollmentReason: "",
    recommendation: "",
  });
  const [showExportButton, setShowExportButton] = useState(false);

  const exportNewUsers = async (token) => {
    const estudents = await estudiantesService.getEstudiantesNuevos();
    exportToCSV(estudents, 'nuevos-estudiantes');
  };

  const exportToCSV = (excelData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const dataMapped = excelData.map(item => ({
      "Nombre estudiante": item.fullName??'',
      "Grado": item.grade??'',
      "Email": item.email??'',
      "Telefono": item.cellPhone??'',
      "Nombre acudiente": item.parentName??'',
      "Telefono acudiente": item.parentCellPhone??'',
      "Email acudiente": item.parentEmail??'',
      "Fecha diligenciado": item.createdDate??''
    }));
    
    if(dataMapped.length>0)
    {
      const ws = XLSX.utils.json_to_sheet(dataMapped);
      const header = ["A1", "B1","C1","D1","E1", "F1","G1","H1"]; // Debes ajustar esto a la cantidad de columnas que tienes
        header.forEach(cell => {
          if(ws[cell])
          ws[cell].s = {
            font: {
              bold: true
            }
          };
        });
      
        // Calcular el ancho máximo de cada columna (esto es un ejemplo simple)
        const colWidths = header.map(col => {
          return Math.max(
            ...dataMapped.map(row => row[ws[col].v.toString()].toString().length),
            ws[col].v.toString().length
          );
        });

        // Establecer el ancho de las columnas
        ws['!cols'] = colWidths.map(width => ({ wch: width }));
        // Agregar AutoFilter
        ws['!autofilter'] = { ref: "A1:H1" + (dataMapped.length + 1) };
          
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }else{
      toast.info("No se encontraron datos para exportar.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
    }
  }

  useEffect(() => {


    const expandView = () =>{
        const sidebar = document.getElementById("sidebar");
        if(sidebar) sidebar.style.display = "none";
        const lateralMenu = document.getElementById("lateralMenu");
        if(lateralMenu) lateralMenu.style.display = "none";
        const mainContainer = document.getElementById("mainContainer");
        if(mainContainer) mainContainer.style.padding = "0px";
        const user = utils.getUserInformation();
        if(user && user.userTypeId===1){
            setShowExportButton(true);
        }
    }
    expandView();
  }, []);

  return (
    <div className="container-fluid inscription-page-inscripciones">
      <div className="row text-white-form  header-form-row">
        <div className="col-md-2 header-form-logo">
          <img
            src="https://colegiojerusalem.edu.co/wp-content/uploads/2021/09/Logo-Colegio-980x963.png"
            alt="Logo del Colegio"
            className="img-fluid"
          />
        </div>
        <div className="col-md-10">
          <h1 className="header-form-title">
            Bienvenidos al Colegio Jerusalem
          </h1>
          <p className="header-form-subtitle">Educamos para la eternidad.</p>
          {showExportButton && (
          <button className="btn-matricula margin-right-20-percent" onClick={() => {exportNewUsers(); }}>
                  Exportar nuevos estudiantes
          </button>
          )}
        </div>
      </div>

        <div className='card containerForm-inscripciones'>
            <StudentForm
                        student={0}
                        hideBtnInscription={false}
                        ReadOnly={false}
                        onClose={() => {
                            
                        }
                    }
                    />
        </div>
        
    </div>
  );
}

export default Inscriptions;
