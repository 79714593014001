import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';

const EvaluacionesDetalle = ({ selectedSubject, notas }) => {
  console.log(selectedSubject);
  console.log(notas);

  return (
    <div className="col-md-12">
      <div id="detalles-asignatura-container">
        <div className="card">
          <div className="card-header" id="cabecera-detalles">
            <h2 className="card-title mb-3" id="titulo-notasPanel">DETALLES DE {selectedSubject.schoolSubjectName}</h2>            
          </div>
          {notas.processNotes.map((proceso, indexProceso) => (
            <div className="card-body proceso-detalle" key={indexProceso} id={`detalle-proceso-${indexProceso}`}>
              <h4 className="card-subtitle text mb-4 proceso-titulo" id={`titulo-proceso-${indexProceso}`}>
                {proceso.evaluationProcessName}
              </h4>
                  <Table className="dataTables_wrapper dt-bootstrap5 table-notas" 
                  id={`tabla-evaluaciones-${indexProceso}`}>
                    <thead>
                      <tr>
                        <th className="descripcion-column" id={`th-descripcion-${indexProceso}`}>Descripción</th>
                        <th className="nota-column" id={`th-nota-${indexProceso}`}>Nota</th>
                      </tr>
                    </thead>
                <tbody>
                  {proceso.achievementEvaluations.map((logro, indexlogro) => (
                    <tr key={indexlogro} className="fila-evaluacion" id={`fila-evaluacion-${indexProceso}-${indexlogro}`}>
                      <td className="descripcion-evaluacion" id={`td-descripcion-${indexProceso}-${indexlogro}`}>
                        {logro.achievementEvaluationName}
                        <br />
                      </td>
                      <td className="nota-evaluacion" id={`td-nota-${indexProceso}-${indexlogro}`}>
                        <span>{logro.evaluativeScaleNameAbr +" ("+logro.evaluativeScaleName+" )" || " - " }</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ))}          
        </div>
      </div>
    </div>
  );
};

export default EvaluacionesDetalle;
