import api from './api'; // Asumiendo que tienes un módulo de configuración de API

const getAllBillings = async () => {
    try {
        const response = await api.get('/billing/list');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getBillingDetailsById = async (billingId) => {
    try {
        const response = await api.get(`billing/${billingId}/details`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getBillingStatus = async () => {
    try {
        const response = await api.get('/billing/status');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getBillingObligationStatus = async (studentId, onlyDeuda) => {
    try {
        const response = await api.get(`/billing/obligation-status?studentId=${studentId}&onlyDeudas=${onlyDeuda}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getBillingById = async (billingId) => {
    try {
        const response = await api.get(`/billings/${billingId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const addBilling = async (billing) => {
    try {
        const response = await api.post('/billing', billing);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const addBillingDetails = async (billingDetails) => {
    try {
        const response = await api.post('/billing/details', billingDetails);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateBilling = async (billing) => {
    try {
        const response = await api.put(`/billing`, billing);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteBilling = async (billingId) => {
    try {
        const response = await api.delete(`/billing/${billingId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstadoDeCuentas = async (studentId,journeyId, schoolYearId, educationLevelId, courseId) => {
    try {
        const response = await api.get(`/billing/account-status?studentId=${studentId}&journeyId=${journeyId}&schoolYearId=${schoolYearId}&educationLevelId=${educationLevelId}&courseId=${courseId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const generarIntereses = async () => {
    try {
        const response = await api.post(`/billing/generate-billing-delays`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getBillingReport = async (schoolYearId,paymentTypeId, startDate, endDate, billinConceptId) => {
    try {
        const response = await api.get(`/billing/report?schoolYearId=${schoolYearId}&paymentTypeId=${paymentTypeId}&startDate=${startDate}&endDate=${endDate}&billinConceptId=${billinConceptId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
//


export default {
    getAllBillings,
    getBillingDetailsById,
    getBillingStatus,
    getBillingById,
    addBilling,
    generarIntereses,
    addBillingDetails,
    updateBilling,
    deleteBilling,
    getEstadoDeCuentas,
    getBillingObligationStatus,
    getBillingReport
};
