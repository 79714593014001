import api from './api';

const getEvaluativeScale = async (evaluationTypeId) => {
    try {
        const response = await api.get(`academic/evaluative-scale/${evaluationTypeId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getEvaluativeScale
};