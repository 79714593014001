import React, { useState, useEffect } from 'react';
import StudentForm from '../../components/Forms/StudentForm';
import { Link, useParams } from 'react-router-dom';
import "../../css/estudiantes.css";
import DashboardCard from '../../components/TituloDashboard.js'; // Asegúrate de que la ruta sea correcta
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import studentPhoto from '../../img/user-profile.png';
import util from '../../utils/generalFunctions.js'




const PerfilEstudiante = () => {
  const { id } = useParams(); // Obtenemos el ID del estudiante de la URL
  const [studentData, setStudentData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const studentInfo = util.getStudentInformation();

  return (
    <div className=' p-3'>
       <Link to="/dashboard" className="back-to-dashboard-pc">
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link> 
      <DashboardCard
      studentPhoto={studentPhoto} // Usar la variable importada aquí
          title="Hoja de Vida"
          subtitle="Detalles de tu Inscripción"
        />
      <notasDelEstudiantePC/>
      <StudentForm student={studentInfo.identification} ReadOnly={true} hideBtnInscription={true} />


    </div>
  );
};

export default PerfilEstudiante;
