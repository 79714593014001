import api from './api'; // Asumiendo que tienes un módulo de configuración de API

const getAllAcademicAreas = async () => {
    try {
        const response = await api.get('/academic/area');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAcademicAreaById = async (academicAreaId) => {
    try {
        const response = await api.get(`/academic/area/${academicAreaId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const addAcademicArea = async (academicArea) => {
    try {
        const response = await api.post('/academic/area', academicArea);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateAcademicArea = async (academicArea) => {
    try {
        const response = await api.put(`/academic/area`, academicArea);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteAcademicArea = async (academicAreaId) => {
    try {
        const response = await api.delete(`/academic/area/${academicAreaId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getAllAcademicAreas,
    getAcademicAreaById,
    addAcademicArea,
    updateAcademicArea,
    deleteAcademicArea
};
