import React, { Component } from 'react';
import cssLoading from '../css/loading.css';

class Loading extends Component {

  
  render(){
  return (
    <div id="pnlLoading" Style="text-align: center;">
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
    );
  }
};

export default Loading;
