import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import estudiantesService from "../services/estudiantesService";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import StudentForm from '../components/Forms/StudentForm';
import Loading from '../components/Loading';
import utils from "../utils/generalFunctions";
import useCustomDataTableStyles from "../components/estilosDeDataTable";
import userService from "../services/userService";
import UsuarioForm from "../components/Forms/FormUsuario";
import Swal from "sweetalert2";



const Usuarios = () => {
  
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');
    const [showEditButton, setShowEditButton] = useState(false);

    const toggleShow = () => {
        setFullscreenXlModal(!fullscreenXlModal);
        setSelectedUser(null); 
    }

    const openMaximizeModal = (user) => {
        setSelectedUser(user);
        setFullscreenXlModal(true);
        setBtnVer(true);
    };

    const openEditingModal = async (user) => {
        setSelectedUser(user);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };
    
    const openAddingModal = async (user) => {
        setSelectedUser(user);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };

    const fetchUsuarios = async () => {
        try {
            validarPermisos();
            const response = await userService.getAllUsers();
            setUsers(response);
        } catch (error) {
            console.error("Error al obtener los usuarios:", error);
        }
    };

    const validarPermisos = ()=> {
      const user = utils.getUserInformation();
      if(user && user.userTypeId<=4){
          setShowEditButton(true);
      }

    }

    useEffect(() => {
        fetchUsuarios();
    }, []);

  const columns = [
    { name: "ID", selector: "identification", sortable:true,grow:"2.5",},
    { name: "Nombre", selector: "userName", sortable:true,grow:"6"},
    { name: "Tipo", selector: "userTypeName", sortable:true,grow:"2.5", },
    { name: "Email", selector: "email", sortable:true,grow:"3", },
    { name: "Estado", selector: "isActive", sortable:true,grow:"3",
        cell: (row) => (row.isActive ? <span>Activo</span> : <span>Inactivo</span>),
    },
    {
      name: "Acciones",grow:"3",
      selector: "actions",
      cell: (row) => (
        <div className="actions-container justify-content-between align-items-center">
          <button
            onClick={() => openMaximizeModal(row)}
            className="btn-maximize btn-sm me-2"
            title="Maximizar información"
          >
            <i className="fas fa-eye"></i>
          </button>
          {showEditButton && (
            <button
              onClick={() => openEditingModal(row)}
              className="btn-edit btn-sm me-2"
              title="Editar"
            >
              <i className="icon-edit fas fa-pencil-alt"></i>
            </button>
          )}
          {showEditButton && (
            <button
              onClick={() => deleteUser(row)}
              className="btn-deactivate btn-sm"
              title="Eliminar"
            >
              <i className="icon-deactivate fas fa-times"></i>
            </button>
          )}
        </div>
      ),
    },
  ];

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredItems = users?.filter((item) => {
    if (item) {
      const allData = `${item.userName} ${item.email} ${item.institutionalEmail} ${item.userTypeName} ${item.identification} ${item.isActive}`;
      const normalizedFilterText = normalizeString(filterText);
      const normalizedItemName = normalizeString(allData);
      return normalizedItemName.includes(normalizedFilterText);
    }
    return item;
  });

  const customStyles = useCustomDataTableStyles();

  const paginationPerPage = 50; // Ajusta este número según tus necesidades

  const actionsMemo = React.useMemo(() =>
  <button className="btn-matricula margin-right-20-percent" onClick={() => { exportUsuarios(); }}>
    Exportar usuarios
  </button>, []);

const exportUsuarios = async () => {
  const response = await userService.getAllUsers();
  const dataMapped = response.map(item => ({
    "Identificación": item.identification??'',
    "Nombre": item.userName??'',
    "Tipo de usuario":item.userTypeName??'',
    "Email":item.email??'',
    "Email institucional":item.institutionalEmail??'',
    "Estado": item.isActive?'Activo':'Inactivo'
  }));

  utils.exportDataToExcel(dataMapped, 'Lista de usuarios');
};


const deleteUser = async(user)=>{
  Swal.fire({
      title: 'Esta seguro que desea eliminar este usuario?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
        await userService.deleteUserById(user.userId);
        Swal.fire(
          'Felicidades!',
          'Usuario eliminado exitosamente!',
          'success'
        );
        fetchUsuarios();
        }catch(error){
          console.log(error);
          Swal.fire(
              "Ha ocurrido un error",
              "Por favor intentelo mas tarde",
              "error"
          );
        }
      }
    })
}

  return (
    <div className="student-table-container p-3">
      <h1>Administración de usuarios</h1>
      <p>Esta es la vista de los usuarios de todo el plantel educativo.</p>

            <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
                <input
                    type="text"
                    placeholder="Buscar usuario"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    className="form-control form-control-sm w-50"
                />
                {showEditButton && (
                <div className="btn-group">
                    <button className="btn-sm btn-Estudiante btn-NuevoEstudiante"  onClick={() => openAddingModal(null)} ><i className="fa-solid fa-circle-plus"></i> Nuevo Usuario</button>
                </div>
                )}
            </div>
            <DataTable
                title="Lista de usuarios"
                className="students-datatable shadow-sm rounded"
                columns={columns}
                actions={actionsMemo}
                noDataComponent="No hay información que mostrar, por favor añadela"   
                data={filteredItems}
                pagination
                fixedHeader
                customStyles={customStyles}
                paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                paginationRowsPerPageOptions={[50, 100]}

            />

             {/* Renderiza el modal y el formulario aquí */}
            <>
                <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                <MDBModalDialog className="modal-xl-custom">
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>DETALLES DEL USUARIO</MDBModalTitle>
                        <MDBBtn
                        type='button'
                        className='btn-close'
                        color='none'
                        onClick={toggleShow}
                        ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div className='card'>
                            {selectedUser && (
                                <UsuarioForm
                                    key={Date.now()}
                                    user={selectedUser}
                                    onClose={() =>{
                                         setFullscreenXlModal(false); 
                                         fetchUsuarios();
                                    }}
                                    ReadOnly={BtnVer}
                                />
                            )}

                            { !selectedUser && (
                                <UsuarioForm
                                    key={Date.now()}
                                    user={null}
                                    onClose={() =>{
                                         setFullscreenXlModal(false); 
                                         fetchUsuarios();
                                    }}
                                    ReadOnly={false}
                                />
                            )}
                            </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                    <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                        Cerrar
                    </button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
                </MDBModal>
            </>
            

        </div>
    );
};

export default Usuarios;
