import api from './api';

const getPreventivoNotasAcademicos = async () => {
    try {
        const response = await api.get(`academic/preventive-note`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getPreventivoNotasCourse = async (courseId, schoolSubjectId, schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`academic/preventive-note-course?courseId=${courseId}&schoolSubjectId=${schoolSubjectId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getPreventivoNotasCourseReport = async (studentId, courseId, schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`academic/preventive-report?studentId=${studentId}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createPreventivoNotaAcademico = async (periodoData) => {
    try {
        const response = await api.post(`academic/preventive-note`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updatePreventivoNotaAcademico = async (periodoData) => {
    try {
        const response = await api.put(`academic/preventive-note`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getPreventivoNotaAcademico = async (id) => {
    try {
        const response = await api.get(`academic/preventive-note/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deletePreventivoNotasAcademicoById = async (id) => {
    try {
        const response = await api.delete(`academic/preventive-note/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getPreventivoNotasAcademicos,
    createPreventivoNotaAcademico,
    updatePreventivoNotaAcademico,
    getPreventivoNotaAcademico,
    deletePreventivoNotasAcademicoById,
    getPreventivoNotasCourse,
    getPreventivoNotasCourseReport
};