import api from './api';

const getPeriodosAcademicos = async () => {
    try {
        const response = await api.get(`/academic/period`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createPeriodoAcademico = async (periodoData) => {
    try {
        const response = await api.post(`/academic/period`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updatePeriodoAcademico = async (periodoData) => {
    try {
        const response = await api.put(`/academic/period`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getPeriodoAcademico = async (id) => {
    try {
        const response = await api.get(`/academic/period/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deletePeriodoAcademicoById = async (id) => {
    try {
        const response = await api.delete(`/academic/period/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getPeriodosAcademicos,
    createPeriodoAcademico,
    getPeriodoAcademico,
    updatePeriodoAcademico,
    deletePeriodoAcademicoById
};