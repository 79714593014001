import api from './api';

const getProcesosAcademicos = async () => {
    try {
        const response = await api.get(`academic/process`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getProcesosToNotas = async (courseId, schoolSubjectId, schoolYearId, academicPeriodId, evaluationTypeId) => {
    try {
        const response = await api.get(`academic/process-to-notes?courseId=${courseId}&schoolSubjectId=${schoolSubjectId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}&evaluationTypeId=${evaluationTypeId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getProcesosToLogros = async (courseId, schoolSubjectId, schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`academic/process-to-achievement?gradeId=${courseId}&schoolSubjectId=${schoolSubjectId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createProcesoAcademico = async (periodoData) => {
    try {
        const response = await api.post(`academic/process`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateProcesoAcademico = async (periodoData) => {
    try {
        const response = await api.put(`academic/process`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getProcesoAcademico = async (id) => {
    try {
        const response = await api.get(`academic/process/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteProcesosAcademicoById = async (id) => {
    try {
        const response = await api.delete(`academic/process/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getProcesosAcademicos,
    createProcesoAcademico,
    updateProcesoAcademico,
    getProcesoAcademico,
    deleteProcesosAcademicoById,
    getProcesosToNotas,
    getProcesosToLogros
};