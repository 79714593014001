import React, { useState, useEffect } from "react";
import tipoIncidenciaService from "../../services/tipoIncidenciaService";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import Loading from "../Loading";

const CategoriaForm = ({ categoria, onClose }) => {
    const [formData, setFormData] = useState({
        inicidenceTypeId: categoria ? categoria.inicidenceTypeId : 0,
        description: categoria ? categoria.description : "",
    });
    const [showLoading, setShowLoading] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
         // Validación básica
         const requiredFields = ['description'];
         for (let field of requiredFields) {
             if (!formData[field]) {
                 toast.error("Por favor, completa todos los campos obligatorios.", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                   });
                 return;
             }
         }
        // Aquí implementarías la lógica para guardar o actualizar la categoría
        setShowLoading(true); 
        try{
 
            let response = null;
            if(formData.inicidenceTypeId>0){
                response = await tipoIncidenciaService.updateIncidenciaTipo(formData);
            }else{
                response = await tipoIncidenciaService.createIncidenciaTipo(formData);
            }

            if(response && response.inicidenceTypeId>0){
                    Swal.fire(
                        'Felicidades!',
                        'Categoria guardada exitosamente!',
                        'success'
                    );
                    if (onClose) onClose(); // Cerrar el modal después de enviar el formulario
            }else{
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intente más tarde",
                    "error"
                );
            }
         }catch(ex){
             console.log(ex);
              Swal.fire(
                         "Ha ocurrido un error",
                         "Por favor intente más tarde",
                         "error"
                     );
         }
         setShowLoading(false);
    };

    return (
        <>
            {showLoading && <Loading />}

            {!showLoading && (
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Nombre de la Categoría:*</label>
                    <input
                        type="text"
                        className="form-control"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">
                    {categoria ? "Actualizar" : "Registrar"} Categoría
                </button>
            </form>
            )}
        </>
    );
};

export default CategoriaForm;
