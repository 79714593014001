import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import Loading from '../components/Loading';
import CourseForm from "../components/Forms/FormCursos";  // Asegúrate de crear este componente basado en el formulario anterior
import courseService from "../services/courseService";  // Asegúrate de crear este servicio
import Swal from "sweetalert2";
import useCustomDataTableStyles from "../components/estilosDeDataTable";

const Courses = () => {

    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
const [cursos, setCursos] = useState([]);
const [selectedCurso, setSelectedCurso] = useState(null);
const [filterText, setFilterText] = useState('');
const [BtnVer, setBtnVer] = useState('');

const toggleShow = () => {
    setFullscreenXlModal(!fullscreenXlModal);
    setSelectedCurso(null); 
}

const openMaximizeModal = (curso) => {
    setSelectedCurso(curso);
    setFullscreenXlModal(true);
    setBtnVer(true);
};

const openEditingModal = async (curso) => {
    setSelectedCurso(curso);
    setFullscreenXlModal(true);
    setBtnVer(false);
};

const openAddingModal = async (curso) => {
    setSelectedCurso(curso);
    setFullscreenXlModal(true);
    setBtnVer(false);
};

const deleteCurso = async(curso)=>{
  Swal.fire({
      title: 'Esta seguro que desea eliminar este curso?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
        await courseService.deleteCursoById(curso.courseId);  // Asegúrate de tener un método deleteCursoById en cursoService
        Swal.fire(
          'Felicidades!',
          'Curso eliminado exitosamente!',
          'success'
        );
        fetchCourses();
        }catch(error){
          Swal.fire(
              "Ha ocurrido un error",
              "Por favor intentelo mas tarde",
              "error"
          );
        }
      }
    })
}

    const fetchCourses = async () => {
        try {
            const response= await courseService.getCursos();  // Asegúrate de tener un método getCursos en cursoService
            setCursos(response);
        } catch (error) {
            console.error("Error al obtener los cursos:", error);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);


    const columns = [
        { 
            name: "Número", 
            selector: "number", 
            sortable: true, 
            cell: (row, index) => <span>{index + 1}</span>, 
        },
        { 
            name: "Nombre", 
            selector: "name", 
            sortable: true 
        },
        { 
            name: "Grado", 
            selector: "gradeName", 
            sortable: true 
        },
        { name: "Activo", selector: "isActive", sortable: true,
        cell: (row) => (
          <>
           {row.isActive ? <span>Si</span> : <span>No</span>}
          </>
        ), },             // Si el período académico está activo o no
        {
          name: "Acciones",grow:"2.5",
          selector: "actions",
          cell: (row) => (
            <div className="actions-container justify-content-between align-items-center">
              <button
                onClick={() => openMaximizeModal(row)}
                className="btn-maximize btn-sm me-2"
                title="Maximizar información"
              >
                <i className="fas fa-eye"></i>
              </button>
              <button
                    onClick={() => openEditingModal(row)}
                    className="btn-edit btn-sm me-2"
                    title="Editar"
                >
                    <i className="icon-edit fas fa-pencil-alt"></i>
                </button>
                <button
                    onClick={() => deleteCurso(row)}  // Cambié deletePeriodo a deleteCurso
                    className="btn-deactivate btn-sm"
                    title="Eliminar"
                >
                    <i className="icon-deactivate fas fa-times"></i>
                </button>
            </div>
        ),
    },
];
const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

const filteredItems = cursos?.filter((item) => {  // Cambié periodos a cursos
    if (item) {
        const allData = `${item.number} ${item.gradeName} ${item.name} ${item.isActive}`;
        const normalizedFilterText = normalizeString(filterText);
        const normalizedItemName = normalizeString(allData);
        return normalizedItemName.includes(normalizedFilterText);
    }
    return item;
});

const customStyles = useCustomDataTableStyles();


  const paginationPerPage = 50; // Ajusta este número según tus necesidades


    return (
        <div className="student-table-container p-3">
            <h1>Cursos</h1>
            <p>Esta es la vista de los cursos.</p>

            <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
                <input
                    type="text"
                    placeholder="Buscar"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    className="form-control form-control-sm w-50"
                />
                <div className="btn-group">
                    <button className="btn-sm btn-Estudiante btn-NuevoEstudiante"  onClick={() => openAddingModal(null)} >Crear Curso <i className="fa-solid fa-circle-plus"></i></button>

                </div>
            </div>
            <DataTable
                title="Lista de cursos"
                className="students-datatable shadow-sm rounded"
                noDataComponent="No hay información que mostrar, por favor añadela"   
                columns={columns}
                data={filteredItems}
                pagination
                fixedHeader
                customStyles={customStyles}
                paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                paginationRowsPerPageOptions={[50, 100]}

            />

            <>
                <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                    <MDBModalDialog className="modal-xl-custom">
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>DETALLES DEL CURSO</MDBModalTitle>
                                <MDBBtn
                                    type='button'
                                    className='btn-close'
                                    color='none'
                                    onClick={toggleShow}
                                ></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <div className='card'>
                                    {selectedCurso && (
                                        <CourseForm
                                            key={Date.now()}
                                            course={selectedCurso.courseId}
                                            onClose={() => {
                                                setFullscreenXlModal(false);
                                                fetchCourses();
                                            }}
                                            ReadOnly={BtnVer}
                                        />
                                    )}

                                    {!selectedCurso && (
                                        <CourseForm
                                            key={Date.now()}
                                            course={0}
                                            onClose={() => {
                                                setFullscreenXlModal(false);
                                                fetchCourses();
                                            }}
                                            ReadOnly={false}
                                        />
                                    )}
                                </div>
                            </MDBModalBody>
                            <MDBModalFooter>
                                <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                    Cerrar
                                </button>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </>

        </div>
    );
};

export default Courses;
