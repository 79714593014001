import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import utils from '../../utils/generalFunctions';
import academicService from "../../services/academicService";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading";
import JornadaAnioPeriodoNivelCurso from "../../components/Filters/JornadaAnioPeriodoNivelCurso";
import estudiantesService from "../../services/estudiantesService";
import DataTable from "react-data-table-component";
import useCustomDataTableStyles from "../../components/estilosDeDataTable";
import collectionsService from "../../services/collectionsService";

const ReporteNotasCurso = () => {
    const filteReference = useRef(null);
    const initData ={
        schoolYearId: "0",
        journeyId:"0",
        periodId:"",
        educationLevelId: "0",
    };
    const [formData, setFormData] = useState(initData);
    const [periodos, setPeriodosList] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [estudiantes, setEstudiantes] = useState([]);    
    const [asignaturas, setAsignaturas] = useState([]);   
    const [filterText, setFilterText] = useState('');
    const [boletinInfo, setBoletinInfo] = useState(null);
    const [periodoSelected, setPeriodoSelected] = useState(null);
    const [evaluationScale, setEvaluationScale] = useState([]);
    const [decimalesPlanilla, setDecimalesPlanilla] = useState(0);
    
    
    const updateForm = async (data) => {
        if (data.schoolYearId!==formData.schoolYearId){   
          await cargarPeriodos(data.schoolYearId);
        }
        setFormData(prevState => ({
            ...prevState,
            ...data
          }));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const cargarPeriodos = async (schoolYearId) => {
        try {
            const schoolYear = await academicService.getYearById(schoolYearId);              
            if(schoolYear){
                setPeriodosList(schoolYear.periods)
            }            
        } catch (error) {
             console.error("Error al obtener información de año escolar:", error);
        }
    }

    const generarCosolidadReporteTotal = async () =>{
      setBoletinInfo(null);
        const requiredFields = ['schoolYearId', 'journeyId', 'periodId','educationLevelId','courseId'];
        for (let field of requiredFields) {
            if (!formData[field] || formData[field]==='0') {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }
        setShowLoading(true);
        try {
           const copyData = utils.copy(formData);
           copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===String(copyData.periodId))?.periodName??"";
           const years = filteReference.current?.getSchoolYears();
           if(years){
                copyData.year = years.find(p=>String(p.schoolYearId)===String(copyData.schoolYearId))?.year??"";
           }
           const journeys = filteReference.current?.getJourneys();
           if(journeys){
                copyData.journey = journeys.find(p=>String(p.journeyId)===String(copyData.journeyId))?.name??"";
           }

           const courses = filteReference.current?.getCursos();
           if(courses){
                copyData.course = courses.find(p=>String(p.courseId)===copyData.courseId)?.name??"";
           }

           const educationLevels = filteReference?.current?.getEducationLeves();
           if (educationLevels.length > 0) {
                copyData.evaluationTypeId = educationLevels.find((c) => String(c.educationLevelId) === String(copyData.educationLevelId))?.evaluationTypeId;
           }

           const data = await estudiantesService.getEstudianteBoletinValorativo(
            "",
            copyData.courseId,
            copyData.schoolYearId,
            copyData.periodId,
            false
          ); // Obtenemos los estudiantes de tu sistema
          console.log(data);
          if(data && data.length>0){
            setEstudiantes(data[0].students);
            if(data[0].students.length>0){
              let asigs = [];
              const student = data[0].students[0];
                for(let area of student.areasNotes){
                  for(let subject of area.subjectNotes){
                      asigs.push(subject);
                  }  
                }  
              setAsignaturas(asigs);
            }
            setEvaluationScale(data[0].evaluationScale);
          }else{
            toast.error("No se encontró información para mostrar.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setEstudiantes([]); 
              setAsignaturas([]);
          }
          
        } catch (error) {
          console.error("Error al obtener asistencias:", error);
        }
        setShowLoading(false);
    }

    const fetchLoadGeneralParams = async () => {
        try {
          const parameters = await collectionsService.getParameters();
          const decimales = parameters.find(
            (op) => String(op.keyName) === "decimalesPlanilla"
          );
          if (decimales) {
            setDecimalesPlanilla(Number(decimales.value));
          }
        } catch (error) {
          console.error("Error al obtener parametros de formulario:", error);
        }
      };
    
      useEffect(() => {
        fetchLoadGeneralParams();
      }, []);

    const volverLista =  async () => {
        setBoletinInfo(null);
    };

    const generarBoletin =  async (student) => {
        setShowLoading(true);
        const copyData = utils.copy(formData);
        copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===String(copyData.periodId))?.periodName??"";
        setPeriodoSelected({periodId:formData.periodId, periodName:copyData.periodName});
        const data = await estudiantesService.getEstudianteBoletinIntermedio(
            student.studentId,
            formData.courseId,
            formData.schoolYearId,
            formData.periodId
          ); // Obtenemos los estudiantes de tu sistema
          console.log(data);
          setBoletinInfo(data);
          setShowLoading(false);
    };

    const getScaleFormat = (totalNota) => {
        if(totalNota){
        const total = utils.roundNumber(totalNota, decimalesPlanilla);
        const scale = evaluationScale.find(
          (s) => total >= s.minScore && total <= s.maxScore
        );
    
        if (scale) {
          return (
            <span style={{ color: scale.color }}>
              {total + " - " + scale.abbreviation}
            </span>
          );
        } else {
          return <span>{total}</span>;
        }
        }else{
            return <span></span>;
        }
      };

    const exportarExcel = (tablaId) =>{
        const copyData = utils.copy(formData);
        copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===String(copyData.periodId))?.periodName??"";
        const years = filteReference.current?.getSchoolYears();
        if(years){
            copyData.year = years.find(p=>String(p.schoolYearId)===String(copyData.schoolYearId))?.year??"";
        }
        const courses = filteReference.current?.getCursos();
        if(courses){
             copyData.course = courses.find(p=>String(p.courseId)===copyData.courseId)?.name??"";
        }
        const name ="Notas de "+(copyData.course)+" - "+copyData.periodName+"-"+copyData.year;
        utils.exportTableToExcelWithPoints(tablaId, name);
    }

    return ( 
        <>
          <div className="student-table-container p-3 filter-container">
            <h1>Reporte de notas por curso</h1>
            <p>Selecciona los filtros para generar el reporte.</p>
            
                <div className="row">
                    <JornadaAnioPeriodoNivelCurso
                                callback={updateForm}
                                ref={filteReference}
                                loadTutoria={true}
                                loadCoordinators={true}
                                hideAsignatura={true}
                                properties={
                                    {
                                        className:'col-md-2 mb-2',
                                        id:'formReport2',
                                        filtro:false
                                    }
                                }
                            />
                        <div className="col-md-2 mb-1 margin-top-45">
                                <Button onClick={generarCosolidadReporteTotal} className="ml-2">Buscar</Button>
                        </div>
                </div>
          </div>
          {showLoading && <Loading />}

          {!showLoading && estudiantes.length>0 && (
            <>
             <div className="student-table-container p-3 mt-3">
                <div>
                    <div class="card-actions float-end ">
                        <button className='btn btn-success' onClick={(e)=>{exportarExcel("tablaNotasCurso")}}>Exportar a Excel</button>
                    </div>
                </div>
               <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center margin-top-45">

                    <table id="tablaNotasCurso" className="table table-sm table-bordered tabla-curso-notas">
                        <thead>
                                <tr>
                                    <th className="text-table-one-line">Estudiante</th>
                                    {asignaturas?.map((s, index) => (
                                        <th className="text-table-one-line">{s.schoolSubjetName}</th>
                                    ))} 
                                    <th className="text-table-one-line">Definitva</th>
                                </tr>
                        </thead>
                        <tbody>
                    {estudiantes?.map((s, index) => (
                        <>
                            <tr>
                                <td className="text-table-one-line"> {s.studentFullName} </td>
                                {s.areasNotes?.map((a, index) => (
                                    <>
                                    {a.subjectNotes?.map((sb, index) => (
                                        <td className="text-center text-table-one-line">
                                            {getScaleFormat(sb.note)}</td>
                                    ))}
                                    </>
                                ))}
                                <td className="text-center text-table-one-line">{getScaleFormat(s.note)}</td>
                                </tr>
                        </>
                        ))}
                        </tbody>
                    </table>

               </div>
            </div>
            </>
          )}
        </>
    );
};
  
export default ReporteNotasCurso;