import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const exportPDF = async (printRef, fileName) => {
  const element = printRef.current;
  const canvas = await html2canvas(element);
  const data = canvas.toDataURL('image/png');

  const pdf = new jsPDF();
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
  pdf.save(`${fileName}.pdf`);
};

const exportBlobPDF = async (printRef, fileName) => {
  const element = printRef.current;
  const canvas = await html2canvas(element);
  const data = canvas.toDataURL('image/png');

  const pdf = new jsPDF();
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

  return pdf.output('blob');
  // Retornar el PDF como un blob
  // return new Promise((resolve, reject) => {
  //   pdf.output('blob', {filename: `${fileName}.pdf`}, (blob) => {
  //     if (blob) {
  //       resolve(blob);
  //     } else {
  //       reject(new Error('Error al crear el Blob PDF'));
  //     }
  //   });
  // });
};

export default {
  exportPDF,
  exportBlobPDF
};