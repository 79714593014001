import api from './api';

const getCollection = async (collectionName) => {
    try {
        const response = await api.get(`/collection?type=${collectionName}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getParameters = async () => {
    try {
        const response = await api.get(`/collection/items`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getSignaturesReports = async (reportTypeId) => {
    try {
        const response = await api.get(`/collection/signatures/reports/${reportTypeId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getCollection,
    getParameters,
    getSignaturesReports
};