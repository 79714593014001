// DescuentosEstudiantes.js
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter } from 'mdb-react-ui-kit';
import DescuentoForm from '../components/Forms/DescuentoForm';
import EstudianteFilter from '../components/Filters/EstudianteFilter';
import discountsService from '../services/discountsService';
import JornadaAnioNivelCurso from '../components/Filters/JornadaAnioNivelCurso';
import moment from 'moment'
import Loading from '../components/Loading';
import utils from '../utils/generalFunctions';
import useCustomDataTableStyles from '../components/estilosDeDataTable';
import Swal from "sweetalert2";


const DescuentosEstudiantes = () => {
    const [descuentos, setDescuentos] = useState([]); // Estado para almacenar los descuentos
    const [modalOpen, setModalOpen] = useState(false); // Estado para manejar la visibilidad del modal
    const [selectedDescuento, setSelectedDescuento] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [BtnVer, setBtnVer] = useState('');
    const [filters, setFilters] = useState({ // Estado para los filtros aplicados
        studentId: '0',
        journeyId: '0',
        schoolYearId: '0',
        educationLevelId: '0',
        courseId: '0',
    });

    const toggleModal = () => {
      setModalOpen(!modalOpen);
      setSelectedDescuento(null);
  };

  const openMaximizeModal = (billingConcept) => {
      setSelectedDescuento(billingConcept);
      setModalOpen(true);
      setBtnVer(true);
  };

  const openEditModal = (billingConcept) => {
      setSelectedDescuento(billingConcept);
      setModalOpen(true);
      setBtnVer(false);
  };

     // Función para abrir el modal de nuevo descuento
     const openNewDescuentoModal = () => {
        setShowSearch(true);
        setModalOpen(true);
    };

      // Función para cerrar el modal
      const closeModal = () => {
          setModalOpen(false);
      };

      const deleteDescuento = async(discount)=>{
        Swal.fire({
            title: 'Esta seguro que desea eliminar este descuento?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
              try{
              await discountsService.deleteBillingItem(discount.billingDiscountId);
              Swal.fire(
                'Felicidades!',
                'Descuento eliminado exitosamente!',
                'success'
              );
              await buscarDescuentos();
              }catch(error){
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intentelo mas tarde",
                    "error"
                );
              }
            }
          })
    }

       // Obtener los descuentos cuando los filtros cambian
      const fetchDescuentos = async () => {
        try {
            // Asumiendo que tu servicio acepta un objeto de filtros
            const response = await discountsService.getAllBillingDescuentos(filters.studentId,  filters.journeyId, filters.schoolYearId, filters.educationLevelId, filters.courseId);
            setDescuentos(response); // Actualiza el estado con los descuentos obtenidos
        } catch (error) {
            console.error("Error al obtener los descuentos:", error);
        }
      };

    const handleFilterChange = (newFilters) => {
        // Actualiza el estado de filters con los nuevos valores
        setFilters(prev => ({
            ...prev,
            ...newFilters
        }));
    };

    const columns = [
        { name: "Estudiante", selector: row => row.studentName, sortable: true },
        // { name: "Estudiante", selector: row => row.studentName, sortable: true , cell: (row) => (
        //   <div>
        //       <img src={row.studentImage}/> 
        //       <span>{row.studentName}</span>
        //   </div>
        // ),},
        { name: "Curso", selector: row => row.courseName, sortable: true },
        { name: "Concepto", selector: row => row.billingConceptName, sortable: true },
        { name: "Descuento", selector: row => row.discountValue, format: row => `${utils.formatNumber.new(row.discountValue, (row.discountTypeName!=="Porcentaje")?"$":"%")}`, sortable: true },
        { name: "Tipo", selector: row => row.discountTypeName, sortable: true },
        { name: "Año", selector: row => row.year,  sortable: true },
        // { name: "Vigencia", selector: row => row.startDate, cell: (row) => (
        //   <div>
        //       <span>{moment(row.startDate).format('DD-MM-YYYY')} - {moment(row.endDate).format('DD-MM-YYYY')}</span>
        //   </div>
        // ),  sortable: true },
        { name: "Inicio", selector: row => row.startDate, format: row => moment(row.startDate).format('DD-MM-YYYY'),  sortable: true },
        { name: "Final", selector: row => row.endDate,format: row => moment(row.endDate).format('DD-MM-YYYY'), sortable: true },
        {
          name: "Acciones",
          cell: (row) => (
                <div className="actions-container justify-content-between align-items-center">
                <button
                  onClick={() => openMaximizeModal(row)}
                  className="btn-maximize btn-sm me-2"
                  title="Maximizar información"
                >
                  <i className="fas fa-eye"></i>
                </button>
                <button
                  onClick={() => openEditModal(row)}
                  className="btn-edit btn-sm me-2"
                  title="Editar"
                >
                  <i className="icon-edit fas fa-pencil-alt"></i>
                </button>
                <button
                  onClick={() => deleteDescuento(row)}
                  className="btn-deactivate btn-sm"
                  title="Eliminar"
                >
                  <i className="icon-deactivate fas fa-times"></i>
                </button>
              </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
      }
      
            // ... otras columnas que necesites agregar ...
    ];

    const buscarDescuentos = async () => {
      setShowLoading(true);
      await fetchDescuentos();
      setShowLoading(false);
      setShowSearch(true);
    };
    const customStyles = useCustomDataTableStyles();

    const normalizeString = (str) => {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    };

    const filteredItems = descuentos?.filter((item) => {
      if (item) {
        const allData = `${item.fullName} ${item.identification} ${item.grade} ${item.email} ${item.cellPhone} ${item.parentName} ${item.parentCellPhone} ${item.parentEmail}`;
        const normalizedFilterText = normalizeString(filterText);
        const normalizedItemName = normalizeString(allData);
        return normalizedItemName.includes(normalizedFilterText);
      }
      return item;
    });

    return (
      <>
        <div className="student-table-container p-3">
        <h1>Gestión de Descuentos por Estudiantes</h1>
                <div className="row">
                  <EstudianteFilter 
                  callback={handleFilterChange} 
                  properties={
                      {
                          className:'col-md-3 mb-3',
                      }
                  }
                  />
                  <JornadaAnioNivelCurso
                          callback={handleFilterChange}
                          properties={
                            {
                                className:'col-md-2 mb-2',
                                id:'formTest',
                                filtro:true
                            }
                        }
                      />

                    <div className="col-md-1 mb-1 margin-top-45">
                      <Button onClick={buscarDescuentos} className="ml-2">Buscar</Button>
                    </div>
                </div>
        </div>
        

      
              <div className="student-table-container p-3 margin-top-10">
                    <div className="row">
                        <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
                          <div className="btn-group">
                              <button className="btn-sm btn-Estudiante btn-NuevoEstudiante"  onClick={openNewDescuentoModal} >Crear Descuento <i className="fa-solid fa-circle-plus"></i></button>
                          </div>
                      </div>

                        {showLoading && <Loading />}

                        {showSearch && (
                        <>
                          {!showLoading && (
                            <>
                              {/* Tabla de descuentos */}
                              <DataTable
                                  className="students-datatable shadow-sm rounded"
                                  noDataComponent="No hay descuentos que mostrar"  
                                  columns={columns}
                                  data={filteredItems}
                                  customStyles={customStyles}
                                  fixedHeader
                                  // Añade aquí más propiedades si es necesario
                              />
                              {/* Modal para el formulario de descuento */}
                              <MDBModal show={modalOpen} setShow={setModalOpen} tabIndex='-1'>
                                  <MDBModalDialog className="modal-xl-custom">
                                      <MDBModalContent>
                                          <MDBModalHeader>
                                              <MDBModalTitle>Nuevo Descuento</MDBModalTitle>
                                              <Button className='btn-close' color='none' onClick={closeModal}></Button>
                                          </MDBModalHeader>
                                          <MDBModalBody>
                                            <div className='card'>

                                            {selectedDescuento && (
                                                <DescuentoForm
                                                    key={Date.now()}
                                                    billingDiscountId={selectedDescuento.billingDiscountId}
                                                    onClose={() =>{
                                                        setModalOpen(false); 
                                                        fetchDescuentos();
                                                    }}
                                                    ReadOnly={BtnVer}
                                                />
                                            )}

                                            {!selectedDescuento && (
                                                <DescuentoForm
                                                    key={Date.now()}
                                                    billingDiscountId={0}
                                                    onClose={() =>{
                                                        setModalOpen(false); 
                                                        fetchDescuentos();
                                                    }}
                                                    ReadOnly={false}
                                                />
                                            )}
          
                                                  </div>
                                          </MDBModalBody>
                                          <MDBModalFooter>
                                              <Button onClick={closeModal}>Cerrar</Button>
                                          </MDBModalFooter>
                                      </MDBModalContent>
                                  </MDBModalDialog>
                              </MDBModal>
                              </>
                                )}
                              </>
                            )}
                          </div>
                    </div>
              
      </>
    );
};

export default DescuentosEstudiantes;
