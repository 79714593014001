import { useEffect, useState } from "react";
import collectionsService from "../services/collectionsService";
import documentService from "../services/documentService";
import types from "../services/types";
import docentesService from "../services/docentesService";

const FooterActa = ({ creadorId, reportTypeId }) => {

    const [signatures, setSignatures] = useState([]);
    const [columnasFirmas, setColumnasFirmas] = useState("");

    const cargarColumnasFirmas = (signatures) =>{
      const cantidad = signatures.length;
      if(cantidad > 4) return "auto auto auto auto";
      let columnas = "";
      for(let firma of signatures){
        columnas+="auto ";
      }
      return columnas;
    }

    useEffect(()=>{
      cargarFirmas()
    },[]);
    const cargarFirmas = async () => {
      try {
          let arr = [];
          if(creadorId){
            const respTeacher = await docentesService.getDocente(creadorId); // Asumiendo que tienes un servicio para recuperar detalles del docente
            const creadorSignatur = await documentService.getDocumentsToCustomId(types.documentTypes.TEACHER_SIGNATURE, creadorId);
            if(respTeacher && creadorSignatur && creadorSignatur.length>0){
              arr.push({
                firma:creadorSignatur[0].url,
                teacherName: respTeacher.fullName,
                cargo: "Docente y/o Tutor"
              });
            }else if(respTeacher){
              arr.push({
                firma:null,
                teacherName: respTeacher.fullName,
                cargo: "Docente y/o Tutor"
              });
            }
          }
          const response = await collectionsService.getSignaturesReports(reportTypeId);              
          if(response){
              for(let sig of response){
                const teacherSignatur = await documentService.getDocumentsToCustomId(types.documentTypes.TEACHER_SIGNATURE, sig.teacherId);
                if(teacherSignatur && teacherSignatur.length>0){
                    arr.push({
                      firma:teacherSignatur[0].url,
                      teacherName: sig.teacherName,
                      cargo: sig.description
                    });
                }else{
                  arr.push({
                    firma:null,
                    teacherName: sig.teacherName,
                    cargo: sig.description
                  });
                }                
              }
              setSignatures(arr);
              cargarColumnasFirmas(arr);
          }else{
              setSignatures([]);
          }
      } catch (error) {
           console.error("Error al obtener información de año escolar:", error);
      }
  }
    return (
      <footer className="acta-footer">
        <div className="footer-content">
          <div className="firmas" style={{gridTemplateColumns:columnasFirmas}}>
          {signatures?.map((sig, index) => (
                <div className="firma">
                <img src={sig.firma} alt="Firma Docente" className="firma-imagen"/>
                <p></p> {/* Línea de firma */}
                <p>{sig.cargo}</p> {/* Cargo   <p>Docente y/o Tutor</p> */}
                <p>{sig.teacherName}</p>
              </div>
            ))}
          </div>
        </div>
      </footer>
    );
};

export default FooterActa;
