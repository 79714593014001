import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import BillingForm from "../components/Forms/BillingForm";
import billingService from "../services/billingService";
import utils from '../utils/generalFunctions';
import Swal from "sweetalert2";
import ReciboPago from "../components/Reports/reciboPago";
import useCustomDataTableStyles from "../components/estilosDeDataTable";

const BillingView = () => {
    const [billings, setBillings] = useState([]);
    const [billingDetails, setBillingDetails] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [reloadInformacion, setReloadInformacion] = useState(false);
    const [selectedBilling, setSelectedBilling] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');
    const pdfRef = useRef(null);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        setSelectedBilling(null);
    };

    const openMaximizeModal = (billing) => {
        setSelectedBilling(billing);
        setModalOpen(true);
        setBtnVer(true);
    };

    const exportarRecibo =  async (billing) => {
        if (billing) {
            const response = await billingService.getBillingDetailsById(billing.billingId);
            setBillingDetails(response);
            pdfRef.current.exportarPdf();
        }
    };

    const openEditModal = (billing) => {
        setSelectedBilling(billing);
        setModalOpen(true);
        setBtnVer(false);
    };

    const cancelarRecibo = async (billing) => {
        Swal.fire({
          title:  '¿Está seguro de que desea anular este recibo?',
          text: "",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, anular!'
        }).then(async(result) => {
          if (result.isConfirmed) {
            try{
            billing.billingStatusId = 3;
            await billingService.updateBilling(billing);
            Swal.fire(
              'Felicidades!',
              'Recibo anulado exitosamente!',
              'success'
            );
            await fetchBillings();
            }catch(error){
              Swal.fire(
                  "Ha ocurrido un error",
                  "Por favor intentelo más tarde",
                  "error"
              );
            }
          }
        })
    };


    const validateReloadInformation = async () => {
        if(reloadInformacion){
            fetchBillings();
        }
    };

    const fetchBillings = async () => {
        try {
            const response = await billingService.getAllBillings();
            setBillings(response);
        } catch (error) {
            console.error("Error al obtener las facturas:", error);
        }
    };

    useEffect(() => {
        fetchBillings();
    }, []);

    const columns = [
        { name: "Consecutivo", selector: "consecutive", sortable: true,grow:"-1" },
        { name: "Estudiante", selector: "studentName", sortable: true,grow:"2.5" },
        { name: "Fecha", selector: "billingDate", sortable: true,grow:"1", format: row => new Date(row.billingDate).toLocaleDateString() },
        { name: "Total", selector: "total", sortable: true,grow:"1", cell: (row, index) => (<div>{utils.formatNumber.new(row.total, "$")}</div>) },
        { name: "Estado", selector: "billingStatusName", sortable: true, grow:"-1"},
        { name: "Tipo de pago", selector: "paymentTypeName", sortable: true, grow:"1.5"},
        { name: "Año", selector: "year", grow:"1", sortable: true },
        {
            name: "Acciones",grow:"2.5",
            cell: (row) => (
                <div className="actions-container justify-content-between align-items-center">
                    <button
                        onClick={() => openMaximizeModal(row)}
                        className="btn-maximize btn-sm me-2"
                        title="Maximizar información"
                        >
                        <i className="fas fa-eye"></i>
                    </button>
                    {/* <button
                        onClick={() => exportarRecibo(row)}
                        className="btn-deactivate btn-sm me-2"
                        title="Exportar">
                        <i class="fa-solid fa-file-pdf"></i>
                    </button> */}
                    {(row.billingStatusId!==3) &&
                        <button
                            onClick={()=> cancelarRecibo(row)}
                            className="btn-deactivate btn-sm me-2"
                            title="Anular">
                            <i className="fa-solid fa-circle-minus"></i>
                        </button>
                    }
              </div>
            ),
        },
        
    ];
    const normalizeString = (str) => {
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      };

    const filteredItems = billings.filter((item) => {
        if (item) {
            const allData = `${item.consecutive} ${item.studentName} ${item.total} ${item.billingDate} ${item.duedate} ${item.billingStatusName} ${item.isActive}`;
            const normalizedFilterText = normalizeString(filterText);
            const normalizedItemName = normalizeString(allData);
            return normalizedItemName.includes(normalizedFilterText);
        }
        return item;
    });

    const customStyles = useCustomDataTableStyles();

    const paginationPerPage = 50; // Ajusta este número según tus necesidades


    return (
        <div className="student-table-container p-3">
            <h1>Recibos de pagos</h1>
            <p>Esta es la vista de generación de recibos.</p>
            <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
            <input
                    type="text"
                    placeholder="Buscar"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    className="form-control form-control-sm w-50"
                />

            <div className="mb-3">
            <div className="btn-group">
                <button onClick={() => openEditModal(null)} className="btn-sm btn-Estudiante btn-NuevoEstudiante">
                    Generar recibo de pago<i className="fa-solid fa-circle-plus "></i>
                </button>
                </div>
            </div>
            </div>
            <DataTable
                title="Historial de recibos de pagos"
                className="datatable-recibosdepago students-datatable shadow-sm rounded"
                noDataComponent="No hay información que mostrar, por favor añadela"   
                columns={columns}
                data={filteredItems}
                pagination
                fixedHeader
                customStyles={customStyles}
                paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                paginationRowsPerPageOptions={[50, 100]}

            />
            <MDBModal tabIndex='-1' show={modalOpen} setShow={setModalOpen} onHide={
               ()=>{ validateReloadInformation(); }}>
                <MDBModalDialog className="modal-xl-custom">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{selectedBilling ? 'Recibo de pago' : 'Nuevo Recibo de pago'}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                        <div className='card'>
                                {selectedBilling && (
                                    <BillingForm
                                        key={Date.now()}
                                        billingId={selectedBilling.billingId}
                                        onClose={() =>{
                                            setReloadInformacion(true);
                                        }}
                                        ReadOnly={BtnVer}
                                    />
                                )}

                                { !selectedBilling && (
                                    <BillingForm
                                        key={Date.now()}
                                        gradoItem={0}
                                        onClose={() =>{
                                            setReloadInformacion(true);
                                        }}
                                        ReadOnly={false}
                                    />
                                )}
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type='button' className='btn btn-primary' onClick={toggleModal}>
                                Cerrar
                            </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            {/* {billingDetails && billingDetails.billingId && (
                <div className='display-none'>
                    <ReciboPago recibo={billingDetails} ref={pdfRef}/>
                </div>
            )} */}
        </div>
    );
};

export default BillingView;