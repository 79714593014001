import api from './api';

const getAsignaturas = async () => {
    try {
        const response = await api.get(`/academic/schoolSubject`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createAsignatura = async (periodoData) => {
    try {
        const response = await api.post(`/academic/schoolSubject`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateAsignatura = async (periodoData) => {
    try {
        const response = await api.put(`/academic/schoolSubject`, periodoData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAsignatura = async (id) => {
    try {
        const response = await api.get(`/academic/schoolSubject/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteAsignaturaById = async (id) => {
    try {
        const response = await api.delete(`/academic/schoolSubject/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getAsignaturas,
    createAsignatura,
    updateAsignatura,
    getAsignatura,
    deleteAsignaturaById
};