import api from './api';

const enviarEmail = async (type, file, itemId) => {
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('type', type);   
        formDataToSend.append('file', file);
        formDataToSend.append('itemId', itemId);   
        const response = await api.post('/document/send-email', formDataToSend,{
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getDocumentsToCustomId = async (documentTypeId, ownerId) => {
    try {
        const response = await api.get(`/document/type/${documentTypeId}/owner/${ownerId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const addDocument = async (type, file, itemId, name, size,extension) => {
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('documentTypeId', type);   
        formDataToSend.append('file', file);
        formDataToSend.append('ownerId', itemId);   
        formDataToSend.append('name', name);   
        formDataToSend.append('size', size);   
        formDataToSend.append('extension', extension);   
        formDataToSend.append('isActive', 1);   
        const response = await api.post('/document', formDataToSend,{
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const removeDocument = async (documentId) => {
    try {
        const response = await api.delete(`/document/${documentId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export default {
    enviarEmail,
    addDocument,
    removeDocument,
    getDocumentsToCustomId
};