import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import Loading from '../components/Loading';
import AcademicPeriodForm from "../components/Forms/FormPeriodoAcademico";
import periodoService from "../services/periodoService";
import Swal from "sweetalert2";
import useCustomDataTableStyles from "../components/estilosDeDataTable";



const AcademicPeriod = () => {
  
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [periodos, setPeriodos] = useState([]);
    const [selectedPeriodo, setSelectedPeriodo] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');

    const initData ={
      periodoTypeId:"0"
  };

  const [formData, setFormData] = useState(initData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));        
};


    const toggleShow = () => {
        setFullscreenXlModal(!fullscreenXlModal);
        setSelectedPeriodo(null); 
    }

    const openMaximizeModal = (periodo) => {
        setSelectedPeriodo(periodo);
        setFullscreenXlModal(true);
        setBtnVer(true);
    };

    const openEditingModal = async (periodo) => {
        setSelectedPeriodo(periodo);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };
    
    const openAddingModal = async (periodo) => {
        setSelectedPeriodo(periodo);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };

    const deletePeriodo = async(periodo)=>{
      Swal.fire({
          title: 'Esta seguro que desea eliminar este periodo?',
          text: "",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminar!'
        }).then(async(result) => {
          if (result.isConfirmed) {
            try{
            await periodoService.deletePeriodoAcademicoById(periodo.academicPeriodId);
            Swal.fire(
              'Felicidades!',
              'Periodo eliminado exitosamente!',
              'success'
            );
            fetchAcademicPeriods();
            }catch(error){
              Swal.fire(
                  "Ha ocurrido un error",
                  "Por favor intentelo mas tarde",
                  "error"
              );
            }
          }
        })
  }

    const fetchAcademicPeriods = async () => {
        try {
            const response = await periodoService.getPeriodosAcademicos();
            const periodosFilter= response.filter(r=>  String(r.isRecuperation)===formData.periodoTypeId);
            setPeriodos(periodosFilter);
        } catch (error) {
            console.error("Error al obtener los periodos academicos:", error);
        }
    };

    useEffect(() => {
        fetchAcademicPeriods();
    }, []);

  const columns = [
    { name: "N°", selector: "consecutive", sortable: true, 
    cell: (row, index) => <span>{index + 1}</span>, },               // Número identificatorio
    { name: "Nombre", selector: "name", sortable: true, grow:2 },                 // Nombre del período académico
    { name: "Orden", selector: "order", sortable: true },                 // Orden del período académico
    { name: "Fecha Inicio", selector: "startDate",grow:2, sortable: true },   // Fecha de inicio del período académico
    { name: "Fecha Cierre", selector: "endDate",grow:2, sortable: true },     // Fecha de cierre del período académico
    { name: "Jornada", selector: "journeyName", grow:2, sortable: true },            // Ejemplo: Anual o Semestral
    { name: "Activo", selector: "isActive", sortable: true,
    cell: (row) => (
      <>
       {row.isActive ? <span>Si</span> : <span>No</span>}
      </>
    ), },             // Si el período académico está activo o no
    {
      name: "Acciones",grow:"2.5",
      selector: "actions",
      cell: (row) => (
        <div className="actions-container justify-content-between align-items-center">
          <button
            onClick={() => openMaximizeModal(row)}
            className="btn-maximize btn-sm me-2"
            title="Maximizar información"
          >
            <i className="fas fa-eye"></i>
          </button>
          <button
            onClick={() => openEditingModal(row)}
            className="btn-edit btn-sm me-2"
            title="Editar"
          >
            <i className="icon-edit fas fa-pencil-alt"></i>
          </button>
          <button
            onClick={() => deletePeriodo(row)}
            className="btn-deactivate btn-sm"
            title="Eliminar"
          >
            <i className="icon-deactivate fas fa-times"></i>
          </button>
        </div>
      ),
    },
  ];

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredItems = periodos?.filter((item) => {
    if (item) {
      const allData = `${item.consecutive} ${item.name} ${item.order} ${item.startDate} ${item.endDate} ${item.journeyName} ${item.isActive}`;
      const normalizedFilterText = normalizeString(filterText);
      const normalizedItemName = normalizeString(allData);
      return normalizedItemName.includes(normalizedFilterText);
    }
    return item;
  });

  const customStyles = useCustomDataTableStyles();

  const paginationPerPage = 50; // Ajusta este número según tus necesidades


  return (

    <div className="student-table-container p-3">
      <h1>Período académico</h1>
        
           <div className="row mb-3 d-flex justify-content-between align-items-center">
              <div className="col-md-4 mb-2">
                  <input
                        id="txtBuscarPeriodo"
                        type="text"
                        placeholder="Buscar"
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                        className="form-control form-control-sm margin-top-45"
                    />
             </div>
             <div className="col-md-3 mb-2 margin-top-45">
                      <button className=" btn-NuevoEstudiante"  onClick={() => openAddingModal(null)} >Agregar Nuevo período <i className="fa-solid fa-circle-plus"></i></button>
            </div>
             <div className="col-md-2 mb-2"></div>

              <div className="col-md-2 mb-2">
                    <label htmlFor="periodoTypeId" className="form-label">Período:</label>
                    <select className="form-control form-select" id="periodoTypeId" name="periodoTypeId" 
                        value={formData.periodoTypeId} onChange={handleChange}>
                        <option value="0" >Escolar</option>
                        <option value="1" >Recuperación</option>
                    </select>
              </div>

              <div className="col-md-1 mb-2 margin-top-45">
                      <button className="btn-sm btn-NuevoEstudiante"  onClick={() => fetchAcademicPeriods(null)} >Buscar</button>
              </div>
        </div>
        
      
            <DataTable
                title="Lista de periodos académicos"
                className="students-datatable shadow-sm rounded"
                columns={columns}
                data={filteredItems}
                noDataComponent="No hay información que mostrar, por favor añadela"   
                pagination
                fixedHeader
                customStyles={customStyles}
                paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                paginationRowsPerPageOptions={[50, 100]}

            />

             {/* Renderiza el modal y el formulario aquí */}
            <>
                <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                <MDBModalDialog className="modal-xl-custom">
                    <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>DETALLES DEL PERIODO ACADÉMICO</MDBModalTitle>
                        <MDBBtn
                        type='button'
                        className='btn-close'
                        color='none'
                        onClick={toggleShow}
                        ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div className='card'>
                            {selectedPeriodo && (
                                <AcademicPeriodForm
                                    key={Date.now()}
                                    academicPeriod={selectedPeriodo.academicPeriodId}
                                    onClose={() => {
                                        setFullscreenXlModal(false); 
                                        fetchAcademicPeriods();
                                    }}
                                    ReadOnly={BtnVer}
                                />
                            )}

                            { !selectedPeriodo && (
                                <AcademicPeriodForm
                                    key={Date.now()}
                                    academicPeriod={0}
                                    onClose={() => {
                                        setFullscreenXlModal(false); 
                                        fetchAcademicPeriods();
                                    }}
                                    ReadOnly={false}
                                />
                            )}
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                    <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                        Cerrar
                    </button>
                    </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
                </MDBModal>
            </>
            

        </div>
    );
};

export default AcademicPeriod;
