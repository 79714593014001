import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/estudiantes.css";
import estudiantesService from "../services/estudiantesService";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import StudentForm from '../components/Forms/StudentForm';
import Loading from '../components/Loading';
import { toast } from "react-toastify";
import FormMatricula from "../components/Forms/FormMatricula";
import JornadaAnioFilter from "../components/Filters/JornadaAnioFilter";
import EstudianteFilter from "../components/Filters/EstudianteFilter";
import collectionsService from '../services/collectionsService';
import useCustomDataTableStyles from "../components/estilosDeDataTable";
import utils from "../utils/generalFunctions";
import Swal from "sweetalert2";

const Matriculas = () => {
  
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [matricularModal, setMatricularModal] = useState(false);
    const [students, setStudents] = useState([]);
    const [studentsExportar, setStudentsExportar] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedStudentsEnrollment, setSelectedStudentsEnrollment] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showMassEnrollmentButton, setShowMassEnrollmentButton] = useState(false);
    const [enrollmentId, setEnrollmentId] = useState(false);
    const filteReference = useRef(null);

    const initData ={
        schoolYearId: 0,
        journeyId:0,
        studentId:0,
        isMatriculado:1,
    };

    const [formData, setFormData] = useState(initData);

    const fechtCurrentAnio = async () => {
      const parameters = await collectionsService.getParameters();
      const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
      if(currentAnio){
        setFormData((prevState) => ({ ...prevState, schoolYearId:currentAnio.value }));
        filteReference?.current?.setFormValues({schoolYearId:currentAnio.value});
      }
    }

    useEffect(() => {
      fechtCurrentAnio();
    }, []);

    const closeModal = (close) => {
        setFullscreenXlModal(false);
        setMatricularModal(false);
        setSelectedStudent(null); 
    }

    const updateForm = (data) => {
      setFormData(prevState => ({
          ...prevState,
          ...data
        }));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));        
    };

    const openMaximizeModal = (student) => {
        setSelectedStudent(student);
        setFullscreenXlModal(true);
        setBtnVer(true);
    };

    const openEditingModal = async (student) => {
        setSelectedStudentsEnrollment([student.studentId]);
        setEnrollmentId(student.enrollmentId??null);
        setMatricularModal(true);
        setBtnVer(false);
    };

  const openMasiveModal = async () => {
    const studenstId = selectedRows.map(a => a.studentId);
    setSelectedStudentsEnrollment(studenstId)
    setMatricularModal(true);
    setBtnVer(false);
  };

    const buscarEstudiantes = async () => {
        try {
          setShowLoading(true);
          const responseMatriculados = await estudiantesService
                    .getEstudiantesPorAnio(formData.studentId, 
                      formData.journeyId,
                      formData.schoolYearId,
                      formData.isMatriculado);
          setStudents(responseMatriculados);
          setStudentsExportar(responseMatriculados);
          setShowLoading(false);
          setShowSearch(true);
          setShowMassEnrollmentButton(formData.isMatriculado==="0");
        } catch (error) {
            console.error("Error al obtener los estudiantes:", error);
        }
    };

    // Actualiza la lógica para mostrar el botón de matrícula masiva
  useEffect(() => {
    setShowMassEnrollmentButton(
      selectedRows.length > 0 && showMassEnrollmentButton
    );
  }, [selectedRows.length]);

  // Función que se llama cuando se seleccionan/deseleccionan filas
  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  
  const habilitarMatricula = async (matricula) => {
    Swal.fire({
      title:  '¿Está seguro de que desea habilitar esta matricula?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, habilitar!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
        const newMatricula = await estudiantesService.getMatricula(matricula.enrollmentId);
        newMatricula.isActive = true;
        await estudiantesService.updateMatricula(newMatricula);
        Swal.fire(
          'Felicidades!',
          'Matricula habilitada exitosamente!',
          'success'
        );
        await buscarEstudiantes();
        }catch(error){
          Swal.fire(
              "Ha ocurrido un error",
              "Por favor intentelo más tarde",
              "error"
          );
        }
      }
    })
};

const deshabilitarMatricula = async (matricula) => {
  Swal.fire({
    title:  '¿Está seguro de que desea deshabilitar esta matricula?',
    text: "",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, anular!'
  }).then(async(result) => {
    if (result.isConfirmed) {
      try{
      const newMatricula = await estudiantesService.getMatricula(matricula.enrollmentId);
      newMatricula.isActive = false;
      await estudiantesService.updateMatricula(newMatricula);
      Swal.fire(
        'Felicidades!',
        'Matricula deshabilitada exitosamente!',
        'success'
      );
      await buscarEstudiantes();
      }catch(error){
        Swal.fire(
            "Ha ocurrido un error",
            "Por favor intentelo más tarde",
            "error"
        );
      }
    }
  })
};


  const columns = [
    { name: "Id", selector: "identification", sortable:true,grow:"1"},
    { name: "Nombre completo", selector: "fullName", sortable:true,grow:"2" }, // Asumiendo que 'nombre' contiene tanto el nombre como el apellido
    { name: "Grado", selector: "grade", sortable:true,grow:"1" },
    { name: "Año", selector: "schoolYear", sortable:true,grow:"-1" },
    { name: "Jornada", selector: "journey", sortable:true,grow:"1" },
    { name: "Estado", selector: "isActive", sortable:true,grow:"1",
      cell: (row) => (row.isActive ? <span>Activo</span> : <span>Inactivo</span>),
  },
    {
      name: "Acciones",grow:"2.5",
      selector: "actions",
      cell: (row) => (
        <div className="actions-container justify-content-between align-items-center">
          <button
            onClick={() => openMaximizeModal(row)}
            className="btn-maximize btn-sm me-2"
            title="Maximizar información"
          >
            <i className="fas fa-eye"></i>
          </button>
          
          {!row.enrollmentId && (
            <button
              onClick={() => openEditingModal(row)}
              className="btn-edit btn-sm me-2"
              title="Matricular"
            >
              <i className="fas fa-file-circle-check"></i>
            </button>
          )}

          {row.enrollmentId && (
            <>
            <button
               onClick={() => openEditingModal(row)}
               className="btn-edit btn-sm me-2"
               title="Editar"
             >
              <i className="icon-edit fas fa-pencil-alt"></i>
             </button>
              <button className="btn-deactivate  btn-sm me-2" title="Eliminar" onClick={() => {
                removeMatricula(row.enrollmentId);
                // Aquí la lógica para eliminar una categoría
               }}>
                <i className="icon-deactivate fas fa-times"></i>
              </button>
              {row.isActive && (

                <button
                    onClick={()=> deshabilitarMatricula(row)}
                    className="btn-deactivate btn-sm"
                    title="Deshabilitar">
                    <i className="fa-solid fa-circle-minus"></i>
                </button>

              )}
              
              {!row.isActive && (

              <button
                onClick={()=> habilitarMatricula(row)}
                className="btn-maximize btn-sm"
                title="Habilitar">
                <i className="fa-solid fa-circle-check"></i>
                </button>

              )}
            </>
          )}

        </div>
      ),
    },
  ];

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredItems = students?.filter((item) => {
    if (item) {
      const allData = `${item.fullName} ${item.identification} ${item.grade} ${item.email} ${item.cellPhone} ${item.parentName} ${item.parentCellPhone} ${item.parentEmail}`;
      const normalizedFilterText = normalizeString(filterText);
      const normalizedItemName = normalizeString(allData);
      return normalizedItemName.includes(normalizedFilterText);
    }
    return item;
  });

  const customStyles = useCustomDataTableStyles();

  // Esta es la cantidad de filas que quieres mostrar por página
  const paginationPerPage = 50; // Ajusta este número según tus necesidades

  const exportStudents = async () => {
      
      const dataMapped = studentsExportar.map(item => ({
        "Identificación": item.identification??'',
        "Nombre completo": item.fullName??'',
        "Grado": item.grade??'',
        "Año": item.schoolYear??'',
        "Jornada": item.journey??''
      }));
    
      utils.exportDataToExcel(dataMapped, ((String(formData.isMatriculado)==="1")?'estudiantes-matriculados':'estudiantes-no-matriculados'));
  };

  const removeMatricula = (enrollmentId) => {
       
    if(enrollmentId){
        Swal.fire({
            title:  '¿Está seguro de que desea eliminar esta matricula?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
              try{
              
              await estudiantesService.removeMatricula(enrollmentId);
              Swal.fire(
                'Felicidades!',
                'Matricula eliminada exitosamente!',
                'success'
              );
              await buscarEstudiantes();
              }catch(error){
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intentelo más tarde",
                    "error"
                );
              }
            }
          })    
    }
};

  const actionsMemo = React.useMemo(() =>
  <button className="btn-matricula margin-right-20-percent" onClick={() => { exportStudents(); }}>
    Exportar estudiantes
  </button>, [studentsExportar]);

  return (
    <>

  <div className="student-table-container p-3">
        <h1>Administración de matriculas</h1>
              <div className="row">

                 <EstudianteFilter
                      callback={updateForm}
                  />
                  <div className="col-md-2 mb-2">
                    <label htmlFor="isMatriculado" className="form-label">Estado:</label>
                    <select className="form-control form-select" id="isMatriculado" name="isMatriculado" 
                        value={formData.isMatriculado} onChange={handleChange}>
                        <option value="1" >Maticulado</option>
                        <option value="0" >No Maticulado</option>
                    </select>
                  </div>
                  <JornadaAnioFilter
                      callback={updateForm}
                      ref={filteReference}
                  />

                  

                  <div className="col-md-2 mb-2 margin-top-45">
                          <button className="btn-sm btn-NuevoEstudiante"  onClick={() => buscarEstudiantes(null)} >Buscar <i className="fa-solid fa-search"></i></button>
                  </div>
              </div>
    </div>
    {showLoading && <Loading />}
    
    {showSearch && (
      <>
          {!showLoading && (

              <div className="student-table-container p-3 margin-top-10">
                  
                        <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
                            <input
                                type="text"
                                placeholder="Buscar estudiante"
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                                className="form-control form-control-sm w-50"
                            />

        
                            {/* Botón de matrícula masiva */}
              {showMassEnrollmentButton && selectedRows.length>0 && (
                <button className="btn-matricula" onClick={() => {openMasiveModal()}}>
                  Matricular Masivo
                </button>
              )}
                        </div>
                        <DataTable
                            title="Lista de estudiantes"
                            className="students-datatable shadow-sm rounded"
                            columns={columns}
                            data={filteredItems}
                            actions={actionsMemo}
                            pagination
                            fixedHeader
                            customStyles={customStyles}
                            noDataComponent="No hay información que mostrar, por favor añadela"   
                            paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
                            paginationRowsPerPageOptions={[50, 100]}
                            selectableRows={showMassEnrollmentButton} // Habilita la selección solo si no están matriculados
                            onSelectedRowsChange={handleRowSelected} // Maneja el cambio de selección
            
                        />
                        

                        {/* Renderiza el modal y el formulario aquí */}
                        <>
                            <MDBModal tabIndex='-1' show={fullscreenXlModal} setShow={setFullscreenXlModal}>
                            <MDBModalDialog className="modal-xl-custom">
                                <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>DETALLES DEL ESTUDIANTE</MDBModalTitle>
                                    <MDBBtn
                                    type='button'
                                    className='btn-close'
                                    color='none'
                                    onClick={closeModal}
                                    ></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <div className='card'>
                                        {selectedStudent && fullscreenXlModal && (
                                            <StudentForm
                                                key={Date.now()}
                                                student={selectedStudent.identification}
                                                hideBtnInscription={true}
                                                onClose={() =>{
                                                    setFullscreenXlModal(false); 
                                                    buscarEstudiantes();
                                                }}
                                                ReadOnly={BtnVer}
                                            />
                                        )}

                                        { !selectedStudent && fullscreenXlModal && (
                                            <StudentForm
                                                key={Date.now()}
                                                student={0}
                                                hideBtnInscription={true}
                                                onClose={() =>{
                                                    setFullscreenXlModal(false); 
                                                    buscarEstudiantes();
                                                }}
                                                ReadOnly={false}
                                            />
                                        )}
                                        </div>
                                </MDBModalBody>
                                <MDBModalFooter>
                                <button type='button' className='btn-footer-close btn-primary' onClick={closeModal}>
                                    Cerrar
                                </button>
                                </MDBModalFooter>
                                </MDBModalContent>
                            </MDBModalDialog>
                            </MDBModal>
                        </>

                        <>
                            <MDBModal tabIndex='-1' show={matricularModal} setShow={setMatricularModal}>
                            <MDBModalDialog className="modal-xl-custom">
                                <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>MATRICULAR ESTUDIANTE</MDBModalTitle>
                                    <MDBBtn
                                    type='button'
                                    className='btn-close'
                                    color='none'
                                    onClick={closeModal}
                                    ></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <div className='card'>
                                        {selectedStudentsEnrollment && matricularModal && (
                                            <FormMatricula
                                                key={Date.now()}
                                                students={selectedStudentsEnrollment}
                                                enrollmentId={enrollmentId}
                                                hideBtnInscription={true}
                                                onClose={() =>{
                                                    setMatricularModal(false); 
                                                    buscarEstudiantes();
                                                }}
                                                ReadOnly={BtnVer}
                                            />
                                        )}

                                        { !selectedStudentsEnrollment && matricularModal && (
                                            <FormMatricula
                                                key={Date.now()}
                                                students={[]}
                                                enrollmentId={null}
                                                hideBtnInscription={true}
                                                onClose={() =>{
                                                    setMatricularModal(false); 
                                                    buscarEstudiantes();
                                                }}
                                                ReadOnly={false}
                                            />
                                        )}
                                        </div>
                                </MDBModalBody>
                                <MDBModalFooter>
                                <button type='button' className='btn-footer-close btn-primary' onClick={closeModal}>
                                    Cerrar
                                </button>
                                </MDBModalFooter>
                                </MDBModalContent>
                            </MDBModalDialog>
                            </MDBModal>
                        </>
                </div>
           )}
      </>
    )}
  
   
    </>
  );
};

export default Matriculas;