import React, { Component, useState } from "react";
import userProfile from "../img/user-profile.png";
import avatar5 from '../img/user-profile.png';
import types from '../services/types';
import userServices from '../services/userService';
import studentService from "../services/estudiantesService";
import util from '../utils/generalFunctions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import "../css/header.css";
import { LazyLoadImage } from "react-lazy-load-image-component";


class Header extends Component {
  constructor(props) {
    super(props);
	const userTypeId = localStorage.getItem('userTypeId');
	const student = util.getStudentInformation();
    this.cerrarSession = props.cerrarSession;
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
	  rolDropdownOpen: false,  // Estado para manejar la visibilidad del dropdown de roles
	  childDropdownOpen: false,  // Estado para manejar la visibilidad del dropdown de roles
	  idUsuarioActivo:isNaN(Number(userTypeId))?0:Number(userTypeId),
	  idStudianteActivo:(student)?isNaN(Number(student.studentId))?0:Number(student.studentId):0,
    };
	
  }
  
  
  // Función para alternar la visibilidad del dropdown de roles
  toggleRolDropdown = () => {
	this.setState(prevState => ({
		rolDropdownOpen: !prevState.rolDropdownOpen
	}));
}

toggleChildDropdown = () => {
	this.setState(prevState => ({
		childDropdownOpen: !prevState.childDropdownOpen
	}));
}

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  getStudentsByParent=async () => {
    const reponse = await studentService.getEstudiantesByParents();
    return reponse;
  }
  
 async changeUser(user) {
	const result = await this.getNewUserToken(user.userId);
	if(result){
		localStorage.setItem('userTypeId',user.userTypeId);
		this.setState(prevState => ({
			idUsuarioActivo: user.userTypeId
		}));
		const token = result.token
		localStorage.setItem('userTypeId', result.userTypeId);
		localStorage.setItem('token', token);

		if(result.userTypeId === types.userTypes.PARENTS){
			const students = await this.getStudentsByParent();
			const sData = btoa(JSON.stringify(students));
			localStorage.setItem('_students', sData);
			if(students && students.length>0){
			  const stuData = btoa(JSON.stringify(students[0]));
			  localStorage.setItem('_student', stuData);
			}
		}else{
			localStorage.setItem('_student', '');
			localStorage.setItem('_students', '');
		}
		const protocol = window.location.protocol;
        const host = window.location.host;
        const baseUrl = `${protocol}//${host}`;
        const loginPath = '/dashboard';
        const loginUrl = `${baseUrl}${loginPath}`;
        window.location.href = loginUrl;
	}
  }

  async changeStudent(student) {
	const stuData = btoa(JSON.stringify(student));
	localStorage.setItem('_student', stuData);
	this.setState(prevState => ({
		idStudianteActivo: student.studentId
	}));
	const protocol = window.location.protocol;
	const host = window.location.host;
	const baseUrl = `${protocol}//${host}`;
	const loginPath = '/dashboard';
	const loginUrl = `${baseUrl}${loginPath}`;
	window.location.href = loginUrl;
  }

  getNewUserToken=async (userId) => {
    const reponse  = await userServices.getUserToken(userId);
    return reponse.data;
  } 

  sidebarToggle(e) {
    e.preventDefault();
    const sidebar = document.getElementById("sidebar");
    if (sidebar.className === "sidebar")
      sidebar.className = "sidebar collapsed";
    else sidebar.className = "sidebar";
  }

  profileMenuToggle(e) {
    e.preventDefault();
	let customClass='';
	if(e.target.id==="menuMobile"){
		customClass="dropdown-movil";
	}
    const sidebar = document.getElementById("profileMenu");
    if (sidebar.className.includes("show"))
      sidebar.className = "dropdown-menu dropdown-menu-end "+customClass;
    else sidebar.className = "dropdown-menu dropdown-menu-end show "+customClass;
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-mobile-show");
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("aside-menu-hidden");
  }

  loadProfiles(){
	try{
		const data = localStorage.getItem('_data');
		const ddata = atob(data);
		const profiles =  JSON.parse(ddata);
		return profiles;
	}catch(ex){	
		localStorage.setItem('userTypeId', '');
		localStorage.setItem('token', '');
		localStorage.setItem('_data', '');
		localStorage.setItem('_student', '');
		localStorage.setItem('_students', '');
		const protocol = window.location.protocol;
		const host = window.location.host;
		const baseUrl = `${protocol}//${host}`;
		const loginPath = '/';
		const loginUrl = `${baseUrl}${loginPath}`;
		window.location.href = loginUrl;
	}
  }

  loadStudentsList(){
	const studentsLits = localStorage.getItem('_students');
	if(studentsLits){
		try{
			const ddata = atob(studentsLits);
			const students =  JSON.parse(ddata);
			return students;
		}catch(ex){
			return [];
		}
	}else{
		return [];
	}
  }

  getInformationUser(){
	try{
		const data = localStorage.getItem('_data');
		const ddata = atob(data);
		const profiles =  JSON.parse(ddata)
		const userTypeId = localStorage.getItem('userTypeId');
		return profiles?.find(u=> Number(u.userTypeId) === Number(userTypeId));
	}catch(ex){
		localStorage.setItem('userTypeId', '');
		localStorage.setItem('token', '');
		localStorage.setItem('_data', '');
		localStorage.setItem('_student', '');
		localStorage.setItem('_students', '');
		const protocol = window.location.protocol;
        const host = window.location.host;
        const baseUrl = `${protocol}//${host}`;
        const loginPath = '/';
        const loginUrl = `${baseUrl}${loginPath}`;
        window.location.href = loginUrl;
	}
  }

  getProfileName(typeUser){
	switch(typeUser){
		case types.userTypes.ADMIN: return "Administrador";
		case types.userTypes.COODINATOR: return "Coordinador";
		case types.userTypes.TEACHER: return "Profesor";
		case types.userTypes.STUDENT: return "Estudiante";
		case types.userTypes.PARENTS: return "Padre y/o Madre";
		case types.userTypes.WORKER: return "Funcionario";
		default: return "Profile"
	}
  }

  render() {
	const { rolDropdownOpen } = this.state;
	const { childDropdownOpen } = this.state;
	const profiles = this.loadProfiles();
	const students = this.loadStudentsList();
	const {idUsuarioActivo} = this.state; 
	const {idStudianteActivo} = this.state;
	const user =  this.getInformationUser();

    return (
      <nav
        className="navbar navbar-expand navbar-light navbar-bg"
        id="lateralMenu"
      >
        <a className="sidebar-toggle" onClick={this.sidebarToggle}>
          <i className="hamburger align-self-center"></i>
        </a>

        <div className="navbar-collapse collapse">
          <ul className="navbar-nav navbar-align">
		  {students.length > 1 && (
		  	<li class="nav-item dropdown">
			  <a className={`nav-icon dropdown-toggle ${childDropdownOpen ? "show" : ""}`} href="#" id="alertsDropdown" onClick={this.toggleChildDropdown} aria-expanded={childDropdownOpen}>
								<div class="position-relative">
								<i class="fas fa-universal-access"></i><span class="indicator">{students.length}</span>
								</div>
							</a>
							<div className={`dropdown-movil-child dropdown-menu  dropdown-menu-lg dropdown-menu-end py-0 ${childDropdownOpen ? "show" : ""}`} aria-labelledby="servicesDropdown"		>										
								 <div class="list-group">
								 {students.map(s=>{
											return(
										<a href="#" class="list-group-item"  onClick={()=>{this.changeStudent(s)}} >
											<div class="row g-0 align-items-center">
											<div class="col-2">
												<LazyLoadImage
													src={s.urlImage ?? avatar5} // Utiliza el operador de coalescencia nula para proporcionar una imagen por defecto
													alt={s.studentFullName} // Asegúrate de mantener el atributo alt para accesibilidad
													className="avatar img-fluid rounded-circle" // Reutiliza todas las clases para mantener el estilo
													effect="blur" // Opcional: añade un efecto de carga, como 'blur'
												/>
											</div>
												<div class="col-10 ps-2">
													<div class="text-dark"><h5>{s.fullName}</h5></div>
													<div class="text-muted small mt-1"><strong>Curso:</strong>  {s.courseName}</div>
														{(idStudianteActivo!==s.studentId) && (
															<div class="badge badge-soft-danger me-2">Inactivo</div>
														)}
														{(idStudianteActivo===s.studentId) && (
															<div class="badge badge-soft-success me-2"><span>Activo</span></div>
														)}
												</div>
											</div>
										</a>
											)}
									)}
								</div>
							</div>
			</li>
			)}

			{(profiles?.length??0) > 1 && (
				<li class="nav-item dropdown">
				<a className={`nav-icon dropdown-toggle ${rolDropdownOpen ? "show" : ""}`} href="#" id="alertsDropdown" onClick={this.toggleRolDropdown} aria-expanded={rolDropdownOpen}>
									<div class="position-relative">
									<i class="fas fa-sync-alt"></i>
									</div>
								</a>
								<div className={`dropdown-movil-rol dropdown-menu dropdown-menu-lg  dropdown-menu-end py-0 ${rolDropdownOpen ? "show" : ""}`} aria-labelledby="alertsDropdown"	>
									<div class="dropdown-menu-header">
										<span>Cambia tu rol.</span>
									</div>
									<div class="list-group">
										{profiles?.map(p=>{
											return(
											<a href="#" class="list-group-item" onClick={()=>{this.changeUser(p)}} >
												<div class="row g-0 align-items-center">
													<div class="col-2">
													<i class="fa-regular fa-user"></i>
													</div>
													<div class="col-10">
														<div class="text-dark">{this.getProfileName(p.userTypeId)}</div>
														{(idUsuarioActivo!==p.userTypeId) && (
														<div class="badge badge-soft-danger me-2">Inactivo</div>
														)}
														{(idUsuarioActivo===p.userTypeId) && (
														<div class="badge badge-soft-success me-2">Activo</div>
														)}
													</div>
												</div>
											</a>)
										})}
									</div>
								</div>
				</li>
			)}
            <li className="nav-item dropdown">
              <a
                className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                href="#"
                data-bs-toggle="dropdown" aria-expanded="false"
				onClick={this.profileMenuToggle}>   
				
				<svg  id="menuMobile" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user align-middle me-2">
				<path  id="menuMobile" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
				<circle  id="menuMobile" cx="12" cy="7" r="4"></circle></svg>
				
				</a>
              <a
			  	id="menuWeb"
                className="nav-link dropdown-toggle d-none d-sm-inline-block"
                data-bs-toggle="dropdown"
                onClick={this.profileMenuToggle}
              >
                <img
                  src={(user && user.urlImage)?`${user.urlImage}`:userProfile}
                  className="avatar img-fluid rounded-circle me-1"
                  style={{
                    objectFit: "cover",
                  }}
                  alt="User Profile"
                />
                <span className="text-dark">{(user)?`${user.userName}`:`User`}</span>
              </a>
              <div id="profileMenu" className=" dropdown-movil-user dropdown-menu dropdown-menu-end">
			  <Link to="/UserProfile" className="dropdown-item">
				<FontAwesomeIcon icon={faUserCircle} /> Perfil
			</Link>
			<div class="dropdown-divider"></div>
                <a className="dropdown-item" onClick={this.cerrarSession}>
				<i class="far fa-times-circle"></i> Cerrar sesión
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Header;
