import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Loading from "../Loading";
import types from "../../services/types";

function EmployeeForm({ employee, onClose }) {
    const [formData, setFormData] = useState({
        employeeId: 0,
        identification: "",
        idType: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        isActive: true,
        profilePicture: null,
        showImageProfile: null,
        signatureImage: null,
        showSignatureImage: null,
    });
    const [loading, setLoading] = useState(false);
    const [identificationTypes, setIdentificationTypes] = useState([]);

   

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

   

    return (
        <div>
            {loading ? <Loading /> : (
                <form >
                    <div className="form-group">
                        <label>Identification Number</label>
                        <input type="text" className="form-control" name="identification" value={formData.identification} onChange={handleInputChange} required />
                    </div>
                    <div className="form-group">
                        <label>Identification Type</label>
                        <select className="form-control" name="idType" value={formData.idType} onChange={handleInputChange} required>
                            {identificationTypes.map(type => (
                                <option key={type.id} value={type.id}>{type.description}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>First Name</label>
                        <input type="text" className="form-control" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" className="form-control" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" name="email" value={formData.email} onChange={handleInputChange} required />
                    </div>
                    <div className="form-group">
                        <label>Phone</label>
                        <input type="tel" className="form-control" name="phone" value={formData.phone} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label>Address</label>
                        <input type="text" className="form-control" name="address" value={formData.address} onChange={handleInputChange} />
                    </div>
                    <div className="form-group form-check">
                        <input type="checkbox" className="form-check-input" name="isActive" checked={formData.isActive} onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })} />
                        <label className="form-check-label">Active</label>
                    </div>
                    <button type="submit" className="btn btn-primary">Save Employee</button>
                </form>
            )}
        </div>
    );
}

export default EmployeeForm;
