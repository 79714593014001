import React, { useState } from 'react';

function HorarioDocente() {
  // Estado inicial de las asignaturas y los días
  const [subjects, setSubjects] = useState([
    { id: 1, name: "Matemáticas", duration: 2 },
    { id: 2, name: "Ciencias", duration: 1 },
    { id: 3, name: "Historia", duration: 2 },
    { id: 4, name: "Arte", duration: 1 }
  ]);

  const initialDays = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: []
  };

  // Estado para cada día de la semana
  const [weekSchedule, setWeekSchedule] = useState(initialDays);

  // Función para manejar el inicio del arrastre
  const handleDragStart = (event, subject) => {
    event.dataTransfer.setData("subject", JSON.stringify(subject));
  };

  // Función para manejar la caída de los elementos en los días
  const handleDrop = (day) => (event) => {
    event.preventDefault();
    const subject = JSON.parse(event.dataTransfer.getData("subject"));
    setWeekSchedule(prev => ({
      ...prev,
      [day]: [...prev[day], subject]
    }));
  };

  // Función para manejar el arrastre sobre un área droppable
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div className="container">
      <div className="box all-subjects columna-dia">
        <h3>Asignaturas</h3>
        <div className="subjects contenedor-clases ">
          {subjects.map(subject => (
            <div
              key={subject.id}
              draggable
              onDragStart={(e) => handleDragStart(e, subject)}
              className="subject tarjeta-clase"
            >
              {subject.name} - {subject.duration} hora(s)
            </div>
          ))}
        </div>
      </div>
      {Object.keys(weekSchedule).map((day) => (
        <div className="box columna-dia" key={day} onDrop={handleDrop(day)} onDragOver={handleDragOver}>
          <h3 className='titulo-dia'>{day}</h3>
          {weekSchedule[day].map((subj, index) => (
            <div key={index} className="scheduledSubject tarjeta-clase">{subj.name} - {subj.duration} hora(s)</div>
          ))}
        </div>
      ))}

      <style jsx>{`
        .container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .box {
          width: 30%;
          min-height: 220px;
          border: 1px solid #ccc;
          padding: 10px;
          margin: 2px 5px;
          background-color: #ffffff;  // Blanco limpio para fondo
          box-shadow: 0 4px 8px rgba(0,0,0,0.1);
          border-radius: 10px; // Bordes redondeados
        }
        .subjects{
          background-color: #fff; // Azul muy suave

        }
        .subject{
          margin: 5px;
          background-color: #f4f4f4; // Azul muy suave
          cursor: pointer;
          border-radius: 8px; // Bordes redondeados
          box-shadow: 0 2px 4px rgba(0,0,0,0.1); // Sombra ligera
        }
        .scheduledSubject {
          margin: 5px;
          padding: 10px;
          background-color: #f4f4f4;
          border-radius: 8px; // Bordes redondeados
          box-shadow: 0 2px 4px rgba(0,0,0,0.1); // Sombra ligera
        }
        h3 {
          text-align: center;
          color: #2c3e50; // Azul oscuro para los títulos
        }
      `}</style>
    </div>
  );
}

export default HorarioDocente;
