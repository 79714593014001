import React, { useEffect, useState } from 'react';
import CategoriasIncidencias from './categoriasDeIncidencias.js'; // Importa la vista de categorías
import TiposIncidencias from './TiposIncidencias.js'; // Importa la vista de tipos de incidencias
import Incidencias from './incidencias.js'; // Importa la vista de incidencias
import utils from "../utils/generalFunctions";


const GestionIncidencias = () => {
    const [activeTab, setActiveTab] = useState("tab-1");
    const [showConfiView, setShowConfiView] = useState(false);

    const validarPermisos = ()=> {
        const user = utils.getUserInformation();
        if(user && user.userTypeId<=4){
            setShowConfiView(true);
        }
    }

    useEffect(() => {
        validarPermisos();
    }, []);

    return (
        <div className="col-12 col-lg-12">
            {showConfiView && (
                <div className="tab">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" role="presentation" >
                            <a 
                                className={`nav-link ${activeTab === "tab-1" ? "active" : ""}`}
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected={activeTab === "tab-1"}
                                onClick={() => setActiveTab("tab-1")}
                            >
                                Incidencias
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className={`nav-link ${activeTab === "tab-2" ? "active" : ""}`}
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected={activeTab === "tab-2"}
                                onClick={() => setActiveTab("tab-2")}
                            >
                                Tipos de Incidencias
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className={`nav-link ${activeTab === "tab-3" ? "active" : ""}`}
                                data-bs-toggle="tab"
                                role="tab"
                                aria-selected={activeTab === "tab-3"}
                                onClick={() => setActiveTab("tab-3")}
                            >
                                Categorías de Incidencias
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className={`tab-pane ${activeTab === "tab-1" ? "active show" : ""}`} id="tab-1" role="tabpanel">
                            <Incidencias />
                        </div>
                        <div className={`tab-pane ${activeTab === "tab-2" ? "active show" : ""}`} id="tab-2" role="tabpanel">
                            <TiposIncidencias />
                        </div>
                        <div className={`tab-pane ${activeTab === "tab-3" ? "active show" : ""}`} id="tab-3" role="tabpanel">
                            <CategoriasIncidencias />
                        </div>
                    </div>
                </div>
            )}
            {!showConfiView && (
                <Incidencias />
            )}
        </div>
    );
};

export default GestionIncidencias;
