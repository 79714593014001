import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import LogroForm from "../components/Forms/LogroForm.js";  // Asegúrate de crear este componente
import Swal from "sweetalert2";
import logroService from "../services/logrosServices.js"; // Asegúrate de tener este servicio
import useCustomDataTableStyles from "../components/estilosDeDataTable"; // Asegúrate de tener esta función
import types from '../services/types.js';

const LogrosEstudiantes = () => {

    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [logros, setLogros] = useState([]);
    const [selectedLogro, setSelectedLogro] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [BtnVer, setBtnVer] = useState('');
    const [evaluationTypeId, setEvaluationTypeId]= useState(types.evaluationTypes.ESCALA_VALORATIVA); 
    const [cmbEvaluationTypeId, setCmbEvaluationTypeId]= useState(types.evaluationTypes.ESCALA_VALORATIVA); 

    const toggleShow = () => {
        setFullscreenXlModal(!fullscreenXlModal);
        setSelectedLogro(null); 
    }
    
    const openAddingModal = async (logro) => {
        setSelectedLogro(logro);
        setFullscreenXlModal(true);
        setBtnVer(false);
    };

    const deleteLogroById = async(logro) => {
        Swal.fire({
            title: '¿Está seguro que desea eliminar este logro?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
                try{
                    await logroService.deleteLogroById(logro.achievementId);  
                    Swal.fire(
                        '¡Felicidades!',
                        'Logro eliminado exitosamente!',
                        'success'
                    );
                    fetchLogros();
                }catch(error){
                    Swal.fire(
                        "Ha ocurrido un error",
                        "Por favor intente más tarde",
                        "error"
                    );
                }
            }
        })
    }

    const fetchLogros = async () => {
        try {
            const response = await logroService.getLogros();
            const filtredData = response.filter(r=>r.evaluationTypeId===Number(cmbEvaluationTypeId));
            setLogros(filtredData);
            setEvaluationTypeId(cmbEvaluationTypeId);
        } catch (error) {
            console.error("Error al obtener los logros:", error);
        }
    };

    useEffect(() => {
        fetchLogros();
    }, []);

    const columns = [
        { 
            name: "N°", 
            selector: row => row.number, 
            sortable: true,
            grow:"0.5", 
            cell: (row, index) => <span>{index + 1}</span>, 
        },
        {
            name: 'Asignatura',
            selector: row => row.schoolSubjectName, 
            sortable: true,
            grow:"1",
        },
        {
            name: 'Grado',
            selector: row => row.gradeName, 
            sortable: true,
        },
        {
            name: 'Descripción',
            grow:"6",
            selector: row => row.description, 
            sortable: true,
        },
        {
            name: 'Desempeño',
            omit: (Number(evaluationTypeId)!==2),
            selector: row => row.evaluativeScaleName, 
            sortable: true,
        },
        {
            name: 'Proceso Evaluativo',
            omit:(Number(evaluationTypeId)!==1),
            selector: row => row.evaluationProcessName, 
            sortable: true,
        },
        {
            name: 'Año',
            selector: row => row.schoolYearName, 
            sortable: true,
        },
        {
            name: 'Periodo',
            selector: row => row.periodName, 
            sortable: true,
        },
        {
            name: 'Acciones',
            grow:"0.8",
            cell: (row) => (
                <div className="actions-container justify-content-between align-items-center">
                    <button
                        onClick={() => {openEditModal(row)}}
                        className="btn-edit btn-sm me-2"
                        title="Editar"
                    >
                        <i className="fas fa-edit"></i>
                    </button>
                    <button
                        onClick={() => deleteLogroById(row)}
                        className="btn-deactivate btn-sm"
                        title="Eliminar"
                    >
                        <i className="icon-deactivate fas fa-times"></i>
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const normalizeString = (str) => {
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      };

    const filteredItems = logros?.filter((item) => {
        if (item) {
          const allData = `${item.schoolSubjectName} ${item.gradeName} ${item.description} ${item.evaluativeScaleName} ${item.schoolYearName} ${item.periodName}`;
          const normalizedFilterText = normalizeString(filterText);
          const normalizedItemName = normalizeString(allData);
          return normalizedItemName.includes(normalizedFilterText);
        }
        return item;
      });

    const openEditModal = async (logro) => {
        setSelectedLogro(logro);
        setFullscreenXlModal(true);
        setBtnVer(true);
    };

    const customStyles = useCustomDataTableStyles();


    return (
        <div className="student-table-container p-3">
            <h1>Gestión de Logros</h1>
            <p>Esta es la vista para gestionar los logros de los estudiantes.</p>
            <div className="row">

                <div className="col-md-4 mb-2">
                        <input
                                type="text"
                                placeholder="Buscar..."
                                onChange={(e) => setFilterText(e.target.value)}
                                className="form-control form-control-sm margin-top-45"
                            />
                </div>
                    
                <div className="col-md-3 mb-2 margin-top-45">
                            <button className="btn-sm btn-Estudiante btn-NuevoEstudiante"  onClick={() => openAddingModal(null)} >Crear Nuevo Logro <i className="fa-solid fa-circle-plus"></i></button>
                </div>
                <div className="col-md-2 "></div>
                <div className="col-md-2 mb-2">
                    <label htmlFor="ccevaluationTypeId" className="form-label">Tipo de evaluación:</label>
                    <select className="form-control form-select" id="ccevaluationTypeId" name="ccevaluationTypeId" 
                        value={cmbEvaluationTypeId} onChange={(e)=>{ setCmbEvaluationTypeId(e.target.value)}}>
                        <option value="1" >Dimensión valorativa</option>
                        <option value="2" >Escala valorativa</option>
                    </select>
              </div>
              <div className="col-md-1 mb-2 margin-top-45">
                      <button className="btn btn-NuevoEstudiante"  onClick={() => fetchLogros(null)} >Buscar</button>
              </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredItems}
                noDataComponent="No se encontraron logros que mostrar, por favor añadelos" 
                className="students-datatable shadow-sm rounded"
                customStyles={customStyles}
                pagination
                fixedHeader
                paginationRowsPerPageOptions={[50, 100]}
            />
 <>
            <MDBModal show={fullscreenXlModal} setShow={setFullscreenXlModal} tabIndex='-1'>
                <MDBModalDialog size='xl' fullscreen='xl-down'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{selectedLogro ? "Editar Logro" : "Agregar Logro"}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            {selectedLogro && (
                                <LogroForm 
                                    logro={selectedLogro} 
                                    onClose={()=>{
                                        toggleShow();
                                        fetchLogros();
                                    }} 
                                />
                            )}

                            {!selectedLogro && (
                                <LogroForm 
                                    logro={null} 
                                    onClose={()=>{
                                        toggleShow();
                                        fetchLogros();
                                    }} 
                                />
                            )}
                        </MDBModalBody>
                        <MDBModalFooter>
                        <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                    Cerrar
                        </button>                            
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            </>
        </div>
    );
};

export default LogrosEstudiantes;
