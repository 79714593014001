
import { useEffect, useRef, useState } from "react";
import HeaderActa from "../../../components/headerActas";
import FooterActa from "../../../components/FooterActa";
import moment from "moment";
import utils from "../../../utils/generalFunctions";
import types from "../../../services/types";


const RepoPreventivoMasivo = ({ information }) => {
    const filteReference = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const [process, setProcess] = useState([]);

   
    return (
        <>
          {!showLoading && information && (

            <>

                <div class="card acta-container">
                
                    {/* <!-- Cabezera --> */}
                    <div className="card-body">
                    <HeaderActa />
                    {/* <!-- Ficha de Estudiante --> */}
                        <div class="contenedor-informes">
                            {information.filter(r=> r.schoolSubjects.
                                                    filter(s=>s.evaluationProcess.
                                                            filter(e=>e.evaluativeScale).length > 0).length > 0)
                            .map((reporte, index) => (
                                            <div class="mini-info-preventivo">
                                                <div class="caja-encabezado">
                                                <h3 class="titulo-colegio">COLEGIO JERUSALEM</h3>
                                                <p class="tipo-reporte">Reporte de Corte Preventivo</p>
                                                </div>
                                                <div class="caja-info-estudiante">
                                                <p class="nombre-estudiante">{reporte.student.studentFullName}</p>
                                                <p class="detalle-periodo">{reporte.student.grade} -  {reporte.student.periodName} </p>
                                                </div>
                                                <table class="tabla-mini-info-preventivo">
                                                    <tbody>
                                                    {reporte.schoolSubjects.
                                                    filter(s=>s.evaluationProcess.
                                                            filter(e=>e.evaluativeScale).length > 0)
                                                    .map((subject, index) => (
                                                        <tr>
                                                            <td>{subject.schoolSubjectName}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                                <div class="caja-info-estudiante">
                                                    <div >
                                                    <p class="detalle-periodo">Firma</p>
                                                    <br />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                
                        </div> 
                         <FooterActa 
                            reportTypeId={types.reportTypes.ACTA}></FooterActa> 
                    </div>
                </div>

                {/* <FooterActa></FooterActa> */}

                <br />
                    <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
                <br />

            </>
          )}
        </>
    );
  };
  
  export default RepoPreventivoMasivo;