import api from './api';

const getAsistenciasAcademicos = async () => {
    try {
        const response = await api.get(`disciplinary/attendance`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAsistenciasToNotas = async (courseId, schoolSubjectId, schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`disciplinary/attendance-to-course?courseId=${courseId}&schoolSubjectId=${schoolSubjectId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAsistenciasTotalReport = async (schoolYearId, academicPeriodId, date) => {
    try {
        const response = await api.get(`disciplinary/attendance-report-total?schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}&date=${date}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAsistenciasTotalReportCourse = async (schoolYearId, academicPeriodId, date, courseId) => {
    try {
        const response = await api.get(`disciplinary/attendance-report-total-course?schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}&date=${date}&courseId=${courseId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAsistenciasTotalReportStudent = async (schoolYearId, academicPeriodId, date, courseId, studentId) => {
    try {
        const response = await api.get(`disciplinary/attendance-report-total-student?schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}&date=${date}&courseId=${courseId}&studentId=${studentId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAsistenciasTotalReportSubject = async (schoolYearId, academicPeriodId, date, courseId, schoolSubjectId) => {
    try {
        const response = await api.get(`disciplinary/attendance-report-total-subject?schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}&date=${date}&courseId=${courseId}&schoolSubjectId=${schoolSubjectId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
//

const getAsistenciasToLogros = async (courseId, schoolSubjectId, schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`disciplinary/attendance-to-grade?gradeId=${courseId}&schoolSubjectId=${schoolSubjectId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createAsistenciaAcademico = async (asistencia) => {
    try {
        const response = await api.post(`disciplinary/attendance`, asistencia);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateAsistenciaAcademico = async (asistencia) => {
    try {
        const response = await api.put(`disciplinary/attendance`, asistencia);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAsistenciaAcademico = async (id) => {
    try {
        const response = await api.get(`disciplinary/attendance/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteAsistenciasAcademicoById = async (id) => {
    try {
        const response = await api.delete(`disciplinary/attendance/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getAsistenciasAcademicos,
    createAsistenciaAcademico,
    updateAsistenciaAcademico,
    getAsistenciaAcademico,
    deleteAsistenciasAcademicoById,
    getAsistenciasToNotas,
    getAsistenciasToLogros,
    getAsistenciasTotalReport,
    getAsistenciasTotalReportCourse,
    getAsistenciasTotalReportSubject,
    getAsistenciasTotalReportStudent
};