const useCustomLightDataTableStyles = (additionalStyles = {}) => {
  const defaultStyles = {
    rows: {
      style: {
        minHeight: "25px",
        backgroundColor: "var(--bs-light-bg-subtle)", // Fondo claro para las filas
        color: "var(--bs-dark-text-emphasis)", // Texto oscuro para contraste
        "&:hover": {
          backgroundColor: "var(--bs-primary-bg-subtle)", // Fondo al pasar el mouse
        },
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--bs-light)", // Fondo claro para las celdas del encabezado
        color: "var(--bs-secondary)", // Texto oscuro para el encabezado
        fontSize: "var(--bs-body-font-size)",
        fontWeight: "700", // Negrita para el texto del encabezado
        textTransform: "uppercase",
        letterSpacing: "0.03em",
        border: "none",
      },
    },
    cells: {
      style: {
        color: "var(--bs-heading-color)", // Texto oscuro para la paginación
        fontSize: "var(--bs-body-font-size)",
        fontWeight: "var(--bs-body-font-weight)",
        padding: "10px 5px",
        border: "none",
        borderBottom: "1px solid var(--bs-light-border-subtle)", // Borde sutil para las celdas
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "var(--bs-primary-bg-subtle)", // Fondo al pasar el mouse
        },
      },
    },
    pagination: {
      style: {
        backgroundColor: "var(--bs-light)", // Fondo claro para la paginación
        color: "var(--bs-dark)", // Texto oscuro para la paginación
        borderTop: "1px solid var(--bs-light-border-subtle)", // Borde superior sutil
        fontSize: "var(--bs-body-font-size)",
        padding: "8px",
        minHeight: "56px",
        
      },
    },
    noData: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        color: "var(--bs-gray-500)", // Color gris para texto sin datos
        fontSize: "16px",
      },
    },
    // Añade aquí otros estilos por defecto si es necesario
  };

  // Fusiona los estilos por defecto con los adicionales proporcionados
  return { ...defaultStyles, ...additionalStyles };
};

export default useCustomLightDataTableStyles;
