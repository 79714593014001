import api from './api';

const validateLogin = async (username,password) => {
    try {
        const response = await api.post(`/auth/sign-in`,{username,password});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    validateLogin,
};