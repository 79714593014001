import React, { useEffect, useState } from 'react';
import academicService from '../../services/academicService';
import periodoService from '../../services/periodoService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loading from '../Loading';

const SchoolYearForm = (props) => {
    const { yearItem, onClose, ReadOnly } = props; 
    const initData ={
        schoolYearId:0,
        year: "",
        journeyId: "",
        isActive: false,
        periods: [],
        periodoId:""
    };
    const [formData, setFormData] = useState(initData);
    const [journeyList, setJourneyList] = useState([]);
    const [periodosList, setPeriodosList] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [showLoading, setShowLoading] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === "journeyId"){
            LoadPeriods(document.getElementById("cmbJourney").value);
        }
    };

    useEffect(() => {
        const fetchCollections = async () => {
          try {
            const journeyResp = await academicService.getJourneys();
            setJourneyList(journeyResp);
            const periodosResponse = await periodoService.getPeriodosAcademicos();
            setPeriodos(periodosResponse);
            if (yearItem) {
              const resYear = await academicService.getYearById(yearItem);              
              setFormData((prevState) => ({ ...prevState, ...resYear }));
              const periodosFiltrados = periodosResponse.filter((p) =>
               p.journeyId === resYear.journeyId &&
               resYear.periods.every(obj => {
                return obj.academicPeriodId !== p.academicPeriodId
              }));
              setPeriodosList(periodosFiltrados);
            }
            setShowLoading(false);
          } catch (error) {
            console.error("Error al obtener listas de formulario:", error);
          }
        };
    
        fetchCollections();
      }, [yearItem]);

    const LoadPeriods = (journeyId) => {
        const periodosFiltrados = periodos.filter((p) =>
        p.journeyId === Number(journeyId) &&
        formData.periods.every(obj => {
         return obj.academicPeriodId !== p.academicPeriodId
       }));
       setPeriodosList(periodosFiltrados);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        const requiredFields = ['year', 'journeyId'];
        for (let field of requiredFields) {
            if (!formData[field]) {
                toast.error("Por favor, completa todos los campos obligatorios.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }

        // Aquí podrías agregar otras validaciones si es necesario
        if(!ReadOnly)
        {
            setShowLoading(true);
            try {
                let yearId = 0;
                if (formData.schoolYearId > 0) {
                const response = await academicService.updateYear(formData);
                yearId = response.schoolYearId;
                } else{
                    const response = await academicService.createYear(formData);
                    yearId = response.schoolYearId;
                }
                if (yearId) {
                    Swal.fire(
                        "Felicidades!",
                        "Información guardada con éxito!",
                        "success",
                        );
                    if (onClose) onClose();
                    setFormData(initData);
                } else {
                    Swal.fire(
                        "Ha ocurrido un error",
                        "Por favor intentelo mas tarde",
                        "error"
                    );
                }
            } catch (error) {
                console.error('Error al enviar el formulario:', error);
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intentelo mas tarde",
                    "error"
                );
            }
            setShowLoading(false);
        }
    };

    const handlePeriodsDateChange = async (periodo, e)=>{
        const { name, value } = e.target;
        formData.periods.map((p)=>{
            if(p.academicPeriodId===periodo.academicPeriodId)p[name]=value;
            return p;
        });
        setFormData(prevState => ({
            ...prevState
        }));
    }

    const removePeriodo = (periodo) => {
        const newPeriodos = formData.periods.filter((p) =>p.academicPeriodId !== periodo.academicPeriodId);
        const journeyId = document.getElementById("cmbJourney").value;
        const periodosFiltrados = periodos.filter((p) =>
        p.journeyId === Number(journeyId) &&
        newPeriodos.every(obj => { return obj.academicPeriodId !== p.academicPeriodId }));
       setFormData(prevState => ({
        ...prevState,
        periods: newPeriodos
        }));
        setPeriodosList(periodosFiltrados);
    };

    const addPeriodo = () =>{
        const periodId = formData.periodoId;
        const periodo = periodos.filter((p) =>p.academicPeriodId === Number(periodId));
        if(periodo && periodo.length>0 && periodo[0]){
            periodo[0]={...periodo[0],periodName:periodo[0].name};
            const newPeriodos = [...formData.periods, ...periodo];
            const journeyId = document.getElementById("cmbJourney").value;
            const periodosFiltrados = periodos.filter((p) =>
            p.journeyId === Number(journeyId) &&
            newPeriodos.every(obj => { return obj.academicPeriodId !== p.academicPeriodId }));
            setFormData(prevState => ({
                ...prevState,
                periods: newPeriodos,
                periodoId:""
                }));
            setPeriodosList(periodosFiltrados);
        }else{
            toast.error("Por favor, selecciona un periodo para añadir", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              return;
        }
    }

    return (
        <>
        {showLoading && <Loading />}

        {!showLoading && (
            <div className="container-fluid mt-4">
            <p className="text-muted">Por favor, llena todos los campos para registrar un nuevo año escolar.</p>
            
            <form onSubmit={handleSubmit} className="mt-3 ">
            <div className="row">

            <div className="col-md-6 mb-3">
                <label htmlFor="year" className="form-label">Año</label>
                <input type="text" className="form-control" id="year" name="year" placeholder="Ejemplo: 2023-2024" readOnly={ReadOnly} value={formData.year} onChange={handleChange} required />

                <div className="col-md-6 mb-3 padding-top-20 padding-left-10">
                    <input type="checkbox" className="form-check-input" id="isActive" name="isActive" checked={formData.isActive} onChange={e => setFormData({...formData, isActive: e.target.checked})}  disabled={ReadOnly} />
                    <label className="form-check-label" htmlFor="isActive">Activo</label>
                </div>
            </div>

            <div className="col-md-6 mb-3">
                <label htmlFor="journeyId" className="form-label">Jornada</label>
                <select
                        id="cmbJourney"
                        className="form-control form-select validarForm"
                        name="journeyId"
                        value={formData.journeyId}
                        onChange={handleChange}
                        readOnly={ReadOnly}
                        required
                    >
                        <option value="" disabled={ReadOnly}>
                        Seleccione
                        </option>
                        {journeyList.map((option) => (
                        <option
                            key={option.journeyId}
                            value={option.journeyId}
                            disabled={ReadOnly}
                        >
                            {option.name}
                        </option>
                        ))}
                </select>
            </div>
            {!ReadOnly  && (
            <div className="col-md-6 mb-3 row">
                <div className='col-md-4'>
                    <label htmlFor="periodoId" className="form-label">Periodos:</label>
                    <select
                            id="cmbPeriodos"
                            className="form-control form-select validarForm"
                            name="periodoId"
                            onChange={handleChange}
                            readOnly={ReadOnly}
                        >
                            <option value="" disabled={ReadOnly}>
                            Seleccione
                            </option>
                            {periodosList.map((option) => (
                            <option
                                key={option.academicPeriodId}
                                value={option.academicPeriodId}
                                disabled={ReadOnly}>
                                {option.name}
                            </option>
                            ))}
                    </select>
                </div>
                <div className='col-md-4 padding-top-50'>
                        <button type='button' className='btn btn-success' onClick={addPeriodo}>
                            Añadir <i class="fa-sharp fa-solid fa-plus" ></i>
                        </button>
                </div>
            </div>
            )}
            <div className="row mb-3">
                    {formData.periods.map((periodo,index) => (
                        <>
                        <div className="col-md-3 mb-3">
                            {index===0  && (
                                <label className="form-label">Periodo:</label>
                            )}
                            <input type="text" className="form-control" id={"period-"+periodo.academicPeriodId}  name={"period-"+periodo.academicPeriodId}  
                                    readOnly={true} value={periodo.periodName}  required />
                        </div>
                        <div className="col-md-3 mb-3">
                            {index===0  && (
                                <label className="form-label">
                                    Fecha de inicio*
                                </label>
                            )}
                            <input
                                type="date"
                                className="form-control validarForm"
                                name="startDate"
                                value={periodo.startDate}
                                onChange={(e) => handlePeriodsDateChange(periodo,e)}
                                placeholder="dd/mm/aaaa"
                                readOnly={ReadOnly}
                                required
                            />
                        </div>
                        <div className="col-md-3 mb-3">
                            {index===0  && (
                                <label className="form-label">
                                    Fecha de cierre*
                                </label>
                            )}
                            <input
                                type="date"
                                className="form-control validarForm"
                                name="endDate"
                                value={periodo.endDate}
                                onChange={(e) => handlePeriodsDateChange(periodo,e)}
                                placeholder="dd/mm/aaaa"
                                readOnly={ReadOnly}
                                required
                            />
                        </div>
                        <div className={"col-md-3 mb-3 "+((index===0)?"padding-top-50":"padding-top-10")}>
                            <a className='btn btn-danger btn-round' onClick={() => removePeriodo(periodo)}> 
                                <i className="icon-deactivate fas fa-times" aria-hidden="true"></i>
                            </a>
                        </div>
                        </>
                    ))}
            </div>
        </div>
                    
                {!ReadOnly  && (
                    <div className='row'>
                        <div className='col-md-1 center padding-top-20 padding-bottom-20'>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                )}
            </form>
            </div>
        )}
        </>
    );
}

export default SchoolYearForm;
