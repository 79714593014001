import { useState } from "react";
import RepoAsistenciaTotal from "./repo-total-asistencia";
import { toast } from 'react-toastify';
import RepoAsistenciaTotalCurso from "./repo-total-asistencia-curso";
import RepoAsistenciaTotalAsig from "./repo-total-asistencia-asig";
import RepoAsistenciaTotalEstudiante from "./repo-total-asistencia-estudiante";

const DashboardInasistenciasRepo = () => {
    const [reporteSelected, setReporteSelected] = useState(null); 

    const volverLista =  async () => {
        setReporteSelected(null);
    };

    const showMensajeInfo = () => {
        toast.info("Este reporte se habilitara proximamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
    }
      
    return (
        <>
        {reporteSelected===null && (
          <div className="student-table-container p-3 filter-container">
            <h1>Reportes de Asistencias</h1>
            <p>Selecciona el reporte que quieres visualizar.</p>
            
                <div className="row">
                    <div className="col-md-6 col-xxl-3 d-flex pointer" onClick={()=>{ setReporteSelected(5); }}>
                        <div class="card illustration flex-fill">
                            <div class="card-body p-0 d-flex flex-fill">
                                <div class="row g-0 w-100">
                                    <div class="col-6">
                                        <div class="illustration-text p-3 m-1">
                                        <h4 class="illustration-text">Consolidado total asistencias</h4>
                                            <p class="mb-0">por estudiante</p>
                                        </div>
                                    </div>
                                    <div class="col-6 align-self-end text-end">
                                    <i className="fas fa-user-graduate font-size-80"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xxl-3 d-flex pointer" onClick={()=>{ setReporteSelected(2); }}>
                        <div class="card illustration flex-fill">
                            <div class="card-body p-0 d-flex flex-fill">
                                <div class="row g-0 w-100">
                                    <div class="col-6">
                                        <div class="illustration-text p-3 m-1">
                                            <h4 class="illustration-text">Consolidado total asistencias</h4>
                                            <p class="mb-0">por curso</p>
                                        </div>
                                    </div>
                                    <div class="col-6 align-self-end text-end">
                                        <i class="fa-solid fa-person-shelter font-size-80"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xxl-3 d-flex pointer" onClick={()=>{ setReporteSelected(4); }}>
                        <div class="card illustration flex-fill">
                            <div class="card-body p-0 d-flex flex-fill">
                                <div class="row g-0 w-100">
                                    <div class="col-6">
                                        <div class="illustration-text p-3 m-1">
                                        <h4 class="illustration-text">Consolidado total asistencias</h4>
                                            <p class="mb-0">por asignatura</p>
                                        </div>
                                    </div>
                                    <div class="col-6 align-self-end text-end">
                                    <i className="fas fa-book font-size-80"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xxl-3 d-flex pointer" onClick={()=>{ setReporteSelected(3); }}>
                        <div class="card illustration flex-fill">
                            <div class="card-body p-0 d-flex flex-fill">
                                <div class="row g-0 w-100">
                                    <div class="col-6">
                                        <div class="illustration-text p-3 m-1">
                                            <h4 class="illustration-text">Consolidado total asistencias</h4>
                                            <p class="mb-0">Por dia</p>
                                        </div>
                                    </div>
                                    <div class="col-6 align-self-end text-end">
                                        <i class="fa-solid fa-address-book font-size-80"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
        )}

          {reporteSelected===3 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <RepoAsistenciaTotal  />
            </div>
            )}

            {reporteSelected===2 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <RepoAsistenciaTotalCurso  />
            </div>
            )}

            {reporteSelected===4 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <RepoAsistenciaTotalAsig  />
            </div>
            )}

            {reporteSelected===5 && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
                <RepoAsistenciaTotalEstudiante  />
            </div>
            )}

        </>
    );
  };
  
  export default DashboardInasistenciasRepo;