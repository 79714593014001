import React from 'react';
import { Route } from 'react-router-dom';
import Estudiantes from './views/estudiantes';
import Build from './components/Building';
import Dashboard from './views/Dashboard';
import Docentes from './views/docentes';
import NotasEstudiantes from './views/NotasEstudiantes';
import BillingConceptsView from './views/BillingConcepts';
import BillingView from './views/Billing';
import Matriculas from './views/matricula';
import ConfiguracionAcademica from './views/configuracionAcademica';
import Inscriptions from './views/inscriptions';
import DescuentosEstudiantes from './views/descuentoestudiantes';
import EstadoDeCuentas from './views/estadodecuentas';
import DashboardEstudiante from './views/view-estudiantes/DashboardEstudiante';
import InformacionBasicaEstudiante from './views/view-estudiantes/InformacionBasica';
import ObservacionesAsistencia from './views/view-estudiantes/ObservadorEstudiante';
import NotasEstudiante from './views/view-estudiantes/NotasEstudiante';
import PagosEstudiante from './views/view-estudiantes/PagosEstudiante';
import NotasEstudiantePc from './views/view-estudiantes-PC/NotasEstudiantePc';
import PerfilEstudiante from './views/view-estudiantes-PC/perfilEstudiante';
import DashboardEstudiantePc from './views/view-estudiantes-PC/DashboardEstudiantePc';
import PagosEstudiantepc from './views/view-estudiantes-PC/pagosEstudiantesPc';
import ObservacionesAsistenciaPC from './views/view-estudiantes-PC/ObservadorEstudiantePc';
import ProcesosView from './views/procesos';
import BillingMorosos from './views/BillingMorosos';
import HorarioEstudiantePc from './views/view-estudiantes-PC/horarioestudiante';
import LogrosEstudiantes from './views/LogrosEstudiantes';
import CalendarioEventos from './views/CalendarioAcademico';
import AsistenciaView from './views/asistencia';
import GestionIncidencias from './views/IncidenciasVistaGeneral';
import DashboardDocente from './views/view-docentes/DashboardDocente';
import VistaAsignacionTutores from './views/Tutores';
import DashboardInasistenciasRepo from './views/Reportes/DashInasistencia';
import UserProfile from './views/PerfilUsuario';
import PlantillaPreventivaView from './views/planillaPreventiva';
import DashboardInformesRepo from './views/Reportes/DashInformes';
import DashboardReporteEstudiantes from './views/view-estudiantes-PC/ReportesEstudiantesPC';
import DesactivarReportesEstudiantes from './views/Reportes/DesactivarReportesEstudiantes';
import ReportCard from './views/Reportes/AsignaturaPerdida/AsignaturasPerdidasCurso';
import Observador from './views/observador.js';
import NotasEstudiantesPcPreescolar from './views/view-estudiantes-PC/NotasEstudiantesPcPreescolar.js';
import HorarioDocente from './views/view-docentes/HorarioDocente.js';
import Employees from './views/Employees.js';
import BillingReport from './views/BillingReport.js';
import NewBillingMorosos from './views/BillingMorososNew.js';
import Usuarios from './views/usuarios.js';






  const routesArray = [

    
      <Route key="UserProfile" path="/UserProfile" element={<UserProfile />} />,

      <Route key="inscripciones" path="/inscripciones" element={<Inscriptions />} />,
      <Route key="dashboard" path="/" element={<Dashboard />} />,
      <Route key="dashboard" path="/dashboard" element={<Dashboard />} />,

      <Route key="DashboardDocente" path="/" element={<DashboardDocente />} />,
      <Route key="DashboardDocente" path="/dashboard" element={<DashboardDocente />} />,

      <Route key="DashboardEstudiantePc" path="/" element={<DashboardEstudiantePc />} />,
      <Route key="DashboardEstudiantePc" path="/dashboard" element={<DashboardEstudiantePc />} />,



      <Route key="students" path="/estudiantes" element={<Estudiantes />} />,
      <Route key="ConfiguracionAcademica" path="/ConfiguracionAcademica" element={<ConfiguracionAcademica/>} />,
      <Route key="teachers" path="/docentes" element={<Docentes />} />,
      <Route key="LogrosEstudiantes" path="/Logros-estudiantes" element={<LogrosEstudiantes/>} />,
      <Route key="Procesos" path="/procesos" element={<ProcesosView/>} />,
      <Route key="EvaluacionPreventiva" path="/evaluacion-preventiva" element={<PlantillaPreventivaView/>} />,
      <Route key="NotasEstudiantes" path="/NotasEstudiantes" element={<NotasEstudiantes/>} />,
      <Route key="matriculas" path="/matriculas" element={<Matriculas />} />,
      <Route key="CalendarioEventos" path="/calendario-academico" element={<CalendarioEventos />} />,
      <Route key="GestionIncidencias" path="/diciplinario/incidencias" element={<GestionIncidencias />} />,   
      <Route key="DashboardDocente" path="/dashboard-docente" element={<DashboardDocente />} />,
      <Route key="VistaAsignacionTutores" path="/dashboard-docente/asignaciontutores" element={<VistaAsignacionTutores />} />,
      <Route key="HorarioDocente" path="/dashboard-docente/HorarioAcademico" element={<HorarioDocente/>} />,


      <Route key="BillingConcepts" path="/cartera/conceptos" element={<BillingConceptsView/>} />,
      <Route key="Billing" path="/cartera/facturacion" element={<BillingView/>} />,
      <Route key="Discounts" path="/cartera/descuentos" element={<DescuentosEstudiantes/>} />,
      <Route key="EstadoCuentas" path="/cartera/estadodecuentas" element={<EstadoDeCuentas/>} />,
      <Route key="Morosos" path="/cartera/morosos" element={<NewBillingMorosos/>} />,
      <Route key="BillingReport" path="/cartera/reporte" element={<BillingReport/>} />,
      <Route key="build" path="/mantenimiento" element={<Build />} />,
      <Route key="Asistencia" path="/asistencia" element={<AsistenciaView />} />, 
      <Route key="Employees" path="/adminUsers" element={<Usuarios />} />,      
     


      <Route key="DashboardEstudiante" path="/dashboard-estudiante" element={<DashboardEstudiante />} />,
      <Route key="DatosBasicosEstudiante" path="/dashboard-estudiante/datos-basicos-estudiante" element={<InformacionBasicaEstudiante />} />,
      <Route key="ObservacionesAsistencia" path="/dashboard-estudiante/observaciones-estudiante" element={<ObservacionesAsistencia />} />,
      <Route key="CalificacionesEstudiante" path="/dashboard-estudiante/calificaciones-estudiante" element={<NotasEstudiante />} />,
      <Route key="EstadoCuentaEstudiante" path="/dashboard-estudiante/estado-cuenta-estudiante" element={<PagosEstudiante />} />,
      <Route key="ReporteEstudiantePC" path="/reportes-estudiantepc" element={<DashboardReporteEstudiantes />} />,
      <Route key="CalificacionesPreescolar" path="/dashboard-estudiante/calificaciones-estudiante-pre" element={<NotasEstudiantesPcPreescolar />} />,


      

      
      <Route key="perfil" path="/dashboard-estudiantepc/perfil" element={<PerfilEstudiante/>} />,
      <Route key="notasDelEstudiante" path="/dashboard-estudiantepc/Notas" element={<NotasEstudiantePc/>} />,
      <Route key="PagosEstudiantepc" path="/dashboard-estudiantepc/estado-de-cuenta" element={<PagosEstudiantepc/>} />,
      <Route key="ObservacionesAsistenciaPC" path="/dashboard-estudiantepc/observador-asistencia" element={<ObservacionesAsistenciaPC/>} />,
      <Route key="HorarioDeClases" path="/dashboard-estudiantepc/horario-de-clases" element={<HorarioEstudiantePc/>} />, 
      <Route key="DashInasistencias" path="/informe-inasistencias" element={<DashboardInasistenciasRepo />} />, 
      <Route key="DashInformes" path="/informes" element={<DashboardInformesRepo />} />,
      <Route key="DesactivarReportesEstudiantes" path="/desactivar-reportes" element={<DesactivarReportesEstudiantes/> } />,
      <Route key="DesactivarReportesEstudiantes" path="/Reportes/report-card" element={<ReportCard />} />,
      <Route key="ObservadorView" path="/observador" element={<Observador />} />




      

  ];
  
  export default routesArray;