import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import exportDocuments from '../exportDocuments';
import '../../css/ReporteReciboPago.css';
import logo from '../../img/Logo-Colegio.png'; // Asegúrate de que la ruta sea correcta
import pagadoStamp from '../../img/pagado.png'; // Asegúrate de que la ruta sea correcta
import anuladoStamp from '../../img/anulado.png'; // Asegúrate de que la ruta sea correcta
import utils from '../../utils/generalFunctions';
import documentService from '../../services/documentService';
import types from '../../services/types';
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ReciboPago = forwardRef((props, ref) => {
  const { recibo, onClose } = props; 
  const printRef = useRef();
  useImperativeHandle(ref, () => ({
        exportarPdf() { exportDocuments.exportPDF(printRef,`Recibo ${recibo.consecutive}`) }
  }));

const exportar = (e) =>{
    const pdfBlob = exportDocuments.exportPDF(printRef,`Recibo ${recibo.consecutive}`)

};

const enviarEmail = async (e) =>{
   const documentPdf = await exportDocuments.exportBlobPDF(printRef,`Recibo ${recibo.consecutive}`)
   await documentService.enviarEmail(types.notification.RECIBO,documentPdf, recibo.billingId);
   toast.success("Email enviado con exito!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

  return (
    <div>
      <div ref={printRef}>
            {recibo && (
                <div className='body'>
                    <div class="recibo-container">
                        <div class="recibo-header">
                        <LazyLoadImage
                                    src={logo}
                                    alt="Logo del Colegio"
                                    className="logo"
                                    effect="blur"
                                    />
                            <div className="recibo-header-info">
                            <h2><strong >COLEGIO JERUSALEM</strong></h2>
                            <p>NIT 800 197 762 - 1<br />
                                    LICENCIA DE FUNCIONAMIENTO No. 001205 DE NOVIEMBRE DE 2005<br />
                                DIRECCIÓN: CRA 50 No. 76-185 PBX 3684319 <br /> 
                                    Barranquilla-Colombia
                            </p>
                        
                            {recibo.billingStatusName==='Anulado' && (
                                <LazyLoadImage
                                src={anuladoStamp}
                                alt="Anulado"
                                className="anulado"
                                effect="blur"
                              />
                            )}
                            {recibo.billingStatusName!=='Anulado' && (
                                <LazyLoadImage
                                src={pagadoStamp}
                                alt="Pagado"
                                className="pagado"
                                effect="blur"
                                
                              />
                            )}
                            </div>
                        </div>
                        <div class="recibo-info">
                            <strong >CONSTANCIA DE PAGO</strong>
                        </div>
                        <div class="recibo-body">
                            <table>
                                <tr>
                                    <td >Volante:</td>
                                    <th >{recibo.consecutive}</th>
                                    <td>Grado:</td>
                                    <th colspan="2">{recibo.grade}</th>
                                    <td>Año:</td>
                                    <th colspan="2">{recibo.year}</th>
                                </tr>
                                <tr>
                                    <td>Estudiante:</td>
                                    <th colspan="6">{recibo.studentName}</th>
                                </tr>
                                <tr>
                                    <th colspan="6">LISTA DE CONCEPTOS</th>
                                    <th>VALOR</th>
                                </tr>
                                {recibo.details.map((detail) => (
                                    <tr>
                                        <td colspan="6">{detail.isParcial?'ABONO: ':''} {detail.billingConceptName}</td>
                                        <td>{utils.formatNumber.new(detail.total, "$")}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <th colspan="6">VALOR A CANCELAR</th>
                                    <th >{utils.formatNumber.new(recibo.total, "$")}</th>
                                </tr>
                                <tr>
                                    <th>Fecha pago:</th>
                                    <td>{recibo.billingDate}</td>
                                </tr>
                                <tr>
                                    <th>Forma de pago:</th>
                                    <td>{recibo.paymentTypeName}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            )}
      </div>
      <br />
      <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
      <br />
      {/* <button className="btn btn-primary center display-block" onClick={enviarEmail}>Enviar email</button> */}
    </div>
  );
});

export default ReciboPago;