import React, { useState, useEffect, useRef } from "react";
import "../css/CategoryCard.css";
import logroNoteService from "../services/logroNoteService";

const TablaNotasDimensiones = (props) => {
  const { students, process, evaluationScale, tablaNotes, paramPlanilla, refreshNotas } = props;
  const [estudiantes, setEstudiantes] = useState([]); // Almacenamos los estudiantes aquí
  const [procesos, setProcesos] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [scale, setScale] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [notasTabla, setNotasTabla] = useState([]); // Almacenamos los procesos y sus notas aquí
  const [parametros, setParametros] = useState([]); // Almacenamos los procesos y sus notas aquí

  useEffect(() => {
    const loadInformation = () => {
        setEstudiantes(students);
        setProcesos(process);
        setScale(evaluationScale);
        setNotasTabla(tablaNotes);
        setParametros(paramPlanilla);
    };
    loadInformation();
  }, []);

  const handleNoteChange = async (proceso,logro,studentId,valor,element) => {
    element.dataset.change = true;
    const e = element.parentNode.parentNode.parentNode;
    const achievementEvaluateId =  notasTabla[`${studentId}-${proceso}-${logro}`].achievementEvaluateId;
    try {
        const evaluation ={
            achievementEvaluateId:achievementEvaluateId,
            studentId: studentId,
            teacherId: parametros.teacherId,
            schoolSubjectId: parametros.schoolSubjectId,
            evaluationProcessId: proceso,
            achievementId: logro,
            evaluativeScaleId: valor,
            schoolYearId:parametros.schoolYearId,
            periodId:parametros.periodId
        };
        const result = await logroNoteService.updateLogroNotaAcademico(evaluation);
        if(result && result.achievementEvaluateId>0){
            e.style.backgroundColor ="#fff299";
            setNotasTabla((prevState) => ({
                ...prevState,
                [`${studentId}-${proceso}-${logro}`]:{
                      evaluativeScaleId:valor,
                      achievementEvaluateId: achievementEvaluateId
                  }
              }));
            if(refreshNotas)refreshNotas();
        }else{
            e.style.backgroundColor = "#ffc7c5";
        }
      } catch (ex) {
        e.style.backgroundColor = "#ffc7c5";
        console.log(ex);
      }
  };

  const guardarLogroNota = async (estudiante, proceso, logro, e) => {
    const defaultScale = evaluationScale.find(e=>e.abbreviation==="LA");
    const asignedScale = (defaultScale)?defaultScale:evaluationScale[0];
    try {
        const newEvaluation ={
            studentId: estudiante.studentId,
            teacherId: parametros.teacherId,
            schoolSubjectId: parametros.schoolSubjectId,
            evaluationProcessId: proceso.evaluationProcessId,
            achievementId: logro.achievementId,
            evaluativeScaleId: asignedScale.evaluativeScaleId,
            schoolYearId:parametros.schoolYearId,
            periodId:parametros.periodId
        };
        const result = await logroNoteService.createLogroNotaAcademico(newEvaluation);
        if(result && result.achievementEvaluateId>0){
            e.style.backgroundColor ="#cdfdc1";
            setNotasTabla((prevState) => ({
                ...prevState,
                [`${estudiante.studentId}-${proceso.evaluationProcessId}-${logro.achievementId}`]:{
                    evaluativeScaleId:result.evaluativeScaleId,
                    achievementEvaluateId: result.achievementEvaluateId
                }
              }));
              if(refreshNotas)refreshNotas();
        }else{
            e.style.backgroundColor = "#ffc7c5";
        }
      } catch (ex) {
        e.style.backgroundColor = "#ffc7c5";
        console.log(ex);
      }
  }

  const eliminarLogroNota = async (evaluation, estudiante, proceso, logro, e) => {
    try {
        await logroNoteService.deleteLogroNotasAcademicoById(evaluation.achievementEvaluateId);
        e.style.backgroundColor = "#fff299";
        setNotasTabla((prevState) => ({
            ...prevState,
            [`${estudiante.studentId}-${proceso.evaluationProcessId}-${logro.achievementId}`]:""
          }));
          if(refreshNotas)refreshNotas();
      } catch (ex) {
        e.style.backgroundColor = "#ffc7c5";
        console.log(ex);
      }
  }

  const handleEneableNote = async (evaluation,estudiante, proceso, logro, element ) => {
    const e = element.parentNode.parentNode.parentNode.parentNode;
    if(evaluation===""){
        await guardarLogroNota(estudiante,proceso,logro, e);
    }else{
        await eliminarLogroNota(evaluation,estudiante, proceso, logro, e);
    }
  }

  const handleEneableAllNote = async (e, proceso, logro) =>{
    const checked = e.target.checked;
    for(let estudiante of estudiantes){
        const evaluation = notasTabla[`${estudiante.studentId}-${proceso.evaluationProcessId}-${logro.achievementId}`];
        const chk = document.getElementById("chk-"+estudiante.studentId+"-"+logro.achievementId);
        const celda = chk.parentNode.parentNode.parentNode.parentNode;
        if(checked && evaluation===""){
            await guardarLogroNota(estudiante,proceso,logro, celda);
        }else{
            if(!checked && evaluation!==""){
                await eliminarLogroNota(evaluation, estudiante,proceso,logro, celda);
            }   
        }
    }
  }

  const getLogroEstudiante = (estudiante, proceso, logro) => {

    const evlatuation = notasTabla[`${estudiante.studentId}-${proceso.evaluationProcessId}-${logro.achievementId}`];
    return(
        <div className="row">
            <div className="col-md-4">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id={"chk-"+estudiante.studentId+"-"+logro.achievementId} 
                    checked={(evlatuation!=="")} 
                    onChange={(e)=> handleEneableNote(evlatuation,estudiante, proceso, logro, e.target) } />
                    <label class="form-check-label" for={"chk-"+estudiante.studentId+"-"+logro.achievementId}></label>
                </div>
            </div>
            {notasTabla[`${estudiante.studentId}-${proceso.evaluationProcessId}-${logro.achievementId}`]!=="" && (
            <div className="col-md-8">
                    <select className="form-control form-select" 
                    id={"cmbEvaluation-"+estudiante.studentId+"-"+logro.achievementId} 
                    name={"cmbEvaluation-"+estudiante.studentId+"-"+logro.achievementId}
                    value={notasTabla[`${estudiante.studentId}-${proceso.evaluationProcessId}-${logro.achievementId}`].evaluativeScaleId}
                    onChange={(e) =>handleNoteChange(proceso.evaluationProcessId,logro.achievementId,estudiante.studentId,e.target.value,e.target)}
                    >
                        {scale.map((sc) => (
                            <option key={sc.evaluativeScaleId} value={sc.evaluativeScaleId}>
                                {sc.abbreviation}
                            </option>
                        ))}
                    </select>
            </div>
            )}
        </div>
    );
  }

  // Aquí renderizamos el componente
  return (
    <div className="custom-table div-table-notas scroll-table">
        <div className="table-responsive">
    <table className="striped bordered hover responsive table-notas">
        <thead className="table-notas-head">
        <tr>
            <th rowSpan={3} className="celda-estudiante">
            Estudiante
            </th>
            {procesos.map((proceso, index) => (
            <th key={index} colSpan={proceso.logros.length}>
                {proceso.description}
                <span></span>
            </th>
            ))}
        </tr>
        <tr>
            {procesos.map((proceso, index) => (
            <>
                {proceso.logros.map((logro, indexNota) => (
                <>
                    <th className="head-logro-preescolar" key={logro.achievementId}>
                        <div className="row">
                            <div className="col-md-4">
                                <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" id={"chk-"+logro.achievementId} onChange={(e)=>{handleEneableAllNote(e, proceso, logro)}} />
                                        <label class="form-check-label" for={"chk-"+logro.achievementId}></label>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <span
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={logro.description}
                                    href="#"
                                >
                                      Logro {indexNota + 1}
                                </span>
                            </div>
                        </div>
                    </th>
                </>
                ))}
            </>
            ))}
        </tr>
        </thead>
        <tbody>
                {(
                    estudiantes.map((estudiante, indexEst) => (
                    <tr key={indexEst}>
                        <td>{estudiante.fullName}</td>
                        {procesos.map((proceso, indexProc) => (
                        <React.Fragment key={indexProc}>
                            {proceso.logros.map((logro, indexNota) => (
                            <td className="celda-logros-preescolar" key={indexNota}>
                                {getLogroEstudiante(estudiante, proceso, logro)}
                                {/* Aquí iría un input para ingresar la nota */}
                            </td>
                            ))}
                        </React.Fragment>
                        ))}
                    </tr>
                    ))
                )}
                  </tbody>
   
  </table>
  </div>
  </div>
  );
};

export default TablaNotasDimensiones;
