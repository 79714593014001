const collections = {
    PARENT_TYPE : "PARENT_TYPE",
    IDENTIFICATION_TYPE : "IDENTIFICATION_TYPE",
    SEX : "SEX",
    JOB_TYPE : "JOB_TYPE",
    PAYMENT_TYPE: "PAYMENT_TYPE",
    PAYMENT_FRECUENCY: "PAYMENT_FRECUENCY",
    PARENT_STATUS: "PARENT_STATUS",
    DISCOUNT_TYPE:"DISCOUNT_TYPE"
}

const notification = {
    RECIBO : "RECIBO"
}

const userTypes = {
    ADMIN : 1,
    WORKER: 2,
    COODINATOR:4,
    TEACHER:5,
    STUDENT:6,
    PARENTS:7
}

const documentTypes = {
    INCIDENCIA_EVIDENCE: 1,
    TEACHER_SIGNATURE: 2,
    PAYMENT_EVIDENCE: 3,
}

const reportTypes = {
    ASISTENCIA: 1,
    ACTA: 2,
    BOLETIN: 3,
    ACTAFINALPERIODO: 4,
}

const evaluationTypes = {
    DIMENSION_VALORATIVA: 1,
    ESCALA_VALORATIVA: 2,
    PLANILLA_PREVENTIVA: 3,
}

export default {
    collections,
    notification,
    userTypes,
    documentTypes,
    reportTypes,
    evaluationTypes
};