import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import "../css/estudiantes.css";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import BillingConceptForm from '../components/Forms/BillingConceptForm';
import Swal from "sweetalert2";
import billingConceptService from '../services/billingConceptService';
import utils from '../utils/generalFunctions';
import useCustomDataTableStyles from '../components/estilosDeDataTable';

const BillingConceptsView = () => {
  const [billingConcepts, setBillingConcepts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [BtnVer, setBtnVer] = useState('');

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        setSelectedConcept(null);
    };

    const openMaximizeModal = (billingConcept) => {
        setSelectedConcept(billingConcept);
        setModalOpen(true);
        setBtnVer(true);
    };

    const openEditModal = (billingConcept) => {
        setSelectedConcept(billingConcept);
        setModalOpen(true);
        setBtnVer(false);
    };

    const deleteGrade = async (billingConcept) => {
      Swal.fire({
        title:  '¿Está seguro de que desea eliminar este concepto?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then(async(result) => {
        if (result.isConfirmed) {
          try{
          await billingConceptService.deleteBillingItem(billingConcept.billingConceptId);
          Swal.fire(
            'Felicidades!',
            'Concepto eliminado exitosamente!',
            'success'
          );
          await fetchBillingConcepts();
          }catch(error){
            Swal.fire(
                "Ha ocurrido un error",
                "Por favor intentelo más tarde",
                "error"
            );
          }
        }
      })
    };


  useEffect(() => {
    fetchBillingConcepts();
  }, []);

  const fetchBillingConcepts = async () => {
    try {
      const response = await billingConceptService.getAllBillingItems(); // Asumiendo que este servicio devuelve los conceptos de facturación
      setBillingConcepts(response);
    } catch (error) {
      console.error("Error al obtener los conceptos de facturación:", error);
    }
  };

  // Columnas para DataTable
  const columns = [
    { name: "Id", selector: "consecutive", sortable: true, 
    cell: (row, index) => <span>{index + 1}</span>,grow:"-2" },      
    { name: 'Descripción', selector: row => row.description, sortable: true,grow:"2.5"},
    { name: 'Valor', selector: row => row.value, format: row => `${utils.formatNumber.new(row.value, "$")}`, sortable: true },
    { name: 'Año', selector: row => row.year, sortable: true,grow:"-1" },
    { name: 'Nivel educativo', selector: row => row.educationLevelName, sortable: true,grow:"1.5" },
    { name: 'Jornada', selector: row => row.journeyName, sortable: true,grow:"1" },
    {
      name: 'Acciones',grow:"2.5",
      cell: (row) => (
         <div className="actions-container justify-content-between align-items-center">
                <button
                  onClick={() => openMaximizeModal(row)}
                  className="btn-maximize btn-sm me-2"
                  title="Maximizar información"
                >
                  <i className="fas fa-eye"></i>
                </button>
                <button
                  onClick={() => openEditModal(row)}
                  className="btn-edit btn-sm me-2"
                  title="Editar"
                >
                  <i className="icon-edit fas fa-pencil-alt"></i>
                </button>
                <button
                  onClick={()=> deleteGrade(row)}
                  className="btn-deactivate btn-sm"
                  title="Eliminar"
                >
                  <i className="icon-deactivate fas fa-times"></i>
                </button>
              </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  
  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredItems = billingConcepts?.filter((item) => {
    if (item) {
      const allData = `${item.name} ${item.abbreviation} ${item.order} ${item.journeyName} ${item.isActive}`;
      const normalizedFilterText = normalizeString(filterText);
      const normalizedItemName = normalizeString(allData);
      return normalizedItemName.includes(normalizedFilterText);
    }
    return item;
  });

  const customStyles = useCustomDataTableStyles();

  const paginationPerPage = 50; // Ajusta este número según tus necesidades

  return (
    <div className="student-table-container p-3">
      <h1>Conceptos de pagos</h1>
      <p>Esta es la vista de configuración de conceptos de pagos.</p>
      <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">
                <input
                    type="text"
                    placeholder="Buscar"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    className="form-control form-control-sm w-50"
                />
                <div className="btn-group">
                    <button className="btn-sm btn-Estudiante btn-NuevoEstudiante"  onClick={() => openEditModal(null)} >Agregar Nuevo Concepto <i className="fa-solid fa-circle-plus"></i></button>
                </div>
            </div>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        className="students-datatable shadow-sm rounded"     
        noDataComponent="No hay información que mostrar, por favor añadela"      
        fixedHeader
        customStyles={customStyles}
        paginationPerPage={paginationPerPage} // Agrega esta propiedad para controlar las filas por página
        paginationRowsPerPageOptions={[50, 100]}

      />
            <>
            <MDBModal tabIndex='-1' show={modalOpen} setShow={setModalOpen}>
                <MDBModalDialog className="modal-xl-custom">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>CREACIÓN DE CONCEPTO DE PAGO</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className='card'>
                                {selectedConcept && (
                                    <BillingConceptForm
                                        key={Date.now()}
                                        billingConceptId={selectedConcept.billingConceptId}
                                        onClose={() =>{
                                            setModalOpen(false); 
                                            fetchBillingConcepts();
                                        }}
                                        ReadOnly={BtnVer}
                                    />
                                )}

                                { !selectedConcept && (
                                    <BillingConceptForm
                                        key={Date.now()}
                                        billingConceptId={0}
                                        onClose={() =>{
                                            setModalOpen(false); 
                                            fetchBillingConcepts();
                                        }}
                                        ReadOnly={false}
                                    />
                                )}
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                        <button type='button' className='btn-footer-close btn-primary' onClick={toggleModal}>
                        Cerrar
                    </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            </>
    </div>
  );
};

export default BillingConceptsView;