import React, { useState, useEffect } from 'react';
import academicService from '../../services/academicService';
import Swal from 'sweetalert2';
import Loading from '../Loading';
import gradesService from '../../services/gradesService';
import { toast } from 'react-toastify';
import courseService from '../../services/courseService';

const FormGrados = (props) => {
    const { gradoItem, onClose, ReadOnly } = props; 
    const initData ={
        gradeId: 0,
        name: "",
        abbreviation: "",
        educationLevelId: "0",
        journeyId: "",
        order: "",
        isActive: false
    };
    const [formData, setFormData] = useState(initData);
    const [educationLevels, setEducationLevels] = useState([]); 
    const [journeyList, setJourneyList] = useState([]);
    const [showLoading, setShowLoading] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchJourneys = async () => {
            try {
                const journeyResp = await academicService.getJourneys();
                setJourneyList(journeyResp);
                const respEd =  await courseService.getEducationLevels();  // Asegúrate de tener un método getCursos en cursoService
                setEducationLevels(respEd);
                setShowLoading(false);
                if (gradoItem) {
                    const resGrado = await gradesService.getGradeById(gradoItem);
                    setFormData((prevState) => ({ ...prevState, ...resGrado }));
                }
            } catch (error) {
                console.error("Error al obtener listas de formulario:", error);
            }
        };
    
        fetchJourneys();
    }, [gradoItem]);

    const handleSubmit = async (e) => {
        e.preventDefault();

         // Validación básica
         const requiredFields = ['name', 'order', 'abbreviation', 'journeyId','educationLevelId'];
         for (let field of requiredFields) {
             if (!formData[field]) {
                 toast.error("Por favor, completa todos los campos obligatorios.", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                   });
                 return;
             }
         }
 

        setShowLoading(true);
        try {
            let response;
            if (formData.gradeId > 0) {
                response = await gradesService.updateGrade(formData);
            } else {
                response = await gradesService.addGrade(formData);
            }
            if (response && response.gradeId) {
                Swal.fire("Felicidades!", "Grado guardado con éxito!", "success");
                if (onClose) onClose();
                setFormData(initData);
            } else {
                Swal.fire("Ha ocurrido un error", "Por favor intentelo mas tarde", "error");
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
            Swal.fire("Ha ocurrido un error", "Por favor intentelo mas tarde", "error");
        }
        setShowLoading(false);
    };

    return (
        <>
        {showLoading && <Loading />}

        {!showLoading && (
            <div className="container-fluid mt-4">
            <p className="text-muted">Por favor, completa todos los campos para registrar un nuevo grado.</p>
            
            <form onSubmit={handleSubmit} className="mt-3 ">
                <div className="row">
                    <div className="col-md-3 mb-3">
                        <label htmlFor="name" className="form-label">Nombre</label>
                        <input type="text" className="form-control" id="name" name="name" 
                            readOnly={ReadOnly} value={formData.name} onChange={handleChange} required />
                    </div>

                    <div className="col-md-3 mb-3">
                        <label htmlFor="abbreviation" className="form-label">Abreviación</label>
                        <input type="text" className="form-control" id="abbreviation" name="abbreviation" 
                            readOnly={ReadOnly} value={formData.abbreviation} onChange={handleChange} required />
                    </div>

                    <div className="col-md-3 mb-3">
                        <label htmlFor="journeyId" className="form-label">Jornada</label>
                        <select className="form-control form-select" id="journeyId" name="journeyId" 
                            value={formData.journeyId} onChange={handleChange} required>
                              <option value="" disabled={ReadOnly}>Seleccione</option>
                            {journeyList.map((journey) => (
                                <option key={journey.journeyId} value={journey.journeyId} disabled={ReadOnly}>
                                    {journey.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-3 mb-3">
                            <label htmlFor="educationLevelId" className="form-label">Nivel Educativo:</label>
                            <select className="form-control form-select" id="educationLevelId" name="educationLevelId" 
                                value={formData.educationLevelId} onChange={handleChange} disabled={ReadOnly}>
                                <option value="0">Seleccione</option>
                                {educationLevels.map((level) => (
                                    <option key={level.educationLevelId} value={level.educationLevelId}>
                                        {level.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                    <div className="col-md-3 mb-3">
                        <label htmlFor="order" className="form-label">Orden</label>
                        <input type="order" className="form-control" id="order" name="order" 
                            readOnly={ReadOnly} value={formData.order} onChange={handleChange} required />
                    </div>

                    <div className="col-md-3 mb-3">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="isActive" name="isActive" disabled={ReadOnly}
                                checked={formData.isActive} onChange={e => setFormData({...formData, isActive: e.target.checked})} />
                            <label className="form-check-label" htmlFor="isActive">Activo</label>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-1 center">
                        {!ReadOnly  && (
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        )}
                    </div>
                </div>
            </form>
            </div>
        )}
        </>
    );
}

export default FormGrados;
