import React, { useState } from 'react';
import FooterActa from '../../../components/FooterActa';
import HeaderActaAdultos from '../../../components/headerActasAdultos';
import moment from 'moment';
import types from '../../../services/types';
import HeaderActa from '../../../components/headerActas';


const AsignaturasRecuperacionActa = ({course, showSemi}) => {
  // Aquí podrías obtener tus datos dinámicamente, por ejemplo, a través de props o un fetch call
  const [todayDay, setTodayDay] = useState(moment());

  const getMes = (mes) =>{
    switch(mes){
        case "1":return "Enero";
        case "2":return "Febrero";
        case "3":return "Marzo";
        case "4":return "Abril";
        case "5":return "Mayo";
        case "6":return "Junio";
        case "7":return "Julio";
        case "8":return "Agosto";
        case "9":return "Septiembre";
        case "10":return "Octubre";
        case "11":return "Noviembre";
        case "12":return "Diciembre";
        default:return "Enero";
    }
}

 const cargarAsignaturas =  (student) => {
        const subjectArray = [];
        student.areasNotes.forEach(a =>{
            a.subjectNotes.forEach(s =>{
                if(!subjectArray.find(sb=>sb.schoolSubjetId === s.schoolSubjetId))
                {
                    subjectArray.push(s);
                }
            });
        });
        return subjectArray
    }

  return (
    <>
    <div className="col-md-3 mb-2 non-printable">
            <label htmlFor="txtAsistenciaDate" className="form-label">Fecha Informe: </label>
            <input
                id="txtAsistenciaDate"
                type="date"
                className="form-control "
                value={todayDay.format("yyyy-MM-DD")}
                onChange={(e) => setTodayDay(moment(e.target.value))}/>
    </div>
    <div className="student-table-container">
      <h1 className='non-printable'>Reporte de recuperación</h1>
      <p className='non-printable'>Selecciona los filtros para generar el reporte.</p>
      <br></br>
      <div className="card acta-container">
        {showSemi && (
          <HeaderActaAdultos />
        )}

        {!showSemi && (
          <HeaderActa />
        )}
      
      <section className="incidencia-implicados">
        <h5 className="section-title">S.I.E</h5>
        <h5 className="section-title">ACTA DE RECUPERACIÓN DE PERÍODO</h5>     
      
        <p>En la ciudad de Barranquilla, siendo los {moment(todayDay).format("DD \\d\\í\\a\\s \\d\\e\\l \\m\\e\\s \\d\\e ")} {getMes(String(todayDay.month()+1))} {todayDay.format(" \\d\\e YYYY")}, se le informa a los acudientes y estudiantes del grado <strong>{course.gradeName}</strong>, los resultados de las recuperaciónes <strong>DEL {course.periodName.toUpperCase()} ACADÉMICO</strong>  una vez finalizado el proceso de entrega y sustentación de los planes de mejoramiento.</p>
        <p>En constancia de lo anterior firman: </p>        
      
      {/* Código para la tabla de estudiantes y notas */}
      <section className="academic-areas escala-valorativa-container">
          <h5 className="historico-title">FIRMA DE LOS ACUDIENTES</h5>
          <table className="table table-custom-boletin">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Cant.</th>
            <th>Asignatura</th>
            <th>Nota</th>
            <th>Recuperación</th>
            <th>Firma Estudiante</th>
            <th>Firma Acudiente</th>
          </tr>
        </thead>
        <tbody>
          {course.students?.map(student => 
            {
              const asignaturas = cargarAsignaturas(student);
              return asignaturas.map((asignatura, index) => 
              (<tr key={`${student.studentFullName}-${asignatura.schoolSubjetName}`}>
                    {index === 0 && (
                      <>
                        <td rowSpan={asignaturas.length}>{student.studentFullName}</td>
                        <td rowSpan={asignaturas.length}>{asignaturas.length}</td>
                      </>
                    )}
                
                    <td>{asignatura.schoolSubjetName}</td>
                    <td>{asignatura.note}</td>
                    <td>{asignatura.recuperationNote}</td>
                    {index === 0 && (
                      <>
                        <td rowSpan={asignaturas.length}></td>
                        <td rowSpan={asignaturas.length}></td>
                      </>
                    )}
                  </tr>
                ))
            }
          )}
        </tbody>
      </table>
        </section>

    </section>
      <FooterActa  creadorId={course.tutorId??0}
                   reportTypeId={types.reportTypes.ACTAFINALPERIODO}></FooterActa>
      </div>
    </div>
    <br />
                    <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
                <br />
    </>
  );
};

export default AsignaturasRecuperacionActa;
