import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import useCustomDataTableStyles from "../components/estilosDeDataTable";
import IncidenciasForm from "../components/Forms/IncidenciaForm";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import Loading from "../components/Loading";
import moment from "moment";
import incidenciasService from "../services/incidenciasService";
import Swal from "sweetalert2";
import ActaIncidencia from "../components/Reports/actaDeIncidencia";

const Incidencias = () => {
  const [incidencias, setIncidencias] = useState([]); // Inicializar como un array vacío, no como null
  const [filterText, setFilterText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIncidencia, setSelectedIncidencia] = useState(null);
  const [BtnVer, setBtnVer] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [verActa, setVerActa] = useState(null);  
  
  const toggleShow = () => {
    setIsModalOpen(!isModalOpen);
    setSelectedIncidencia(null); 
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  // Suponiendo que la función fetchIncidencias obtiene las incidencias desde un servicio
  const fetchIncidencias = async () => {
    setShowLoading(true); // Cambia el estado a false una vez que los datos se carguen
    try {

        const response = await incidenciasService.getIncidencias();
        if(response){
          setIncidencias(response);
        }else{
          setIncidencias([]);
        }
    } catch (error) {
      console.error("Error al obtener incidencias:", error);
    }
    setShowLoading(false);// Cambia el estado a false una vez que los datos se carguen

  };  
  
  useEffect(() => {
    fetchIncidencias();
  }, []);

  const removeIncidencia = (incidenceId) => {
       
    if(incidenceId){
        Swal.fire({
            title:  '¿Está seguro de que desea eliminar esta incidencia?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
              try{
              
              await incidenciasService.deleteIncidenciasById(incidenceId);
              Swal.fire(
                'Felicidades!',
                'Insidencia eliminada exitosamente!',
                'success'
              );
              await fetchIncidencias();
              }catch(error){
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intentelo más tarde",
                    "error"
                );
              }
            }
          })    
    }
};

  const columns = [
    { 
        name: "N°", 
        selector: row => row.number, 
        sortable: true, 
        cell: (row, index) => <span>{index + 1}</span>, 
    },
    { name: "Usuario", selector: row => row.createdUserName, sortable: true },
    { name: "Año escolar", selector: row => row.schoolYearName, sortable: true },
    { name: "Periodo", selector: row => row.periodName, sortable: true },
    { name: "Título", selector: row => row.title, sortable: true },
    { name: "Tipo", selector: row => row.incidenceTypeName, sortable: true },
    { name: "Categoría", selector: row => row.parentTypeName, sortable: true },
    { name: "Descripción", selector: row => row.description, sortable: true },
    { name: "Fecha", selector: row =>  moment(row.incidenceDate).format('DD-MM-YYYY'), sortable: true },
    { name: "Implicados", selector: row => row.studentsFullName, sortable: true },
    { name: "Acciones",
      grow:"2.5",
      selector: "actions",grow:"3",
      cell: (row) => (
        <div className="actions-container justify-content-between align-items-center">
           <div className="actions-container justify-content-between align-items-center">
                    
                    <button className="btn-edit btn-sm me-2" title="Editar" onClick={() => {
                        setSelectedIncidencia(row);
                        setIsModalOpen(true);
                    }}>
                        <i className="icon-edit fas fa-pencil-alt"></i>
                    </button>
                    <button className="btn-deactivate btn-sm" title="Eliminar" onClick={() => {
                        removeIncidencia(row.incidenceId);
                        // Aquí la lógica para eliminar una categoría
                    }}>
                        <i className="icon-deactivate fas fa-times"></i>
                    </button>
                    <button
                        onClick={() => exportarActa(row)}
                        className="btn-deactivate btn-sm ms-2"
                        title="Exportar">
                        <i class="fa-solid fa-file-pdf"></i>
                    </button>
            </div>
        </div>
      ),
    },
    // Agrega aquí más columnas si es necesario
  ];

  const exportarActa =  async (acta) => {
    if (acta) {
       setVerActa(acta);
    }
};

const volverLista =  async () => {
  setVerActa(null);
};


 const customStyles = useCustomDataTableStyles();

 const normalizeString = (str) => {
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
  };
    
  const filteredItems = incidencias?.filter((item) => {
        if (item) {
          const allData = `${item.title} ${item.description} ${item.studentsFullName} ${item.incidenceTypeName} ${item.parentTypeName} ${item.createdUserName} ${item.schoolYearName} ${item.periodName} ${moment(item.incidenceDate).format('DD-MM-YYYY')}`;
          const normalizedFilterText = normalizeString(filterText);
          const normalizedItemName = normalizeString(allData);
          return normalizedItemName.includes(normalizedFilterText);
        }
        return item;
  });    

  return (
    <>
        {!verActa && (
          <div className="student-table-container p-3 filter-container">
          <h1>Gestión de Incidencias</h1>
          <>


                {showLoading && <Loading />}

                {!showLoading && (

                  <>
                        <div className="student-table-controls mb-3 d-flex justify-content-between align-items-center">

                                <input
                                  type="text"
                                  placeholder="Buscar incidencias..."
                                  value={filterText}
                                  onChange={e => setFilterText(e.target.value)}
                                  className="form-control form-control-sm w-50"
                                />
                                  <div className="btn-group">
                                        <button className="btn-sm btn-Estudiante btn-NuevoEstudiante" onClick={openModal}>
                                                <i className="fa-solid fa-circle-plus"></i> Registrar Incidencia</button>
                                  </div>
                        </div>

                        <DataTable
                          noHeader
                          columns={columns}
                          noDataComponent="No hay información que mostrar, por favor añadela"   
                          data={filteredItems}
                          customStyles={customStyles}
                          pagination
                          persistTableHead
                        />

                        <MDBModal tabIndex='-1' show={isModalOpen} setShow={setIsModalOpen}>
                          <MDBModalDialog className="modal-xl-custom">
                            <MDBModalContent>
                              <MDBModalHeader>
                                <MDBModalTitle>Incidencia</MDBModalTitle>
                                <button
                                  type='button'
                                  className='btn-close'
                                  color='none'
                                  onClick={toggleShow}
                                ></button>
                              </MDBModalHeader>
                              <MDBModalBody>
                                    <div className='card'>
                                      {selectedIncidencia ? (
                                        <IncidenciasForm
                                          key={Date.now()}
                                          incidencia={selectedIncidencia.incidenceId}
                                          onClose={() => {
                                            setIsModalOpen(false); 
                                            fetchIncidencias();
                                          }}
                                          ReadOnly={BtnVer}
                                        />
                                      ) : (
                                        <IncidenciasForm
                                          key={Date.now()}
                                          incidencia={0}
                                          onClose={() => {
                                            setIsModalOpen(false); 
                                            fetchIncidencias();
                                          }}
                                          ReadOnly={false}
                                        />
                                      )}
                                    </div>
                        </MDBModalBody>
                              <MDBModalFooter>
                                <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                  Cerrar
                                </button>
                              </MDBModalFooter>
                            </MDBModalContent>
                          </MDBModalDialog>
                        </MDBModal>
                  </>
                )}

          </>

          </div>
        )}

        {verActa && (
            <div>
              <button className="btn btn-secondary"  onClick={()=>{volverLista()}}><i className="fas fa-arrow-left"></i> Volver</button>
              <ActaIncidencia incidencia={verActa} />
           </div>
        )}
    </>
  );
};

export default Incidencias;
