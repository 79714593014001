import React, { useState, useEffect } from "react";
import Loading from "../Loading";
import tipoIncidenciaService from "../../services/tipoIncidenciaService";
import { toast } from 'react-toastify';
import Swal from "sweetalert2";

const TipoIncidenciaForm = ({ tipoIncidencia, onClose }) => {
    const [formData, setFormData] = useState({
        inicidenceTypeId: tipoIncidencia ? tipoIncidencia.inicidenceTypeId : 0,
        description: tipoIncidencia ? tipoIncidencia.description : "",
        parentTypeId: tipoIncidencia ? tipoIncidencia.parentTypeId : ""
    });
    const [showLoading, setShowLoading] = useState(false);
    const [categorias, setCategorias] = useState([]); // Inicializar como un array vacío

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        fetchCategorias();
    }, []);

    const fetchCategorias = async () => {
        setShowLoading(true); // Cambia el estado a false una vez que los datos se carguen
        try {

            const response = await tipoIncidenciaService.getParentsIncidenciasTipos();
            if(response){
                setCategorias(response);
            }else{
                setCategorias([]);
            }
        } catch (error) {
          console.error("Error al obtener categoria de incidencias:", error);
        }
        setShowLoading(false);// Cambia el estado a false una vez que los datos se carguen
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
         // Validación básica
         const requiredFields = ['description','parentTypeId'];
         for (let field of requiredFields) {
             if (!formData[field]) {
                 toast.error("Por favor, completa todos los campos obligatorios.", {
                     position: "top-right",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     theme: "light",
                   });
                 return;
             }
         }
        // Aquí implementarías la lógica para guardar o actualizar la categoría
        setShowLoading(true); 
        try{
 
            let response = null;
            if(formData.inicidenceTypeId>0){
                response = await tipoIncidenciaService.updateIncidenciaTipo(formData);
            }else{
                response = await tipoIncidenciaService.createIncidenciaTipo(formData);
            }

            if(response && response.inicidenceTypeId>0){
                    Swal.fire(
                        'Felicidades!',
                        'Categoria guardada exitosamente!',
                        'success'
                    );
                    if (onClose) onClose(); // Cerrar el modal después de enviar el formulario
            }else{
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intente más tarde",
                    "error"
                );
            }
         }catch(ex){
             console.log(ex);
              Swal.fire(
                         "Ha ocurrido un error",
                         "Por favor intente más tarde",
                         "error"
                     );
         }
         setShowLoading(false);
    };

    return (
        <>
            {showLoading && <Loading />}

            {!showLoading && (
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="nombre" className="form-label">Nombre del Tipo de Incidencia</label>
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="categoria" className="form-label">Categoría</label>
                        <select
                            className="form-select"
                            id="parentTypeId"
                            name="parentTypeId"
                            value={formData.parentTypeId}
                            onChange={handleInputChange}
                            required
                        >
                            {/* Aquí irían las opciones para la categoría */}
                            <option value="">Seleccione una categoría</option>
                            {categorias.map((c) => (
                                <option key={c.inicidenceTypeId} value={c.inicidenceTypeId} >
                                    {c.description}
                                </option>
                            ))}
                            {/* Agregar más opciones según sea necesario */}
                        </select>
                    </div>
                    <button type="submit" className="btn btn-primary">
                        {tipoIncidencia ? "Actualizar" : "Registrar"} Tipo de Incidencia
                    </button>
                </form>
            )}
        </>
    );
};

export default TipoIncidenciaForm;
