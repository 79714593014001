import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/DashboardEstudiante.css'; // Asegúrate de crear este archivo CSS para estilizar tu componente
import studentPhoto from '../../img/user-profile.png';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fotoProfesor1 from '../../img/profe (1).png';
import fotoProfesor2 from '../../img/profe (2).png';
import fotoProfesor3 from '../../img/profe (3).png';
import fotoProfesor4 from '../../img/profe (4).png';
import fotoProfesor5 from '../../img/profe (5).png';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import DashboardCard from '../../components/TituloDashboard';
import util from '../../utils/generalFunctions';
import FiltroPeriodoEscolar from '../../components/filtroperiodoescolarEstudianteNotasVista';
import estudiantesService from '../../services/estudiantesService';
import moment from 'moment';
import userProfile from '../../img/user-profile.png';

const ObservacionesAsistenciaPC = () => {
  const studentInfo = util.getStudentInformation();
  const filteReference = useRef(null);
  const [formData, setFormData] = useState({
    periodId:'',
  });
  const [asistenciaItem, setAsistenciaItem] = useState(null);
  const [incidencias, setIncidencias] = useState(null);


  const updateForm = (data) => {
    setFormData(prevState => ({
        ...prevState,
        periodId:data
      }));
      fetchLoadInformation(data);
  }

  const fetchLoadInformation = async (periodId) => {
    const courseId = studentInfo.courseId;
    const schoolYearId = studentInfo.schoolYearId;
    const studentId = studentInfo.studentId;
    try{
       if(courseId && schoolYearId && studentId && periodId){
        const asistenciaList = await estudiantesService.getEstudianteAsistencia(studentId,courseId,schoolYearId, periodId);
        if(asistenciaList!=null && asistenciaList.length>0){
            setAsistenciaItem(asistenciaList[0]);
        }else{
          setAsistenciaItem(null);
        }
        const response = await estudiantesService.getEstudianteIncidencia(studentId,courseId,schoolYearId, periodId);
        if(response!=null && response.length>0){
          setIncidencias(response);
        }else{
          setIncidencias([]);
        }
      }
    }catch(ex){
      console.error("Error al obtener información del estudiante:", ex);
    }
  }
      
    return (
        <div className="observaciones-asistencia-container-pc">
          <Link to="/dashboard" className="back-to-dashboard-pc">
                <FontAwesomeIcon icon={faArrowLeft} />
            </Link> 
          <DashboardCard
              studentPhoto={studentPhoto}
              title="Vista de Incidencias"
              subtitle="Incidencias y conteo de asistencias"
           
        />


    <div className="card">
      <div className="card-body">
        <div className="row">
            <div className='col-md-12'>
              <FiltroPeriodoEscolar 
                schoolYearId={studentInfo.schoolYearId} 
                callback={updateForm}
                ref={filteReference} />
            </div>

            <div className="col-md-3 col-xxl-3 d-flex">
              <div className="card flex-fill w-100" style={{ backgroundImage: 'linear-gradient(310deg, #56ab2f, #a8e063)' }}>
                <div className="card-body">
                  <h5 className="card-title text-white">Asistencias</h5>
                  <p className="h1 text-white">{asistenciaItem?.assists??0}</p>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-xxl-3 d-flex">
              <div className="card flex-fill w-100" style={{ backgroundImage: 'linear-gradient(310deg, #cb2d3e, #ef473a)' }}>
                <div className="card-body">
                  <h5 className="card-title text-white">Inasistencias</h5>
                  <p className="h1 text-white">{asistenciaItem?.noAssists??0}</p>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-xxl-3 d-flex">
              <div className="card flex-fill w-100" style={{ backgroundImage: 'linear-gradient(310deg,#2152ff,#21d4fd)' }}>
                <div className="card-body">
                  <h5 className="card-title text-white">Excusas</h5>
                  <p className="h1 text-white">{asistenciaItem?.excuses??0}</p>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-xxl-3 d-flex">
              <div className="card flex-fill w-100" style={{ backgroundImage: 'linear-gradient(310deg, #FF8008, #FFC837)' }}>
                <div className="card-body">
                  <h5 className="card-title text-white">Llegadas Tarde</h5>
                  <p className="h1 text-white">{asistenciaItem?.lates??0}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

  <div className="card">
<div className="card-body">
      <div className="card-header">
        <div className="card-actions float-end">
          {/* Aquí puedes poner los controles o acciones que necesites */}
        </div>
        <h5 className="card-title mb-0">Incidencias</h5>
      </div>
      <div className="card-body">
        {incidencias?.map(incidencia => (
          <div key={incidencia.incidenceId} className="d-flex align-items-start">
            <img src={incidencia.urlImage??userProfile} width="56" height="56" className="rounded-circle me-3" alt={incidencia.createdUserName} />
            <div className="flex-grow-1">
              <h6 className="mb-0">{incidencia.createdUserName}</h6>
              <div className="text-muted">
                <p>{incidencia.description}</p>
                <small>{moment(incidencia.incidenceDate).format("yyyy-MM-DD")} - {incidencia.periodName}</small>
                <hr></hr>
              </div>
            </div>
          </div>
        ))}
        
        {(!incidencias || (incidencias && incidencias.length===0)) && (
          <span>
              No se encontraron incidencias reportadas
          </span>
        )}
      </div>
    </div></div>

    
      </div>
    );
  };

export default ObservacionesAsistenciaPC;
