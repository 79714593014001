import React, { forwardRef, useEffect, useState } from 'react';
import academicService from '../services/academicService';
import moment from 'moment';

const FiltroPeriodoEscolar = forwardRef((props, ref) => {
  const { callback, schoolYearId } = props; 

  const [periodos, setPeriodos] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState('');

  const handleChange = (e) => {
    setSelectedPeriodo(e.target.value);
    if(callback) callback(e.target.value);
  };

  const LoadPeriodos = async (schoolYearId) =>{
    if(schoolYearId!=='0'){
        const resYear = await academicService.getYearById(schoolYearId);
        if(resYear){
            const peri = resYear.periods.filter(p=>p.isRecuperation===0 || !p.isRecuperation);
            setPeriodos(peri);
            let selectedP = null;
            for (let i = 0; i < peri.length; i++) {
                const p = peri[i];
                selectedP = p;
                const today = moment().toDate();
                const start = moment(p.startDate).toDate();
                const end = moment(p.endDate).toDate();
                if((today >= start) && (today <= end)){
                    selectedP = p;
                    break;
                }else if(today <= start){
                    selectedP = p;
                    break;
                }
            }
            if(selectedP!==null){
                setSelectedPeriodo(selectedP.academicPeriodId);
                if(callback) callback(selectedP.academicPeriodId);
            }
        }else{
            setPeriodos([]);
        }
    }else{
        setPeriodos([]);
    }
  }

  useEffect(() => {
    LoadPeriodos(schoolYearId??'0');
  }, []);

  return (
    <div className="">
      <div className="card-header">
        <h5 className="card-title mb-0">Selecciona el periodo escolar</h5>
      </div>
      <div className="card-body">
        <div className="input-group mb-3">
          <select
            className="form-select flex-grow-1"
            value={selectedPeriodo}
            onChange={handleChange}
          >
            <option value="">Seleccione un periodo...</option>
            {periodos.map((periodo) => (
              <option key={periodo.academicPeriodId} value={periodo.academicPeriodId}>
                {periodo.periodName}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
});

export default FiltroPeriodoEscolar;
