import api from './api'; // Asumiendo que tienes un módulo de configuración de API

const getAllBillingItems = async () => {
    try {
        const response = await api.get('/billing/concepts');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getBillingItemById = async (billingConceptId) => {
    try {
        const response = await api.get(`billing/concepts/${billingConceptId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const addBillingItem = async (billingItem) => {
    try {
        const response = await api.post('/billing/concepts', billingItem);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateBillingItem = async (billingItem) => {
    try {
        const response = await api.put(`/billing/concepts`, billingItem);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteBillingItem = async (billingConceptId) => {
    try {
        const response = await api.delete(`/billing/concepts/${billingConceptId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getAllBillingItems,
    getBillingItemById,
    addBillingItem,
    updateBillingItem,
    deleteBillingItem
};
